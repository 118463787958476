import React, { createContext, useContext, useState, useMemo, useEffect, useRef, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { ApiError } from "@/models/APIGeneric";
import { DataSet, Group, ModuleId, Project, isDataSet } from "@/model";
import {
  AutoRtaInputGrid,
  postInitializeAutoRta,
  AnalysisCalculation,
  AutoRtaForecastCalculationV2,
  postSaveInputAutoRta,
} from "@/models/gaz/autoRta";

import { autoRtaDefaultState } from "../constants";
import { FluidType } from "@/models/Generic";
import { autoRtaInitializeScheme, AutoRtaState } from "@/models/gaz/autoRta/State";
import { parseErrorThrown } from "@/utils/errorHandling";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { InputGridCalculation } from "@/models/InputGeneric";
import useInterval from "@/utils/useInterval";

type AutoRtaStateProps = {
  autoRtaState: null | AutoRtaState;
  setAutoRtaState: React.Dispatch<React.SetStateAction<AutoRtaState | null>>;
  dataSet?: DataSet[];
  isLoading: boolean;
  setApiError: (err?: ApiError) => void;
  project?: Project;
  fluidType: FluidType;
  apiError?: ApiError;
  validationError: ErrorValidationDetail[];
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;

  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;

  analysisCalculation?: AnalysisCalculation;
  setAnalysisCalculation: React.Dispatch<React.SetStateAction<AnalysisCalculation | undefined>>;
  autoRtaForecastCalculation?: AutoRtaForecastCalculationV2;
  setAutoRtaForecastCalculation: React.Dispatch<React.SetStateAction<AutoRtaForecastCalculationV2 | undefined>>;
  flowingPressureOptions?: string[];
  expandParameter: boolean;
  inputGridCalculate?: InputGridCalculation;
  setInputGridCalculate: React.Dispatch<React.SetStateAction<InputGridCalculation | undefined>>;
  inputResultFromInitialize?: React.MutableRefObject<boolean>;
  haveChangeValue: boolean;
  setHaveChangeValue: (val: boolean) => void;
};

const AutoRtaStateContext = createContext<AutoRtaStateProps>(autoRtaDefaultState);

export type ModelComponentsParamProps = {
  value?: AutoRtaInputGrid;
  calculateValue?: InputGridCalculation;
  isLoading: boolean;
  updateInputGridValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  flowingPressureOptions?: string[];
};

type AutoRtaContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  setApiError: (err?: ApiError) => void;
  project?: Project;
  currentModule?: ModuleId;
  isLoading: boolean;
  apiError?: ApiError;

  group?: Group;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
  expandParameter: boolean;
  checkIfDatasetExists: ({ datasetIds, groupId, projectId }: { datasetIds: string[]; groupId: string; projectId: string }) => boolean;
};

const defaultInterval = 30000;

function AutoRtaProvider({
  children,
  selectedDataSets,
  setApiError,
  project,
  isLoading: loadingState,
  currentModule,
  apiError,
  setProgress,
  setIsLoading,
  setPollStatus,
  expandParameter,
  checkIfDatasetExists,
  group,
}: Readonly<AutoRtaContextProps>) {
  const [autoRtaState, setAutoRtaState] = useState<AutoRtaState | null>(null);
  const [validationError, setValidationError] = useState<ErrorValidationDetail[]>([]);
  const [inputGridCalculate, setInputGridCalculate] = useState<InputGridCalculation>();

  const [analysisCalculation, setAnalysisCalculation] = useState<AnalysisCalculation | undefined>();
  const [autoRtaForecastCalculation, setAutoRtaForecastCalculation] = useState<AutoRtaForecastCalculationV2 | undefined>();

  const [flowingPressureOptions, setFlowingPressureOptions] = useState<string[]>();
  const [haveChangeValue, setHaveChangeValue] = useState(false);

  const latestDataSet = useRef<DataSet[]>([]);

  const inputResultFromInitialize = useRef(false);

  const dataSet = useMemo(() => {
    if (!selectedDataSets) return [];
    return isDataSet(selectedDataSets) ? [selectedDataSets] : selectedDataSets;
  }, [selectedDataSets]);

  const client = useQueryClient();

  const fluidType = useMemo(() => {
    return currentModule === ModuleId.GAZ_AUTORTA ? FluidType.gas : FluidType.oil;
  }, [currentModule]);

  const { isLoading, isFetching } = useQuery({
    queryKey: ["initialize-gaz-auto-rta", dataSet, fluidType, project?.id],
    queryFn: async () => {
      return postInitializeAutoRta({
        data_set_ids: dataSet?.map((data) => data.id) ?? [],
        projectId: project?.id ?? "",
        type: fluidType,
      });
    },
    select(data) {
      try {
        if (data?.data && !autoRtaState) {
          const parsed = autoRtaInitializeScheme.parse(data.data);

          setAutoRtaState({
            inputs: parsed.input_inputs,
            analysis: parsed.analysis_inputs,
            forecast: parsed.forecast_inputs,
            available_flowing_pressures: parsed.available_flowing_pressures,
            field_data: parsed.field_data,
          });

          latestDataSet.current = dataSet ?? [];
          if (parsed.available_flowing_pressures) setFlowingPressureOptions(parsed.available_flowing_pressures);

          if (parsed.input_result) {
            setInputGridCalculate(parsed.input_result);
            inputResultFromInitialize.current = true;
          }
          if (parsed.analysis_result) setAnalysisCalculation({ ...parsed.analysis_result, isFromInitialize: true });
          if (parsed.forecast_result) setAutoRtaForecastCalculation({ ...parsed.forecast_result, isFromInitialize: true });
        }
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedDataSets && !!project?.id,
  });

  const clearCache = useCallback(() => {
    setAutoRtaState(null);
    client?.invalidateQueries();
    setAnalysisCalculation(undefined);
    setAutoRtaForecastCalculation(undefined);
  }, [client]);

  // need this for comparing latest state with new payload for save feature
  const latestSavedPayload = useRef<any>();

  // we need this for saving state before unmounting
  const latestState = useRef<any>();

  const onSaveAutoRta = useCallback(
    async (state?: AutoRtaState, projectId?: string, groupId?: string) => {
      const latestState = state ?? autoRtaState;
      const latestProjectId = projectId ?? project?.id;
      const latestGroupId = groupId ?? group?.id;

      if (latestState?.inputs && latestState?.analysis && latestState?.forecast && latestProjectId && latestGroupId) {
        if (
          !checkIfDatasetExists({
            projectId: latestProjectId,
            groupId: latestGroupId,
            datasetIds: dataSet.map((data) => data.id),
          })
        )
          return;
        const payload = {
          data_set_ids: dataSet.map((data) => data.id),
          inputs: latestState?.inputs,
          analysis: latestState?.analysis,
          forecast: latestState?.forecast,
        };

        if (_.isEqual(payload, latestSavedPayload.current)) return;
        try {
          latestSavedPayload.current = _.cloneDeep(payload);
          await postSaveInputAutoRta(latestProjectId, fluidType, payload);
        } catch (error: any) {
          parseErrorThrown({
            error,
            setApiError,
            apiError,
          });
        }
      }
    },
    [apiError, autoRtaState, checkIfDatasetExists, dataSet, fluidType, group?.id, project?.id, setApiError]
  );

  useEffect(() => {
    latestState.current = autoRtaState;
  }, [autoRtaState]);

  // validate every 30 seconds
  useInterval(async () => {
    onSaveAutoRta();
  }, defaultInterval);

  useEffect(() => {
    return () => {
      onSaveAutoRta(latestState.current, project?.id ?? "", group?.id ?? "");
    };
    // Disable this lint because this is unmounting hooks,
    // when unmount take the state from ref if its from the state, it disappear already
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (latestDataSet.current.length > 0 && !_.isEqual(latestDataSet, dataSet)) {
      clearCache();
    }
  }, [clearCache, client, dataSet]);

  useEffect(() => {
    if (fluidType) {
      clearCache();
    }
  }, [clearCache, fluidType]);

  const state = useMemo(() => {
    return {
      autoRtaState,
      dataSet,
      setAutoRtaState,
      isLoading: isFetching || isLoading || loadingState,
      setApiError,
      project,
      fluidType,
      apiError,
      validationError,
      setValidationError,
      setIsLoading,
      setPollStatus,
      setProgress,
      analysisCalculation,
      setAnalysisCalculation,
      autoRtaForecastCalculation,
      setAutoRtaForecastCalculation,
      flowingPressureOptions,
      expandParameter,
      inputGridCalculate,
      setInputGridCalculate,
      inputResultFromInitialize,
      haveChangeValue,
      setHaveChangeValue,
    };
  }, [
    inputResultFromInitialize,
    inputGridCalculate,
    setInputGridCalculate,
    dataSet,
    apiError,
    fluidType,
    autoRtaState,
    isLoading,
    isFetching,
    setApiError,
    project,
    loadingState,
    validationError,
    setValidationError,
    setIsLoading,
    setPollStatus,
    setProgress,
    analysisCalculation,
    setAnalysisCalculation,
    autoRtaForecastCalculation,
    setAutoRtaForecastCalculation,
    flowingPressureOptions,
    expandParameter,
    haveChangeValue,
    setHaveChangeValue,
  ]);

  return <AutoRtaStateContext.Provider value={state}>{children}</AutoRtaStateContext.Provider>;
}

export default AutoRtaProvider;

export function useAutoRtaState() {
  return useContext(AutoRtaStateContext);
}
