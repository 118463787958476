import { ProjectStructureErrorCode, PromiseResult } from "@/models/wells";

export const getColumnIndexWithAlphabets = (csvString: string) => {
  const rows = csvString.split("\n");
  if (rows.length === 0) return [];
  const headers = rows[0].split(",");
  const columnIndices: number[] = [];
  for (let i = 0; i < headers.length; i++) {
    if (headers[i].trim() !== "") {
      columnIndices.push(i);
    }
  }
  return columnIndices;
};

export interface State {
  file?: File;
  res?: PromiseResult<ProjectStructureErrorCode>;
  isLoading: boolean;
  csvText?: string;
  csvHeaderText?: string[];
  selectedField: (string | undefined)[];
  isDropdownOpened: number;
}

export type Action =
  | { type: "SET_FILE"; payload?: File }
  | { type: "SET_RES"; payload?: PromiseResult<ProjectStructureErrorCode> }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_CSV_TEXT"; payload?: string }
  | { type: "SET_CSV_HEADER_TEXT"; payload?: string[] }
  | { type: "SET_SELECTED_FIELD"; payload: (string | undefined)[] }
  | { type: "SET_DROPDOWN_OPEN"; payload: number }
  | { type: "RESET" };

export const initialState: State = {
  file: undefined,
  res: undefined,
  isLoading: false,
  csvText: undefined,
  csvHeaderText: undefined,
  selectedField: [undefined, undefined, undefined],
  isDropdownOpened: 0,
};

export function importProjectStructureReducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_FILE":
      return { ...state, file: action.payload };
    case "SET_RES":
      return { ...state, res: action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_CSV_TEXT":
      return { ...state, csvText: action.payload };
    case "SET_CSV_HEADER_TEXT":
      return { ...state, csvHeaderText: action.payload };
    case "SET_SELECTED_FIELD":
      return { ...state, selectedField: action.payload };
    case "SET_DROPDOWN_OPEN":
      return { ...state, isDropdownOpened: action.payload };
    case "RESET":
      return {
        ...state,
        file: undefined,
        isLoading: false,
        csvText: undefined,
        csvHeaderText: undefined,
        selectedField: [undefined, undefined, undefined],
        isDropdownOpened: 0,
      };
    default:
      return state;
  }
}
