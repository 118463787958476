import React, { useCallback, useMemo, useRef, useReducer, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinearProgress from "@mui/material/LinearProgress";

import Tabs from "@/components/Tabs";
import IndividualItem from "../shared/components/IndividualItem";
import { Container, VisuallyHiddenInput } from "../shared/style";
import CustomCard from "@/components/Card";
import HeaderMappingDialog from "./HeaderMappingDialog";
import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";

import {
  HeaderMapping,
  PromiseResultDetail,
  ResultState,
  WellErrorCode,
  postDataSet,
  NormalizedHeaders,
  getNormalizedHeaders,
  Configuration,
} from "@/models/wells";
import { DIALOG_CONSTANTS, getDateUnitFromFiles, parseFile, renameVolumeToRate, renameRateToVolume } from "./helper";
import { reducer, initialState } from "./reducer";

const MAX_FETCH = 10;

const ImportWell = () => {
  const { refreshDataSets } = useTreeViewState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [normalizedFields, setNormalizedFields] = useState<NormalizedHeaders | null>(null);
  const totalFiles = useRef<number>(0);

  const [searchParams] = useSearchParams();
  const autoOpen = searchParams.get("autoOpen") === "true";

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // When mount, open file dialog immediately (?autoOpen=true)
  useEffect(() => {
    if (autoOpen && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [autoOpen]);

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: "SET_IS_LOADING", payload: true });
        const fields = await getNormalizedHeaders();
        const renamed = renameVolumeToRate(fields); // rename "Oil Volume" -> "Oil Rate"
        setNormalizedFields(renamed);
      } catch (err) {
        dispatch({ type: "SET_ERROR_MESSAGE", payload: "Failed to load normalized fields." });
      } finally {
        dispatch({ type: "SET_IS_LOADING", payload: false });
      }
    })();
  }, []);

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: "RESET_STATE" });

      const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
      if (!selectedFiles.length) return;

      const fileParsingPromises = selectedFiles.map(parseFile);

      Promise.all(fileParsingPromises)
        .then((parsedFiles) => {
          const firstHeaders = parsedFiles[0].headers;
          const allHeadersMatch = parsedFiles.every((pf) => _.isEqual(pf.headers, firstHeaders));

          if (!allHeadersMatch) {
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: "Headers between files do not match. Please upload files with identical headers.",
            });
            return;
          }

          const dateColumns = firstHeaders.filter((hdr) => hdr === DIALOG_CONSTANTS.DATE_FIELD);
          if (dateColumns.length > 1) {
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: "Multiple 'Date' columns found. Please remove extra 'Date' columns and try again.",
            });
            return;
          }

          // Store the raw File[] + parsed details
          dispatch({ type: "SET_FILES", payload: selectedFiles });
          dispatch({ type: "SET_PARSED_FILES", payload: parsedFiles });

          // Build an initial headerMapping
          const templateFieldOptions = Object.keys(normalizedFields!);
          const initialMapping: Record<string, HeaderMapping> = {};

          firstHeaders.forEach((header) => {
            let normalizedField = templateFieldOptions.includes(header) ? header : null;
            let normalizedUnit = null;

            if (header === DIALOG_CONSTANTS.DATE_FIELD) {
              normalizedField = DIALOG_CONSTANTS.DATE_FIELD;
              const guessed = getDateUnitFromFiles(parsedFiles, header);

              if (guessed === DIALOG_CONSTANTS.UNRECOGNIZED_DATE) {
                normalizedUnit = DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
                dispatch({ type: "SET_DATE_FORMATS", payload: [DIALOG_CONSTANTS.UNRECOGNIZED_DATE] });
              } else if (Array.isArray(guessed)) {
                dispatch({ type: "SET_DATE_FORMATS", payload: guessed });
                if (guessed.length === 1) normalizedUnit = guessed[0];
              }
            }

            initialMapping[header] = { normalizedField, normalizedUnit };
          });

          dispatch({ type: "SET_HEADER_MAPPING", payload: initialMapping });

          if (parsedFiles.length > 0) {
            dispatch({ type: "SET_CURRENT_FILE_INDEX", payload: 0 });
            dispatch({ type: "SET_MAPPING_DIALOG_OPEN", payload: true });
          }
        })
        .catch((error) => {
          console.error("Error parsing files:", error);
        });
    },
    [normalizedFields]
  );

  const handleMappingChange = useCallback((newMapping: Record<string, HeaderMapping>) => {
    dispatch({ type: "SET_HEADER_MAPPING", payload: newMapping });
  }, []);

  const handleMappingConfirm = useCallback(() => {
    dispatch({ type: "SET_MAPPING_DIALOG_OPEN", payload: false });
  }, []);

  const batchFetch = useCallback(async (payloadList: any[]) => {
    let results: any[] = [];
    let remaining = _.cloneDeep(payloadList);

    while (remaining.length > 0) {
      try {
        const currentBatch = remaining.slice(0, MAX_FETCH).map((payload) => postDataSet(payload));
        const batchResults = await Promise.allSettled(currentBatch);
        results = results.concat(batchResults);
        remaining.splice(0, MAX_FETCH);

        const percentage: number = ((totalFiles.current - remaining.length) / totalFiles.current) * 100;
        dispatch({ type: "SET_PROGRESS_PERCENTAGE", payload: percentage });
      } catch (error) {
        console.error("Batch fetch error:", error);
      }
    }

    return { totalRes: results };
  }, []);

  const initializeSubmissionState = useCallback((override: boolean): void => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({ type: "SET_PROGRESS_PERCENTAGE", payload: 0 });
    if (!override) dispatch({ type: "SET_RES", payload: {} });
    dispatch({ type: "SET_ERROR_MESSAGE", payload: "" });
  }, []);

  const prepareFileList = useCallback(
    (override: boolean) => {
      const overrideFileIndex: number[] = [];
      const filelistToFetch: Array<{ file: File; override: boolean; configuration: Configuration }> = [];
      const configuration = {
        well_type: state.manualWellType,
        header: state.headerMapping,
      };

      // rename any "Rate" field to "Volume" in configuration.header
      for (const colName of Object.keys(configuration.header)) {
        const mapping = configuration.header[colName];
        if (mapping?.normalizedField) {
          mapping.normalizedField = renameRateToVolume(mapping.normalizedField);
        }
      }

      if (override) {
        state.files.forEach((file, idx) => {
          const res = state.res[idx];
          if (res?.status === "rejected" && res?.detail.code === "file_exists") {
            overrideFileIndex.push(idx);
            filelistToFetch.push({ file, override, configuration });
          }
        });
      } else {
        state.files.forEach((file) => {
          filelistToFetch.push({ file, override, configuration });
        });
      }

      return { overrideFileIndex, filelistToFetch };
    },
    [state.files, state.headerMapping, state.res, state.manualWellType]
  );

  const processFetchResults = useCallback(
    (
      totalRes: Array<{ reason: { code: number; detail: PromiseResultDetail<WellErrorCode> }; status: string }>,
      override: boolean,
      overrideFileIndex: number[]
    ) => {
      const updatedResult: ResultState<WellErrorCode> = { ...state.res };

      totalRes.forEach((currRes, idx) => {
        const safeRes = currRes as {
          reason: { code: number; detail: PromiseResultDetail<WellErrorCode> };
          status: "rejected" | "fulfilled";
        };

        const targetIndex = override ? overrideFileIndex[idx] : idx;
        updatedResult[targetIndex] = {
          code: safeRes.reason?.code,
          detail: safeRes.reason?.detail,
          status: safeRes.status,
        };
      });

      return updatedResult;
    },
    [state.res]
  );

  const handleCancel = useCallback(() => {
    dispatch({ type: "RESET_STATE" });
    if (fileInputRef.current) fileInputRef.current.value = "";
  }, []);

  const handleSubmit = useCallback(
    async (override: boolean) => {
      try {
        initializeSubmissionState(override);
        if (!state.files) return;

        const { overrideFileIndex, filelistToFetch } = prepareFileList(override);
        totalFiles.current = filelistToFetch.length;

        const { totalRes } = await batchFetch(filelistToFetch);
        const reMappedResult = processFetchResults(totalRes, override, overrideFileIndex);

        dispatch({ type: "SET_RES", payload: reMappedResult });
        if (!override) refreshDataSets();
      } catch (error: any) {
        console.error("Submission error:", error);
      } finally {
        dispatch({ type: "SET_IS_LOADING", payload: false });
        dispatch({ type: "SET_ACTIVE_TAB", payload: 0 });
        dispatch({ type: "SET_IS_OVERRIDE_ACTIVE", payload: false });
      }
    },
    [batchFetch, state.files, refreshDataSets, processFetchResults, prepareFileList, initializeSubmissionState]
  );

  const resultAvailable = useMemo(() => Object.keys(state.res).length > 0, [state.res]);

  const tabList = useMemo(() => {
    if (!resultAvailable || !state.res) return [];
    const successFiles = state.files.map((_, idx) => (state.res[idx].status === "fulfilled" ? String(idx) : null)).filter(Boolean) as string[];

    const overrideFiles = state.files
      .map((_, idx) => (state.res[idx].status === "rejected" && state.res[idx].detail?.code === "file_exists" ? String(idx) : null))
      .filter(Boolean) as string[];

    const failedFiles = state.files
      .map((_, idx) => (state.res[idx].status === "rejected" && state.res[idx].detail?.code !== "file_exists" ? String(idx) : null))
      .filter(Boolean) as string[];

    const tabs: { label: JSX.Element; key: string; content: JSX.Element }[] = [];

    if (successFiles.length > 0) {
      tabs.push({
        label: <span>{dictionary.wellImport.successFiles}</span>,
        key: "success",
        content: (
          <div className="files-list-container">
            {successFiles.map((fileIndex) => (
              <IndividualItem key={state.files[Number(fileIndex)].name} name={state.files[Number(fileIndex)].name} data-testid="success-tab" />
            ))}
          </div>
        ),
      });
    }

    if (failedFiles.length > 0) {
      tabs.push({
        label: <span>{dictionary.wellImport.failedTitle}</span>,
        key: "failed",
        content: (
          <div className="files-list-container">
            {failedFiles.map((fileIndex) => (
              <IndividualItem
                error={state.res[Number(fileIndex)].detail?.message}
                key={state.files[Number(fileIndex)].name}
                name={state.files[Number(fileIndex)].name}
                data-testid="failed-tab"
              />
            ))}
          </div>
        ),
      });
    }

    if (overrideFiles.length > 0) {
      tabs.push({
        label: <span>{dictionary.wellImport.overrideFile}</span>,
        key: "override",
        content: (
          <div className="files-list-container">
            {overrideFiles.map((fileIndex) => (
              <IndividualItem
                error={state.res[Number(fileIndex)].detail?.message}
                key={state.files[Number(fileIndex)].name}
                name={state.files[Number(fileIndex)].name}
                data-testid="override-tab"
              />
            ))}
          </div>
        ),
      });
    }

    return tabs;
  }, [resultAvailable, state.res, state.files]);

  const onlyOverrideTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "override").length === 1 && tabList.length === 1;
  }, [tabList]);

  const onlySuccessTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "success").length === 1 && tabList.length === 1;
  }, [tabList]);

  return (
    <Container>
      <CustomCard style={{ width: 700, maxHeight: "60vh" }}>
        <h3>
          <a target="_blank" rel="noreferrer" href={helpLinkUrl.wellImport}>
            {dictionary.nav.importWells} <InfoOutlinedIcon fontSize="small" />
          </a>
        </h3>

        <div className="list-container">
          {state.isLoading && (
            <LinearProgress style={{ margin: "1em" }} variant="determinate" value={state.progressPercentage} data-testid="progress-bar" />
          )}

          {state.errorMessage && (
            <div className="error-message" style={{ color: "red" }} data-testid="import-well-error">
              {state.errorMessage}
            </div>
          )}

          <div>
            {!resultAvailable &&
              state.files.map((file) => <IndividualItem key={file.name} name={file.name} data-testid={`selected-file-${file.name}`} />)}
          </div>

          {resultAvailable && (
            <Tabs
              onClickItem={(item) => {
                dispatch({ type: "SET_ACTIVE_TAB", payload: item });
                dispatch({
                  type: "SET_IS_OVERRIDE_ACTIVE",
                  payload: tabList[item].key === "override",
                });
              }}
              customActiveTab={state.activeTab}
              tabList={tabList}
              data-testid="results-tabs"
            />
          )}
        </div>

        <div className="button-container">
          {!onlySuccessTabActive && (
            <Button
              disabled={state.isLoading || normalizedFields === null}
              style={{ color: "white" }}
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              data-testid="select-files-button"
            >
              {dictionary.wellImport.selectFile}(s)
              <VisuallyHiddenInput
                data-testid="hidden-file-input"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                multiple
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Button>
          )}

          {onlySuccessTabActive ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/modules";
              }}
              style={{ color: "white", marginLeft: 20 }}
              variant="contained"
              data-testid="ok-button"
            >
              {dictionary.wellImport.ok}
            </Button>
          ) : (
            <>
              {(state.isOverrideActive || onlyOverrideTabActive) && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                  style={{ marginLeft: 20 }}
                  variant="outlined"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(state.isOverrideActive || onlyOverrideTabActive);
                }}
                disabled={state.files.length === 0 || state.isLoading || (resultAvailable && !state.isOverrideActive && !onlyOverrideTabActive)}
                style={{ color: "white", marginLeft: 20 }}
                variant="contained"
                data-testid="submit-button"
              >
                {state.isOverrideActive || onlyOverrideTabActive ? dictionary.wellImport.overrideFile : dictionary.wellImport.submit}
              </Button>
            </>
          )}
        </div>
      </CustomCard>

      <HeaderMappingDialog
        open={state.mappingDialogOpen}
        parsedFiles={state.parsedFiles}
        currentFileIndex={state.currentFileIndex}
        setCurrentFileIndex={(idx) => dispatch({ type: "SET_CURRENT_FILE_INDEX", payload: idx })}
        manualWellType={state.manualWellType}
        setManualWellType={(val) => dispatch({ type: "SET_MANUAL_WELL_TYPE", payload: val })}
        headerMapping={state.headerMapping}
        onMappingChange={handleMappingChange}
        dateFormatsOptions={state.dateFormats}
        setDateFormatOptions={(val) => dispatch({ type: "SET_DATE_FORMATS", payload: val })}
        onConfirm={handleMappingConfirm}
        onCancel={handleCancel}
        normalizedFields={normalizedFields}
      />
    </Container>
  );
};

export default ImportWell;
