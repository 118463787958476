import { useMemo, useState } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";
import { AutoFmbState, ForecastCalculationState, ForecastFmbFieldDataOil, ForecastFmbResult } from "@/models/autoFmb";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { caseKey } from "../constants";
import { generateDataTableColumnBase, generateDataTableRowCalculation } from "./utils";

type UseAutoFmbForecastResultProps = {
  autoFmbState?: AutoFmbState;
  fieldData?: ForecastFmbFieldDataOil;
  forecastResult?: ForecastFmbResult;
};

const forecastFmbModelDataTableList = ["dates", "flowing_pressure", "oil_rate", "cumulative_oil"];

const safeDic: { [key: string]: string } = dictionary.fmb;

const useAutoFmbForecastResultOil = ({ fieldData, autoFmbState, forecastResult }: UseAutoFmbForecastResultProps) => {
  const { palette } = useThemeStyling();

  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  const chartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [
        { name: dictionary.genericChart.oilRate, type: "value", color: palette.customColor.green },
        { name: dictionary.genericChart.pressure, type: "value", color: palette.customColor.black },
      ],
    };
  }, [palette.customColor.black, palette.customColor.green]);

  const colorScheme = useMemo(() => {
    const seriesColorScheme = [palette.customColor.greenLight, palette.customColor.green, palette.customColor.greenDark];
    return seriesColorScheme;
  }, [palette.customColor.greenLight, palette.customColor.green, palette.customColor.greenDark]);

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (forecastResult && fieldData) {
      const metrics = [
        {
          type: "oil_rate",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilRate}`,
          colorScheme,
          yAxisIndex: 0,
          defaultDisabled: false,
        },
        {
          type: "flowing_pressure",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.gas.flowingPressure}`,
          colorScheme: [palette.common.black, palette.common.black, palette.common.black],
          defaultDisabled: true,
          yAxisIndex: 1,
        },
      ];

      let mappedCase: FossilyticsChartSeries[] = [];

      metrics.forEach((metric) => {
        const eachMap = caseKey.map((key, index) => {
          const safeData: any = forecastResult[key as keyof ForecastCalculationState["forecast_result"]].data;

          return {
            name: `${safeDic[key]} ${metric.nameKey}`,
            type: "line",
            color: metric.colorScheme[index],
            yAxisIndex: metric.yAxisIndex,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type]?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
            defaultDisabled: metric.defaultDisabled,
          };
        });
        mappedCase = [...mappedCase, ...eachMap];
      });

      return [
        ...mappedCase,
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilRate}`,
          type: "scatter",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: fieldData.dates.map((x: string, i: number) => [x, fieldData.oil_rate?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
      ];
    }
    return [];
  }, [forecastResult, fieldData, colorScheme, palette.common.black, palette.customColor.green]);

  const cumulativeChartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [{ name: dictionary.genericChart.cumulativeOil, type: "value", color: palette.customColor.green }],
    };
  }, [palette.customColor.black, palette.customColor.green]);

  const cumulativeChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!forecastResult || !fieldData) return [];

    const metrics = [
      {
        type: "cumulative_oil",
        nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilCumulative}`,
        colorScheme,
        defaultDisabled: false,
      },
    ];

    let mappedCase: FossilyticsChartSeries[] = [];

    metrics.forEach((metric) => {
      const eachMap = caseKey.map((key, index) => {
        const safeData: any = forecastResult[key as keyof ForecastCalculationState["forecast_result"]].data;

        return {
          name: `${safeDic[key]} ${metric.nameKey}`,
          type: "line",
          color: metric.colorScheme[index],
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
          defaultDisabled: metric.defaultDisabled,
        };
      });
      mappedCase = [...mappedCase, ...eachMap];
    });

    return [
      ...mappedCase,
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilCumulative}`,
        type: "scatter",
        color: palette.customColor.green,
        yAxisIndex: 0,
        data: fieldData.dates.map((x: string, i: number) => [x, fieldData.cumulative_oil?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
    ];
  }, [forecastResult, fieldData, colorScheme, palette.customColor.green]);

  const forecastCalculationDataTableCol = generateDataTableColumnBase(forecastFmbModelDataTableList);

  const forecastCalculationDataTableRow = useMemo(() => {
    return generateDataTableRowCalculation({
      headerList: forecastFmbModelDataTableList,
      symbols: autoFmbState?.symbols_key,
      units: autoFmbState?.units_key,
      dataTable: forecastResult?.[selectedCase as keyof ForecastCalculationState["forecast_result"]].data,
    });
  }, [forecastResult, autoFmbState?.symbols_key, autoFmbState?.units_key, selectedCase]);

  const fieldDataRow = useMemo(() => {
    return generateDataTableRowCalculation({
      headerList: forecastFmbModelDataTableList,
      symbols: autoFmbState?.symbols_key,
      units: autoFmbState?.units_key,
      dataTable: fieldData,
    });
  }, [fieldData, autoFmbState?.symbols_key, autoFmbState?.units_key]);

  const fieldDataCol = generateDataTableColumnBase(forecastFmbModelDataTableList);

  return {
    setSelectedCase,
    chartAxes,
    forecastCalcSeries,
    cumulativeChartAxes,
    cumulativeChartSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    fieldDataCol,
    fieldDataRow,
    selectedCase,
  };
};

export default useAutoFmbForecastResultOil;
