import { ReactNode, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import useThemeStyling from "@/utils/useThemeStyling";

type CollapsibleProps = {
  headerText: string;
  children: ReactNode;
  defaultOpen?: boolean;
};

const Collapsible = ({ headerText, children, defaultOpen = false }: CollapsibleProps) => {
  const { palette } = useThemeStyling();

  const [isExpand, setIsExpand] = useState(defaultOpen);

  return (
    <div style={{ marginTop: 10 }}>
      <h4
        onKeyDown={() => setIsExpand(!isExpand)}
        onClick={() => setIsExpand(!isExpand)}
        style={{ color: palette.primary.main, margin: 0, display: "flex", justifyContent: "space-between", cursor: "pointer", marginTop: 0 }}
      >
        {headerText} {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </h4>

      {isExpand && children}
    </div>
  );
};

export default Collapsible;
