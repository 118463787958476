import React, { useCallback, useMemo, useRef, useState, useEffect, useLayoutEffect } from "react";
import Tree from "rc-tree";
import { useNavigate } from "react-router-dom";
import { formatTestTitle } from "@/utils/general";
import { IconButton } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TagFilterMenu from "../TagFilterMenu";
import { AFA_WELL_TYPE } from "../../constants";
import InputField from "@/components/fields/InputField";
import { buildTagDictionary, hasModifiedDefaultSelectionUtils } from "../../utils";

import Radio from "@mui/material/Radio";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

import SpokeOutlinedIcon from "@mui/icons-material/SpokeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import TransformOutlinedIcon from "@mui/icons-material/TransformOutlined";

import { DataSet, Project, Group } from "@/model";
import { Container, PaddingContainer } from "./style";
import { DialogEnum, FlatenGroupProject, SelectItemProp } from "../../types";
import { findParentByKey, transformProjectsToTreeNodes, TreeNode, filterDataSetsByTagsAndText } from "./helper";
import useArrowNavigation from "@/utils/useArrowNavigation";
import DataSetRadioItem from "../DataSetRadioItem";
import { MissingDataSet } from "@/models/wells/State";
import _ from "lodash";
import useThemeStyling from "@/utils/useThemeStyling";

type DnDProjectTree = {
  projects: Project[];
  onDragEnd: (info: any) => void;
  dataSets?: DataSet[];
  onDragWellList: (payload: any) => void;
  missingDataset: MissingDataSet[];
} & GenericDndProps;

type GenericDndProps = {
  setActiveDialog: (val: DialogEnum | undefined) => void;
  mappedItemKeyTotal: FlatenGroupProject;
  onSelectItem: (payload: SelectItemProp) => void;
  selectedKeys: string[];
  onClickPreview: (dataSetId: string, isOutsideList: boolean) => void;
};

type DndItemProps = {
  item: TreeNode;
  expandedKeys: string[];
  filteredMappedItemKeyTotal: FlatenGroupProject;
  isFilterApplied: boolean;
  onExpand: (val: string, forceOpen?: boolean) => void;
} & GenericDndProps;

const DndItem = ({
  item,
  expandedKeys,
  selectedKeys,
  onExpand,
  onSelectItem,
  onClickPreview,
  setActiveDialog,
  mappedItemKeyTotal,
  filteredMappedItemKeyTotal,
  isFilterApplied,
}: DndItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const paddingContainerRef = useRef<any>(null);

  const { palette } = useThemeStyling();

  const isExpanded = expandedKeys.indexOf(item.key) !== -1;
  const isSelected = selectedKeys.indexOf(item.key) !== -1;

  const total = mappedItemKeyTotal?.[item.id]?.total ?? 0;
  const filteredTotal = filteredMappedItemKeyTotal?.[item.id]?.total ?? 0;

  const renderMenuList = useCallback(
    (level: number, canCreateSubGroup: boolean) => {
      if (level === 0) {
        return [
          {
            text: "New Groups",
            icon: <AddBoxIcon sx={{ color: palette.primary.main }} />,
            onClick: () => {
              setActiveDialog(DialogEnum.NEW_GROUP);
            },
          },
          {
            text: "Project Settings",
            icon: <SettingsIcon sx={{ color: palette.primary.main }} />,
            onClick: () => {
              setActiveDialog(DialogEnum.PROJECT_SETTING);
            },
          },
          {
            text: "Rename Project",
            icon: <EditIcon sx={{ color: palette.primary.main }} />,
            onClick: () => setActiveDialog(DialogEnum.EDIT_PROJECT),
          },
          {
            text: "Delete Project",
            icon: <DeleteIcon sx={{ color: palette.primary.main }} />,
            onClick: () => setActiveDialog(DialogEnum.DELETE_PROJECT),
          },
          {
            text: "Transfer Project",
            icon: <TransformOutlinedIcon sx={{ color: palette.primary.main }} />,
            onClick: () => {
              setActiveDialog(DialogEnum.TRANSFER_PROJECT);
            },
          },
        ];
      }
      return [
        ...(canCreateSubGroup
          ? [
              {
                text: "New Subgroups",
                icon: <AddBoxIcon sx={{ color: palette.primary.main }} />,
                onClick: () => setActiveDialog(DialogEnum.NEW_GROUP),
              },
            ]
          : []),
        {
          text: "Rename Group",
          icon: <EditIcon sx={{ color: palette.primary.main }} />,
          onClick: () => setActiveDialog(DialogEnum.EDIT_GROUP),
        },
        {
          text: "Delete Group",
          icon: <DeleteIcon sx={{ color: palette.primary.main }} />,
          onClick: () => setActiveDialog(DialogEnum.DELETE_GROUP),
        },
      ];
    },
    [palette.primary.main, setActiveDialog]
  );

  const renderIdentityIcon = () => {
    if (item.type === "project") {
      if (isExpanded) return <FolderOpenIcon fontSize="small" style={{ color: palette.primary.main }} />;
      return <FolderIcon fontSize="small" style={{ color: palette.primary.main }} />;
    }
    return <SpokeOutlinedIcon fontSize="small" />;
  };

  if (item.type === "dataset") {
    const isDataPreviewMode = window.location.href.includes("dataPreview");
    return (
      <PaddingContainer level={item.level - 1}>
        <DataSetRadioItem
          onClickPreview={(id) => {
            onClickPreview(id, false);
          }}
          data-testid={`Icon-btn-${item.type}-${item.level}-${formatTestTitle(item.title)}`}
          id={item.key}
          key={item.key}
          onClick={() => {
            if (isDataPreviewMode) onClickPreview(item.id, false);

            onSelectItem({
              type: item.type,
              id: item.parents[0],
              prevSelection: isSelected,
              groupId: item.parents,
              dataSetId: item.id,
              key: item.key,
              item,
            });
          }}
          dataSet={item.title}
          currDataSet={item.dataSet}
          setActiveDialog={setActiveDialog}
          checked={isSelected}
        />
      </PaddingContainer>
    );
  }

  return (
    <PaddingContainer
      ref={paddingContainerRef}
      // highlight drag over project/ group so user know which one to drag into
      onDrop={() => {
        paddingContainerRef.current.className = "project-name";
      }}
      onDragOver={() => {
        paddingContainerRef.current.className = "onDragBg project-name";
      }}
      onDragLeave={() => {
        paddingContainerRef.current.className = "project-name";
      }}
      level={0}
      className="project-name"
    >
      <div
        style={{
          paddingLeft: (item.level - 1) * 20,
        }}
        id={item.key}
        className="radio-container"
        data-testid="radio-container"
      >
        <div style={{ display: "flex", width: "100%" }} className="first-half">
          <Radio
            style={{
              visibility: "visible",
            }}
            size="small"
            checked={isSelected}
            onClick={(e) => {
              e.preventDefault();

              if (!isExpanded) onExpand(item.id);
              onSelectItem({
                type: item.type,
                id: item.id,
                prevSelection: isSelected,
                key: item.key,
                item,
              });
            }}
            key={item.title + isSelected}
          />
          <div
            onClick={() => {
              onExpand(item.id);
            }}
            data-testid={`collapsible-btn-${item.type}-${item.level}-${formatTestTitle(item.title)}`}
            onKeyDown={(event) => {
              if (event.key === "Enter") onExpand(item.id);
            }}
            style={{ display: "flex", width: "100%" }}
          >
            {isExpanded ? <ExpandMoreIcon className={` ${item.type}-title-item`} /> : <ChevronRightIcon className={` ${item.type}-title-item`} />}

            <div
              style={{
                marginTop: 2,
              }}
            >
              {renderIdentityIcon()}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
              className={`name-text ${isExpanded ? "expanded" : "collapsed"} ${item.type}-title-item`}
            >
              {item.title} ({isFilterApplied ? ` ${filteredTotal}/${total} ` : `${total}`})
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }} data-testid="second-half">
          <IconButton
            data-testid={`MoreHorizIcon-${item.type}-${formatTestTitle(item.title)}`}
            aria-haspopup="true"
            onClick={(event) => {
              event.preventDefault();
              onExpand(item.id, true);
              setAnchorEl(event.currentTarget);
              onSelectItem({
                type: item.type,
                id: item.id,
                prevSelection: false,
                key: item.key,
                item,
              });
            }}
            aria-label="more"
          >
            <MoreHorizIcon fontSize="small" sx={{ color: palette.primary.main }} />
          </IconButton>
          <div>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              {renderMenuList(item.level - 1, item.data_set_ids?.length === 0).map((menu) => {
                return (
                  <MenuItem
                    onClick={() => {
                      menu.onClick();
                      setAnchorEl(null);
                    }}
                    key={menu.text}
                  >
                    {menu.icon}
                    <div
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      {menu.text}
                    </div>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      </div>
    </PaddingContainer>
  );
};

const DndNav = ({
  onDragWellList,
  setActiveDialog,
  dataSets,
  projects,
  mappedItemKeyTotal,
  onSelectItem,
  selectedKeys,
  onDragEnd,
  onClickPreview,
  missingDataset,
}: DnDProjectTree) => {
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [projectFilterText, setProjectFilterText] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<Record<string, string[]>>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTagKeys, setOpenTagKeys] = useState<string[]>([]);

  const initialized = useRef(false);
  const previousAvailableTags = useRef<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const treeInstance = useRef<any>();
  const theme = useThemeStyling();
  const navigate = useNavigate();

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenTagKeys([]);
  };

  const dataSetMap = useMemo(() => {
    const map = new Map<string, DataSet>();
    dataSets?.forEach((ds) => map.set(ds.id.toLowerCase(), ds));
    return map;
  }, [dataSets]);

  const missingDataSetMap = useMemo(() => {
    const map = new Map<string, MissingDataSet>();
    missingDataset?.forEach((md) => map.set(md.id.toLowerCase(), md));
    return map;
  }, [missingDataset]);

  // Preprocess the datasets to create a map of dataset tags
  const dataSetTagsMap = useMemo(() => {
    if (!dataSets) return new Map<string, Map<string, string>>();
    const map = new Map<string, Map<string, string>>();
    dataSets.forEach((dataSet) => {
      const tagMap = new Map<string, string>();
      dataSet.tags?.forEach((tagStr) => {
        const [key, value] = tagStr.split(":");
        tagMap.set(key, value);
      });
      map.set(dataSet.id, tagMap);
    });
    return map;
  }, [dataSets]);

  const dataSetIdsInProjects = useMemo(() => {
    const ids = new Set<string>();

    const traverseGroups = (groups: Group[]) => {
      groups.forEach((group) => {
        if (group.data_set_ids) {
          group.data_set_ids.forEach((id) => ids.add(id.toLowerCase()));
        }
        if (group.groups) {
          traverseGroups(group.groups);
        }
      });
    };

    projects.forEach((project) => {
      if (project.groups) {
        traverseGroups(project.groups);
      }
    });

    return ids;
  }, [projects]);

  const dataSetsInProjects = useMemo(() => {
    if (!dataSets) return [];
    return dataSets.filter((ds) => dataSetIdsInProjects.has(ds.id.toLowerCase()));
  }, [dataSets, dataSetIdsInProjects]);

  // Get tags that only present in all projects
  const tags = useMemo(() => {
    if (!dataSetsInProjects) return {};
    return buildTagDictionary(dataSetsInProjects);
  }, [dataSetsInProjects]);

  const hasModifiedDefaultSelection = useMemo(() => hasModifiedDefaultSelectionUtils(selectedTags, tags), [selectedTags, tags]);
  const isFilterApplied = hasModifiedDefaultSelection || projectFilterText.trim() !== "";

  // Filter on selected tags
  const filteredDataSets = useMemo(() => {
    if (!dataSetsInProjects) return [];
    return filterDataSetsByTagsAndText(dataSetsInProjects, selectedTags, dataSetTagsMap, projectFilterText);
  }, [dataSetsInProjects, selectedTags, dataSetTagsMap, projectFilterText]);

  // When no filtering: Include both filtered datasets and missing datasets
  const filteredDataSetIds = useMemo(() => {
    if (hasModifiedDefaultSelection) {
      return new Set(filteredDataSets.map((ds) => ds.id.toLowerCase()));
    } else {
      const ids = new Set(filteredDataSets.map((ds) => ds.id.toLowerCase()));
      missingDataSetMap.forEach((_, id) => ids.add(id));
      return ids;
    }
  }, [filteredDataSets, hasModifiedDefaultSelection, missingDataSetMap]);

  // Recompute tree data based on filtered projects and datasets
  const treeData = useMemo(() => {
    if (projects && dataSetMap) {
      return transformProjectsToTreeNodes(projects, dataSetMap, filteredDataSetIds, missingDataSetMap);
    }
    return [];
  }, [projects, dataSetMap, filteredDataSetIds, missingDataSetMap]);

  useEffect(() => {
    const availableTags = Object.keys(tags[AFA_WELL_TYPE] ?? {});
    const selectedAfaWellTypeTags = selectedTags[AFA_WELL_TYPE];

    if (!initialized.current && tags[AFA_WELL_TYPE]) {
      // init: set selectedTags to include all available tags
      setSelectedTags({
        [AFA_WELL_TYPE]: availableTags,
      });
      initialized.current = true;
    } else if (selectedAfaWellTypeTags === undefined) {
      // user hasn't made any selection yet
      setSelectedTags((prevSelectedTags) => ({
        ...prevSelectedTags,
        [AFA_WELL_TYPE]: availableTags,
      }));
    } else if (_.isEqual(new Set(selectedAfaWellTypeTags), new Set(previousAvailableTags.current))) {
      // user hasn't modified the selection, update with new tags
      if (!_.isEqual(new Set(selectedAfaWellTypeTags), new Set(availableTags))) {
        setSelectedTags((prevSelectedTags) => ({
          ...prevSelectedTags,
          [AFA_WELL_TYPE]: availableTags,
        }));
      }
    }

    previousAvailableTags.current = availableTags;
  }, [tags, selectedTags]);

  const onExpand = useCallback(
    (key: string, forceOpen?: boolean) => {
      const newExpandedKeys = [...expandedKeys];
      const currentKeyIndex = expandedKeys.indexOf(key);
      if (currentKeyIndex === -1 || forceOpen) {
        newExpandedKeys.push(key);
      } else {
        newExpandedKeys.splice(currentKeyIndex, 1);
      }
      setExpandedKeys(newExpandedKeys);
    },
    [expandedKeys]
  );

  // this is to make sure we can drag between tree
  useLayoutEffect(() => {
    if (treeInstance.current) {
      const onNodeDragEnter = treeInstance.current.onNodeDragEnter;

      if (typeof onNodeDragEnter === "function") {
        treeInstance.current.onNodeDragEnter = function (...argv: any[]) {
          onDragWellList(argv[1].props.data);
          if (treeInstance.current.dragNode) {
            return onNodeDragEnter.call(this, ...argv);
          }
        };
      }
    }
  }, [onDragWellList]);

  const adjustItemPosition = useCallback(
    (option: "up" | "down") => {
      if (selectedKeys.length <= 0) return;
      // find the specific item
      const itemParentNode = findParentByKey(treeData, selectedKeys[0]);

      if (itemParentNode?.children) {
        const indexOfcurrentSelectedAmongChildren = itemParentNode.children.findIndex((child) => child.key === selectedKeys[0]);

        // if it is arrow up we need to go to the index before
        //if down will go to the one after
        // if hit threshold, just return to home page

        const thresholdIndex = option === "up" ? 0 : itemParentNode.children.length - 1;
        if (indexOfcurrentSelectedAmongChildren === thresholdIndex) {
          // reset all state by selecting the same thing will automatically reset
          const currentItem = itemParentNode.children[indexOfcurrentSelectedAmongChildren];
          onSelectItem({
            type: "dataset",
            id: currentItem.parents[0],
            prevSelection: true,
            groupId: currentItem.parents,
            dataSetId: currentItem.id,
            key: currentItem.key,
            item: currentItem,
          });
          navigate("/modules");
          return;
        } else {
          const newIndex = option === "up" ? indexOfcurrentSelectedAmongChildren - 1 : indexOfcurrentSelectedAmongChildren + 1;
          const newItem = itemParentNode.children[newIndex];
          const isDataPreviewMode = window.location.href.includes("dataPreview");

          onSelectItem({
            type: "dataset",
            id: newItem.parents[0],
            prevSelection: false,
            groupId: newItem.parents,
            dataSetId: newItem.id,
            key: newItem.key,
            item: newItem,
          });
          if (isDataPreviewMode) onClickPreview(newItem.id, false);
        }
      }
    },
    [navigate, onClickPreview, onSelectItem, selectedKeys, treeData]
  );

  const onArrowUp = useCallback(() => {
    adjustItemPosition("up");
  }, [adjustItemPosition]);

  const onArrowDown = useCallback(() => {
    adjustItemPosition("down");
  }, [adjustItemPosition]);

  useArrowNavigation({
    onArrowUp,
    onArrowDown,
    nodeRef: containerRef,
  });

  // Compute filteredMappedItemKeyTotal
  const filteredMappedItemKeyTotal = useMemo(() => {
    let res: FlatenGroupProject = {};

    const loopTreeNode = (node: TreeNode, isChild: boolean = false): FlatenGroupProject => {
      let res: FlatenGroupProject = {};

      let totalItems = 0;
      let ids: string[] = [];

      // Process the current node if it's a dataset
      if (node.type === "dataset") {
        totalItems += 1;
        ids.push(node.id);
      }

      // Recursively process child nodes
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          const childRes = loopTreeNode(child, true);
          res = { ...res, ...childRes };

          const childTotal = childRes[child.id]?.total || 0;
          const childIds = childRes[child.id]?.ids || [];

          totalItems += childTotal;
          ids = ids.concat(childIds);
        });
      }

      // Store the totalItems and ids in the result for the current node
      res[node.id] = {
        total: totalItems,
        isChild,
        ids,
        name: node.title,
      };

      return res;
    };

    treeData.forEach((node) => {
      const nodeRes = loopTreeNode(node);
      res = { ...res, ...nodeRes };
    });

    return res;
  }, [treeData]);

  const renderDatasetItem = useCallback(
    (item: any) => {
      return (
        <DndItem
          onClickPreview={onClickPreview}
          setActiveDialog={setActiveDialog}
          mappedItemKeyTotal={mappedItemKeyTotal}
          filteredMappedItemKeyTotal={filteredMappedItemKeyTotal}
          isFilterApplied={isFilterApplied}
          expandedKeys={expandedKeys}
          item={item}
          onExpand={onExpand}
          onSelectItem={onSelectItem}
          selectedKeys={selectedKeys}
          key={item.key}
        />
      );
    },
    [
      expandedKeys,
      mappedItemKeyTotal,
      onClickPreview,
      onExpand,
      onSelectItem,
      selectedKeys,
      setActiveDialog,
      filteredMappedItemKeyTotal,
      isFilterApplied,
    ]
  );

  return (
    <Container ref={containerRef} tabIndex={0}>
      <InputField
        dataTestId="project-filter-input"
        type="text"
        value={projectFilterText}
        onChange={(value) => setProjectFilterText(typeof value === "string" ? value : String(value ?? ""))}
        tinyLabel="Search..."
        styles={{ width: "96%" }}
        debounceDelay={300}
        suffix={
          <IconButton onClick={handleFilterIconClick} data-testid="project-filter-icon" aria-label="Filter Projects">
            {hasModifiedDefaultSelection ? (
              <FilterAltIcon style={{ width: 25, height: 25, color: theme.palette.primary.main }} />
            ) : (
              <FilterAltOutlinedIcon style={{ width: 25, height: 25 }} />
            )}
          </IconButton>
        }
      />
      <TagFilterMenu
        anchorEl={anchorEl}
        openTagKeys={openTagKeys}
        setOpenTagKeys={setOpenTagKeys}
        handleMenuClose={handleMenuClose}
        tags={tags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        hasModifiedDefaultSelection={hasModifiedDefaultSelection}
      />
      <Tree
        ref={treeInstance}
        onDragStart={(info: any) => {
          const item = info.node;
          if (item.type === "dataset") {
            onSelectItem({
              type: item.type,
              id: item.parents[0],
              prevSelection: item.selected,
              groupId: item.parents,
              dataSetId: item.id,
              key: item.key,
              item,
              isDrag: true,
            });
          } else {
            onSelectItem({
              type: item.type,
              id: item.id,
              prevSelection: item.selected,
              key: item.key,
              item,
            });
          }
        }}
        expandedKeys={expandedKeys}
        onDrop={onDragEnd}
        draggable
        virtual
        height={height - 220}
        itemHeight={40}
        // @ts-ignore
        treeData={treeData}
        titleRender={(item) => renderDatasetItem(item)}
      />
    </Container>
  );
};

export default DndNav;
