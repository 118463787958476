import { useMemo, useState } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";
import { AutoFmbState, ForecastCalculationState, ForecastFmbFieldDataGas, ForecastFmbResult } from "@/models/autoFmb";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { caseKey } from "../constants";
import { generateDataTableColumnBase, generateDataTableRowCalculation } from "./utils";

type UseAutoFmbForecastResultProps = {
  autoFmbState?: AutoFmbState;
  fieldData?: ForecastFmbFieldDataGas;
  forecastResult?: ForecastFmbResult;
};

const forecastFmbModelDataTableList = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
  "reservoir_pressure",
  "pseudo_reservoir_pressure",
  "pseudo_flowing_pressure",
  "gas_formation_volume_factor",
  "gas_viscosity",
  "gas_compressibility",
  "z",
];

const fieldGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
];

const safeDic: { [key: string]: string } = dictionary.fmb;

const useAutoFmbForecastResult = ({ fieldData, autoFmbState, forecastResult }: UseAutoFmbForecastResultProps) => {
  const { palette } = useThemeStyling();

  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  const chartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [
        { name: dictionary.genericChart.gasRate, type: "value", color: palette.customColor.red },
        { name: dictionary.genericChart.pressure, type: "value", color: palette.customColor.black },
      ],
    };
  }, [palette.customColor.black, palette.customColor.red]);

  const colorScheme = useMemo(() => {
    const seriesColorScheme = [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark];
    return seriesColorScheme;
  }, [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark]);

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (forecastResult && fieldData) {
      const metrics = [
        {
          type: "dry_gas_rate",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasRate}`,
          colorScheme,
          yAxisIndex: 0,
          defaultDisabled: false,
        },
        {
          type: "recombined_gas_rate",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasRate}`,
          colorScheme,
          defaultDisabled: true,
          yAxisIndex: 0,
        },
        {
          type: "flowing_pressure",
          nameKey: `${dictionary.autoRta.modelled} ${dictionary.gas.flowingPressure}`,
          colorScheme: [palette.common.black, palette.common.black, palette.common.black],
          defaultDisabled: true,
          yAxisIndex: 1,
        },
      ];

      let mappedCase: FossilyticsChartSeries[] = [];

      metrics.forEach((metric) => {
        const eachMap = caseKey.map((key, index) => {
          const safeData: any = forecastResult[key as keyof ForecastCalculationState["forecast_result"]].data;

          return {
            name: `${safeDic[key]} ${metric.nameKey}`,
            type: "line",
            color: metric.colorScheme[index],
            yAxisIndex: metric.yAxisIndex,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type]?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
            defaultDisabled: metric.defaultDisabled,
          };
        });
        mappedCase = [...mappedCase, ...eachMap];
      });

      return [
        ...mappedCase,
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasRate}`,
          type: "scatter",
          color: palette.customColor.red,
          yAxisIndex: 0,
          data: fieldData.dates.map((x: string, i: number) => [x, fieldData.dry_gas_rate?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.recombinedGasRate}`,
          type: "scatter",
          color: palette.customColor.red,
          defaultDisabled: true,
          yAxisIndex: 0,
          data: fieldData.dates.map((x: string, i: number) => [x, fieldData.recombined_gas_rate?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
      ];
    }
    return [];
  }, [fieldData, forecastResult, colorScheme, palette.common.black, palette.customColor.red]);

  const cumulativeChartAxes = useMemo(() => {
    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [{ name: dictionary.genericChart.cumulativeGas, type: "value", color: palette.customColor.red }],
    };
  }, [palette.customColor.black, palette.customColor.red]);

  const cumulativeChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!forecastResult || !fieldData) return [];

    const metrics = [
      {
        type: "dry_cumulative_gas",
        nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasCumulative}`,
        colorScheme,
        defaultDisabled: false,
      },
      {
        type: "recombined_cumulative_gas",
        nameKey: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasCumulative}`,
        colorScheme: colorScheme,
        defaultDisabled: true,
      },
    ];

    let mappedCase: FossilyticsChartSeries[] = [];

    metrics.forEach((metric) => {
      const eachMap = caseKey.map((key, index) => {
        const safeData: any = forecastResult[key as keyof ForecastCalculationState["forecast_result"]].data;

        return {
          name: `${safeDic[key]} ${metric.nameKey}`,
          type: "line",
          color: metric.colorScheme[index],
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[metric.type]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
          defaultDisabled: metric.defaultDisabled,
        };
      });
      mappedCase = [...mappedCase, ...eachMap];
    });

    return [
      ...mappedCase,
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: fieldData.dates.map((x: string, i: number) => [x, fieldData.dry_cumulative_gas?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.recombinedGasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: fieldData.dates.map((x: string, i: number) => [x, fieldData.recombined_cumulative_gas?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
        defaultDisabled: true,
      },
    ];
  }, [fieldData, forecastResult, colorScheme, palette.customColor.red]);

  const forecastCalculationDataTableCol = generateDataTableColumnBase(forecastFmbModelDataTableList);

  const forecastCalculationDataTableRow = useMemo(() => {
    return generateDataTableRowCalculation({
      headerList: forecastFmbModelDataTableList,
      symbols: autoFmbState?.symbols_key,
      units: autoFmbState?.units_key,
      dataTable: forecastResult?.[selectedCase as keyof ForecastCalculationState["forecast_result"]].data,
    });
  }, [forecastResult, autoFmbState?.symbols_key, autoFmbState?.units_key, selectedCase]);

  const fieldDataRow = useMemo(() => {
    return generateDataTableRowCalculation({
      headerList: fieldGasDataTableHeader,
      symbols: autoFmbState?.symbols_key,
      units: autoFmbState?.units_key,
      dataTable: fieldData,
    });
  }, [fieldData, autoFmbState?.symbols_key, autoFmbState?.units_key]);

  const fieldDataCol = generateDataTableColumnBase(fieldGasDataTableHeader);

  return {
    setSelectedCase,
    chartAxes,
    forecastCalcSeries,
    cumulativeChartAxes,
    cumulativeChartSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    fieldDataCol,
    fieldDataRow,
    selectedCase,
  };
};

export default useAutoFmbForecastResult;
