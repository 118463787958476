import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import {
  KoldunCsgApiState,
  postInitializeKoldunCsg,
  koldunCsgStateScheme,
  KoldunMeasure,
  InputType,
  postValidateKoldunCsg,
  KoldunCsgForecastData,
  koldunCsgForecastDataScheme,
  koldunCsgInputStateScheme,
  KoldunCsgForecastPayload,
} from "@/models/koldunV2";
import { DataSet, Group, Project, isDataSet } from "@/model";
import { ApiError } from "@/models/APIGeneric";
import { PowerLaw, WellboreModelEnum } from "@/models/InputGeneric";

import dictionary from "@/constants/dictionary";
import useInterval from "@/utils/useInterval";

import { useProjectSettingState } from "@/ProjectSettingsContextV3";

import { defaultOgipMatrixKey, defaultVolumetricKey, defaultPumpHeadValue, defaultNoModelValue, getDefaultMeasure } from "../constants";
import { pollForecastKoldunCsg } from "@/constants/apiUrl";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { parseErrorThrown } from "@/utils/errorHandling";

import { generateNewMeasures } from "../utils";
import { usePolling } from "@/utils/apiFetcher/pollRequest";
import { Interval } from "@/models/Generic";
import { saveBlob } from "@/util";
import { postExportKoldunCsgForecast } from "@/models/koldunV2/csg/apiFetcher/export";

const defaultInterval = 30000;

const defaultState = {
  tabIndex: 0,
  onChangeTab: (index: number) => {},
  dataSets: [],
  loadingState: false,
  layerOption: [],
  selectedLayer: 0,
  setSelectedLayer: (index: number) => {},
  setKoldunCsgState: () => {},
  onChangeInputs: () => {},
  onClickCalculateForecast: () => {},
  onChangeForecast: () => {},
  setValidationError: () => {},
  setApiError: () => {},
  validationError: [],
  canCancelPoll: false,
  onCancelPoll: () => {},
  onClickExport: () => {},
};

type KoldunCsgState = {
  tabIndex: number;
  onChangeTab: (index: number) => void;
  dataSets: string[];
  loadingState: boolean;
  state?: KoldunCsgApiState;
  layerOption: { key: number; text: string }[];
  selectedLayer: number;
  setSelectedLayer: (index: number) => void;
  setKoldunCsgState: React.Dispatch<React.SetStateAction<KoldunCsgApiState | undefined>>;
  onChangeInputs: (key: string, value: any) => void;
  onChangeForecast: (key: string, value: any) => void;
  onClickCalculateForecast: () => void;
  koldunCsgCalculation?: KoldunCsgForecastData;
  validationError: ErrorValidationDetail[];
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;
  forecastEndDate?: string;
  setApiError: (error?: ApiError) => void;
  canCancelPoll: boolean;
  onCancelPoll: () => void;
  onClickExport: (interval: Interval) => void;
};

export type UseModelComponentInputProps = {
  measures: KoldunMeasure[];
  setKoldunCsgState: React.Dispatch<React.SetStateAction<KoldunCsgApiState | undefined>>;
  loadingState: boolean;
  powerLawSkin?: PowerLaw | null;
  specifyInput?: InputType;
};

const KoldunCsgContext = createContext<KoldunCsgState>(defaultState);

type KoldunCsgProviderProps = {
  children: React.ReactNode;
  isLoading: boolean;
  selectedDataSets: DataSet | DataSet[] | undefined;
  group?: Group;
  project?: Project;
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;

  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
  setLoadingBlocker: (val: boolean) => void;
  setLoadingBlockerText: (val?: string) => void;
  checkIfDatasetExists: ({ datasetIds, groupId, projectId }: { datasetIds: string[]; groupId: string; projectId: string }) => boolean;
};

const KoldunCsgProvider = ({
  children,
  isLoading,
  selectedDataSets,
  setApiError,
  group,
  project,
  apiError,
  setLoadingBlocker,
  setIsLoading,
  setProgress,
  setPollStatus,
  setLoadingBlockerText,
  checkIfDatasetExists,
}: KoldunCsgProviderProps) => {
  const [forecastEndDate] = useProjectSettingState<string | undefined>("forecast_end_date", project?.id, undefined);

  const [tabIndex, setTabIndex] = useState(0);

  const [koldunCsgState, setKoldunCsgState] = useState<KoldunCsgApiState>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const [koldunCsgCalculation, setKoldunCsgCalculation] = useState<KoldunCsgForecastData>();
  const [selectedLayer, setSelectedLayer] = useState(0);
  const [validationError, setValidationError] = useState<ErrorValidationDetail[]>([]);

  const isLoadingValidation = useRef(false);

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setValidationError,
    setLoadingBlocker: (val) => {
      setLoadingBlocker(val?.isBlocking ?? false);
      setLoadingBlockerText(val?.loadingBlockerText);
    },
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  const latestSaved = useRef<any>();
  // we need this for saving state before unmounting
  const latestState = useRef<any>();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const analysisIdentity = useMemo(() => {
    if (!group?.id || !project?.id) return null;
    return {
      data_set_ids: dataSets,
      group_id: group?.id ?? "",
      project_id: project?.id ?? "",
    };
  }, [dataSets, group?.id, project?.id]);

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-koldun-csg", analysisIdentity, dataSets],
    queryFn: async () => {
      return postInitializeKoldunCsg(analysisIdentity!);
    },
    select(data) {
      try {
        if (data?.data && !koldunCsgState) {
          const parsed = koldunCsgStateScheme.parse(data.data);
          setKoldunCsgState(parsed);
          setLatestDataSets(dataSets);
          if (parsed.analysis_results) {
            setKoldunCsgCalculation(parsed.analysis_results);
          }
        }
      } catch (error: any) {
        console.log(error?.issues);
        if (error?.issues) {
          const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
          if (apiError?.message !== parseError) {
            setApiError({
              message: parseError,
            });
          }
        }
      }
    },
    refetchOnWindowFocus: false,
    enabled: !koldunCsgState && dataSets && dataSets.length > 0 && !!analysisIdentity,
  });

  const client = useQueryClient();

  useEffect(() => {
    if (latestDataSets.length > 0 && _.isEqual(latestDataSets, dataSets)) {
      setKoldunCsgState(undefined);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const layerOption = useMemo(() => {
    return Array.from(Array(koldunCsgState?.inputs.measures?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.koldunCsg.measure} ${index + 1}`,
      };
    });
  }, [koldunCsgState?.inputs.measures]);

  const onChangeInputs = useCallback(
    (key: string, value: any) => {
      if (!koldunCsgState?.inputs) return;
      setValidationError([]);
      let newMeasures = _.cloneDeep(koldunCsgState?.inputs.measures);

      const newInputs = {
        ...koldunCsgState?.inputs,
        [key]: value,
        measures: newMeasures,
      };

      if (key === "specify_inputs" && value !== koldunCsgState.inputs.specify_inputs) {
        newInputs.measures = generateNewMeasures(newMeasures, value);
        newInputs.dependency_matrices_keys = value === InputType.Ogip ? defaultOgipMatrixKey : defaultVolumetricKey;
      } else if (key === "number_of_measures") {
        if (value > newMeasures.length) {
          for (let index = newMeasures.length; index < value; index++) {
            const defaultMeasure =
              newMeasures.length === 0 ? getDefaultMeasure(koldunCsgState.inputs.specify_inputs) : newMeasures[newMeasures.length - 1];
            newInputs.measures.push(_.cloneDeep(defaultMeasure));
          }
        } else {
          newInputs.measures = newMeasures.slice(0, value);
        }
      }

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: newInputs,
        };
      });
    },
    [setKoldunCsgState, koldunCsgState?.inputs]
  );

  const onChangeForecast = useCallback(
    (key: string, value: any) => {
      if (!koldunCsgState?.forecast) return;

      setValidationError([]);
      let newForecast = {
        ...koldunCsgState?.forecast,
        [key]: value,
      };
      if (key === "selected_wellbore_model") {
        if (value === WellboreModelEnum.PumpModel) {
          newForecast = {
            ...newForecast,
            ...defaultPumpHeadValue,
          };
        } else {
          newForecast = {
            ...newForecast,
            ...defaultNoModelValue,
          };
        }
      }
      setKoldunCsgState((prev) => {
        if (!prev || !koldunCsgState?.forecast) return prev;
        return {
          ...prev,
          forecast: newForecast,
        };
      });
    },
    [setKoldunCsgState, koldunCsgState?.forecast]
  );

  useEffect(() => {
    setApiError();
  }, [koldunCsgState?.inputs, setApiError]);

  const validateKoldun = useCallback(
    async (isInterval?: boolean, state?: KoldunCsgApiState) => {
      const latestState = state ?? koldunCsgState;

      if (analysisIdentity && latestState?.inputs && forecastEndDate) {
        if (
          !checkIfDatasetExists({
            projectId: analysisIdentity.project_id,
            groupId: analysisIdentity.group_id,
            datasetIds: analysisIdentity.data_set_ids,
          })
        )
          return;
        try {
          isLoadingValidation.current = true;
          const payload = {
            analysis_id: analysisIdentity,
            options: latestState?.inputs,
          };
          const equalPayload = _.isEqual(payload, latestSaved.current);

          if (equalPayload && isInterval) return true;
          latestSaved.current = _.cloneDeep(payload);
          const response = await postValidateKoldunCsg(payload);
          isLoadingValidation.current = false;

          if (response.data && !_.isEqual(response.data, latestState.inputs)) {
            const parsedValidationResponse = koldunCsgInputStateScheme.parse(response.data);

            setKoldunCsgState((prev) => {
              if (!prev || !response.data) return prev;
              return {
                ...prev,
                inputs: parsedValidationResponse,
              };
            });
            setValidationError([]);
          }
          return true;
        } catch (error: any) {
          isLoadingValidation.current = false;

          setApiError({
            message: dictionary.errorMessage.koldunInputInvalid,
          });
          parseErrorThrown({
            error,
            setApiError,
            setValidationError,
            apiError,
          });
          return false;
        } finally {
          isLoadingValidation.current = false;
        }
      }
    },
    [analysisIdentity, apiError, checkIfDatasetExists, forecastEndDate, koldunCsgState, setApiError]
  );

  // validate every 30 seconds
  useInterval(async () => {
    if (!isLoadingValidation.current) {
      validateKoldun(true);
    }
  }, defaultInterval);

  useEffect(() => {
    latestState.current = koldunCsgState;
  }, [koldunCsgState]);

  useEffect(() => {
    return () => {
      validateKoldun(true, latestState.current);
    };
    // Disable this lint because this is unmounting hooks,
    // when unmount take the state from ref if its from the state, it disappear already
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTab = useCallback(
    async (index: number) => {
      if (index === 1) {
        const isValid = await validateKoldun();
        if (isValid) {
          setTabIndex(index);
          setValidationError([]);
          setApiError();
        } else {
          setTabIndex(0);
          setApiError({
            message: dictionary.errorMessage.koldunInputInvalid,
          });
        }
      } else {
        setTabIndex(index);
      }
    },
    [setApiError, validateKoldun]
  );

  // calculate forecast
  const onClickCalculateForecast = useCallback(async () => {
    if (!analysisIdentity || !koldunCsgState) return;

    try {
      isLoadingValidation.current = true;
      const response = await createPoll<KoldunCsgForecastData, KoldunCsgForecastPayload>({
        path: pollForecastKoldunCsg(project!.id),
        body: {
          options: koldunCsgState,
          analysis_id: analysisIdentity,
        },
        withTaskInfo: true,
        type: "post",
        isBlocking: true,
      });
      if (response.task_result) {
        const parsedKoldunCalculation = koldunCsgForecastDataScheme.parse(response.task_result);
        setKoldunCsgCalculation(parsedKoldunCalculation);
      } else {
        setApiError({
          message: dictionary.serverError,
        });
      }
    } catch (error: any) {
      parseErrorThrown({
        error,
        setApiError,
        setValidationError,
        apiError,
      });
    } finally {
      isLoadingValidation.current = false;
    }
  }, [analysisIdentity, koldunCsgState, createPoll, project, setApiError, apiError]);

  const onClickExport = useCallback(
    async (interval: Interval) => {
      try {
        if (!analysisIdentity) return;

        setIsLoading(true);
        const res = await postExportKoldunCsgForecast({
          projectId: analysisIdentity.project_id,
          body: analysisIdentity,
          interval,
        });

        if (res.data) {
          const fileName = `${res.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(res.data, fileName);
        }
      } catch (error: any) {
        console.log(error);

        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [analysisIdentity, apiError, setApiError, setIsLoading]
  );

  const state = useMemo(() => {
    return {
      tabIndex,
      onChangeTab,
      loadingState: isLoading || isLoadingInitialize || isFetching,

      setApiError,
      dataSets,
      state: koldunCsgState,
      layerOption,
      selectedLayer,
      setSelectedLayer,
      setKoldunCsgState,
      onChangeInputs,
      onClickCalculateForecast,
      onChangeForecast,
      koldunCsgCalculation,
      validationError,
      forecastEndDate,
      setValidationError,
      canCancelPoll,
      onCancelPoll,
      onClickExport,
    };
  }, [
    onClickExport,
    dataSets,
    isFetching,
    isLoading,
    koldunCsgState,
    isLoadingInitialize,
    onChangeTab,
    setApiError,
    tabIndex,
    layerOption,
    selectedLayer,
    setSelectedLayer,
    setKoldunCsgState,
    onChangeInputs,
    onClickCalculateForecast,
    onChangeForecast,
    koldunCsgCalculation,
    validationError,
    forecastEndDate,
    setValidationError,
    canCancelPoll,
    onCancelPoll,
  ]);

  return <KoldunCsgContext.Provider value={state}>{children}</KoldunCsgContext.Provider>;
};

export function useKoldunCsgState() {
  return useContext(KoldunCsgContext);
}

export default KoldunCsgProvider;
