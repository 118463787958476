import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { DataSet, isDataSet, ModuleId, Project } from "@/model";
import { ApiError } from "@/models/APIGeneric";
import { EconomicsOption, economicsResultScheme, EconomicState, economicStateScheme } from "@/models/economics/state";

import dictionary from "@/constants/dictionary";
import { postCalculateEconomics, postInitializeEconomics } from "@/models/economics/fetcher";
import { parseErrorThrown } from "@/utils/errorHandling";
import { CellChange, DateCell, Row } from "@silevis/reactgrid";
import { tableHeaderStyle } from "@/components/CustomTable";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

type useEconomicsProps = {
  selectedDataSets?: DataSet | DataSet[];
  isLoading: boolean;
  project?: Project;
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  currentModule?: ModuleId;
  haveChangeValue: boolean;
  setIsLoading: (val: boolean) => void;
  setHaveChangeValue: (val: boolean) => void;
  canFetch: boolean;
};

export const grossCapexColumn = [
  {
    columnId: "dates",
    width: 200,
    label: dictionary.genericChart.date,
  },
  {
    columnId: "capex",
    width: 200,
    label: dictionary.economics.grossCapex,
  },
];

const useEconomics = ({
  isLoading,
  setApiError,
  apiError,
  project,
  selectedDataSets,
  currentModule,
  haveChangeValue,
  setHaveChangeValue,
  setIsLoading,
  canFetch,
}: useEconomicsProps) => {
  const [economicState, setEconomicState] = useState<EconomicState>();
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-economics", dataSets, project?.id, currentModule],
    queryFn: async () => {
      return postInitializeEconomics(project!.id, currentModule!, {
        data_set_ids: dataSets,
      });
    },
    select(data) {
      try {
        if (data?.data && !economicState) {
          setLatestDataSets(dataSets);
          const parsed = economicStateScheme.parse(data.data);
          if (!_.isEqual(parsed, economicState)) setEconomicState(parsed);
        }
      } catch (error: any) {
        console.log(error.issues);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !!currentModule && !!canFetch && !economicState,
  });

  const grossCapexRow: Row<any>[] = useMemo(() => {
    const headerNotationRows = [
      {
        rowId: "header",
        height: 55,
        cells: [
          ...grossCapexColumn.map((col) => {
            return {
              type: "header",
              text: col.label,
              style: tableHeaderStyle,
            };
          }),
        ],
      },
    ];
    if (!economicState?.economic_option) return headerNotationRows;

    // combine date array from backend with the frontend empty array
    const combinedDateArray = [...economicState.economic_option.gross_capex_schedule.dates, ...Array(100).fill(undefined)];
    const constantInputsRows = [
      ...headerNotationRows,
      ...(combinedDateArray.map((date, i) => {
        return {
          rowId: i,
          cells: [
            ...grossCapexColumn.map((col) => {
              if (col.columnId === "dates") {
                return {
                  type: "date",
                  date: date ? new Date(date) : undefined,
                  format: Intl.DateTimeFormat(),
                  hideZero: true,
                  nonEditable: isLoading,
                };
              }

              return {
                type: "number",
                value: economicState.economic_option.gross_capex_schedule?.capex[i] ?? NaN,
                nonEditable: isLoading,
              };
            }),
          ],
        };
      }) ?? []),
    ] as Row[];

    return constantInputsRows;
  }, [economicState?.economic_option, isLoading]);

  const client = useQueryClient();

  const resetState = useCallback(() => {
    setEconomicState(undefined);
    client?.invalidateQueries();
  }, [client]);

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      resetState();
    }
  }, [client, dataSets, latestDataSets, resetState]);

  useEffect(() => {
    resetState();
  }, [currentModule, resetState]);

  const onChangeParameter = useCallback((value: any, key: string) => {
    setErrorInputValidation([]);
    setEconomicState((prev) => {
      if (!prev) return prev;
      return {
        economic_option: {
          ...prev?.economic_option,
          [key]: value,
        },
        economic_result: null,
      };
    });
  }, []);

  const onChangeGrossCapexTable = useCallback(
    (changes: CellChange[]) => {
      if (!economicState?.economic_option) return;
      setErrorInputValidation([]);

      let updatedGrossCapex = _.cloneDeep(economicState.economic_option.gross_capex_schedule);

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        let val = newCell.value ?? newCell.text ?? 0;
        if (columnId === "dates") {
          const dateCell = newCell as DateCell;
          val = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0));
        }

        updatedGrossCapex[columnId as keyof EconomicsOption["gross_capex_schedule"]][Number(rowId)] = val;
      }

      setEconomicState((prev) => {
        if (!prev) return;
        return {
          economic_option: {
            ...prev?.economic_option,
            gross_capex_schedule: {
              capex: updatedGrossCapex.capex.filter((capex) => !isNaN(capex)),
              dates: updatedGrossCapex.dates.filter((date) => !!date),
            },
          },
          economic_result: null,
        };
      });
    },
    [economicState?.economic_option]
  );

  useEffect(() => {
    if (haveChangeValue) {
      setHaveChangeValue(false);
      setEconomicState((prev) => {
        if (!prev) return;
        return {
          economic_option: {
            ...prev.economic_option,
          },
          economic_result: null,
        };
      });
    }
  }, [haveChangeValue, setHaveChangeValue, economicState]);

  const onClickCalculateEconomic = useCallback(async () => {
    try {
      if (!project || !economicState?.economic_option || !currentModule) return;
      setIsLoading(true);

      const res = await postCalculateEconomics(project?.id, currentModule, {
        data_set_ids: dataSets,
        economic_option: economicState.economic_option,
      });
      if (!_.isEqual(res.data, economicState?.economic_result)) {
        const parsed = economicsResultScheme.parse(res.data);
        setEconomicState((prev) => {
          if (!prev) return prev;
          return {
            economic_option: prev.economic_option,
            economic_result: parsed,
          };
        });
      }
    } catch (error: any) {
      console.log(error);

      parseErrorThrown({
        error,
        setApiError,
        apiError,
        setValidationError: setErrorInputValidation,
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiError, currentModule, dataSets, economicState?.economic_option, economicState?.economic_result, project, setApiError, setIsLoading]);

  return {
    loadingState: isLoading || isFetching || isLoadingInitialize,
    economicState,
    grossCapexRow,
    onChangeParameter,
    onChangeGrossCapexTable,
    errorInputValidation,
    onClickCalculateEconomic,
  };
};

export default useEconomics;
