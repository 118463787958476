import { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row, CheckboxCell, TextCell } from "@silevis/reactgrid";

import { GroupEditItemAdditional } from "@/models/rulon/groupEdit";
import { tableCellStyle } from "@/components/CustomTable";
import { formatToEngineeringNotation, camelToSnakeCase, mapEnumToDropdownReactGrid } from "@/utils/general";

import { spadDeclineColumnKey } from "../constant";
import { generateHeaderSpad, getWellType, sortRulonRows } from "../utils";
import _ from "lodash";
import { SelectedForecastRollup } from "@/models/spad/decline";
import { SortState } from "./type";
import { DataSet } from "@/model";

export type SpadDelineEditProps = {
  loadingState: boolean;
  dataSetsFullInfo: DataSet[];
  groupEditState?: GroupEditItemAdditional[];
  setGroupEditState: React.Dispatch<React.SetStateAction<GroupEditItemAdditional[] | undefined>>;
};

export const editTableColumn = spadDeclineColumnKey.map((key, index) => {
  return {
    columnId: key,
    width: index === 0 ? 200 : 150,
  };
});

const useSpadDeclineEdit = ({ groupEditState, setGroupEditState, dataSetsFullInfo }: SpadDelineEditProps) => {
  const [activeDropdown, setActiveDropdown] = useState(0);

  // if true mean sort alphanumerically
  // select either one will reset the other one ( only 1 active sort )
  const [sortState, setSortState] = useState<SortState>({ name: false, type: false });

  const onClickSort = useCallback((val: { [key: string]: boolean }) => {
    setSortState((prev) => {
      return {
        ...prev,
        ...val,
      };
    });
  }, []);

  const editTableRow: Row<any>[] = useMemo(() => {
    const header = generateHeaderSpad(spadDeclineColumnKey, onClickSort);
    let rows: any =
      groupEditState?.map((row, index) => {
        const dataSetTag = dataSetsFullInfo.filter((dataSet) => row?.data_set_id === dataSet.id)[0]?.tags;
        const wellType = getWellType(dataSetTag).join(", ");

        return {
          rowId: index,
          height: 30,
          name: row.name,
          wellType,
          cells: [
            ...spadDeclineColumnKey.map((key, keyIndex) => {
              if (keyIndex === 0)
                return {
                  type: "text",
                  text: row.name,
                  style: tableCellStyle,
                  nonEditable: true,
                };

              if (keyIndex === 1) {
                return {
                  type: "text",
                  text: wellType,
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }
              // will be the type of spad decline
              const safeRow: any = row.option;

              // display checkbox
              if (typeof safeRow[camelToSnakeCase(key)] === "boolean") {
                return {
                  type: "checkbox",
                  checked: safeRow[camelToSnakeCase(key)],
                };
              }

              // display dropdown
              if (key === "selectedForecastRollup") {
                return {
                  type: "dropdown",
                  selectedValue: safeRow[camelToSnakeCase(key)],
                  values: mapEnumToDropdownReactGrid(SelectedForecastRollup),
                  style: tableCellStyle,
                  isOpen: index + 1 === activeDropdown,
                };
              }
              let val = 0;
              const splittedKey = key.split(".");
              // for forecast layered object;
              if (splittedKey.length > 1) {
                val = safeRow[camelToSnakeCase(splittedKey[0])]?.[splittedKey[1]]?.[camelToSnakeCase(splittedKey[2])] ?? 0;
              } else {
                val = safeRow[camelToSnakeCase(splittedKey[0])];
              }
              return {
                type: "text",
                text: formatToEngineeringNotation(val),
                style: tableCellStyle,
              };
            }),
          ],
        };
      }) ?? [];
    rows = sortRulonRows(rows, sortState) as Row<any>[];
    return [header, ...rows];
  }, [activeDropdown, dataSetsFullInfo, groupEditState, onClickSort, sortState]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!groupEditState) return;
      const updatedDataOption: any = _.cloneDeep(groupEditState);
      let haveChanged = false;

      // Handle dropdown cell changes
      const handleDropdownChange = (rowId: number, newCell: DropdownCell, previousCell: DropdownCell) => {
        // Toggle active dropdown if needed
        if (previousCell.isOpen !== newCell.isOpen || rowId !== Number(activeDropdown) + 1) {
          setActiveDropdown(newCell.isOpen ? rowId + 1 : 0);
        }

        // If dropdown is open, skip the value change
        if (newCell.isOpen) return false;

        // Check if the selected value has changed and update if needed
        if (String(previousCell.selectedValue) !== newCell.selectedValue) {
          updatedDataOption[rowId].option.selected_forecast_rollup = Number(newCell.selectedValue);
          return true;
        }

        return false;
      };

      // Handle checkbox cell changes
      const handleCheckboxChange = (rowId: number, columnId: string, previousCell: CheckboxCell) => {
        // Check if checkbox state has changed and update if needed
        updatedDataOption[rowId].option[camelToSnakeCase(columnId)] = !previousCell.checked;
        return true;
      };

      // Handle default cell changes (non-dropdown, non-checkbox)
      const handleDefaultChange = (rowId: number, columnId: string, newCell: TextCell) => {
        const splittedKey = columnId.split(".");

        if (splittedKey.length > 1) {
          // Handle nested structure for multi-level keys

          // since operational / ops forecast can be null
          // Ensure the nested structure exists, then assign the new value
          // Check if the property for the first key (converted to snake_case) exists, otherwise initialize it
          const key0 = camelToSnakeCase(splittedKey[0]); // -> ops/forecast layer
          updatedDataOption[rowId].option[key0] = updatedDataOption[rowId].option[key0] || {};

          // Check if the property for the second key exists, otherwise initialize it
          const key1 = splittedKey[1]; // -> low, mid, high layer
          updatedDataOption[rowId].option[key0][key1] = updatedDataOption[rowId].option[key0][key1] || {};

          // Finally, assign the new value to the third key (converted to snake_case)
          const key2 = camelToSnakeCase(splittedKey[2]); // -> the actual value
          updatedDataOption[rowId].option[key0][key1][key2] = Number(newCell.text);
        } else {
          // For single-level keys, directly assign the value
          updatedDataOption[rowId].option[camelToSnakeCase(splittedKey[0])] = Number(newCell.text);
        }

        return true;
      };

      // Process each change in the changes array
      for (const change of changes) {
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        rowId = rowId as number;
        columnId = columnId as string;

        // Use appropriate handler depending on the cell type
        switch (type) {
          case "dropdown":
            haveChanged = handleDropdownChange(rowId, newCell as DropdownCell, previousCell as DropdownCell) || haveChanged;
            break;
          case "checkbox":
            haveChanged = handleCheckboxChange(rowId, columnId, previousCell as CheckboxCell) || haveChanged;
            break;
          default:
            haveChanged = handleDefaultChange(rowId, columnId, newCell) || haveChanged;
            break;
        }
      }

      // If any changes were made, update the groupEditState to prevent rerendering
      if (haveChanged) {
        setGroupEditState(_.cloneDeep(updatedDataOption));
      }
    },
    [activeDropdown, groupEditState, setGroupEditState]
  );

  return { editTableRow, onCellsChanged };
};

export default useSpadDeclineEdit;
