import { useMemo, useState } from "react";

import InputField from "@/components/fields/InputField";
import LoadingIndicator from "@/components/LoadingIndicator";
import Tabs from "@/components/Tabs";

import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

import { useKoldunCsgState } from "../../hooks/KoldunCsgContext";
import ReservoirInputs from "./charts/ReservoirInputs";
import GasPvt from "./charts/GasPvt";
import WaterPvt from "./charts/WaterPvt";
import RelativePermeability from "./charts/RelativePermeability";
import WellInput from "./charts/WellInput";
import Geomechanics from "./charts/Geomechanics";
import CustomCard from "@/components/Card";

const errorSection = [
  "number_of_layers",
  "reservoir_area",
  "formation_temperature",
  "selected_formation_compressibility_correlation",
  "formation_compressibility",
  "layer_depth",
  "permeability_vertical",
  "specific_gravity",
  "selected_skin_model",
  "skin_value",
  "relative_permeability",
  "selected_z_correlation",
  "selected_gas_viscosity_correlation",
  "selected_water_viscosity_correlation",
  "nitrogen",
  "carbon_dioxide",
  "hydrogen_sulphide",
  "salinity",
  "selected_water_compressibility_correlation",
  "geomechanics",
  "wellbore_radius",
  "well_inputs",
];
const ModelComponentInput = () => {
  const { state, loadingState, setKoldunCsgState, onChangeInputs, validationError, tabIndex } = useKoldunCsgState();
  const { palette } = useThemeStyling();

  const [latestActiveState, setLatestActiveState] = useState(0);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.koldunCsg.reservoirInput}</span>,
        key: dictionary.koldunCsg.reservoirInput,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <ReservoirInputs
              specifyInput={state?.inputs.specify_inputs}
              loadingState={loadingState}
              measures={state?.inputs?.measures}
              setKoldunCsgState={setKoldunCsgState}
            />
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.gasPvt}</span>,
        key: dictionary.koldunCsg.gasPvt,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <GasPvt loadingState={loadingState} measures={state?.inputs?.measures} setKoldunCsgState={setKoldunCsgState} />
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.waterPvt}</span>,
        key: dictionary.koldunCsg.waterPvt,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <WaterPvt loadingState={loadingState} measures={state?.inputs?.measures} setKoldunCsgState={setKoldunCsgState} />
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.relativePermeability}</span>,
        key: dictionary.koldunCsg.relativePermeability,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <RelativePermeability loadingState={loadingState} measures={state?.inputs?.measures} setKoldunCsgState={setKoldunCsgState} />
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.wellInput}</span>,
        key: dictionary.koldunCsg.wellInput,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <WellInput
              powerLawSkin={state.inputs.power_law_skin}
              loadingState={loadingState}
              measures={state?.inputs?.measures}
              setKoldunCsgState={setKoldunCsgState}
            />
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.geomechanics}</span>,
        key: dictionary.koldunCsg.geomechanics,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <Geomechanics loadingState={loadingState} measures={state?.inputs?.measures} setKoldunCsgState={setKoldunCsgState} />
          ),
      },
    ];
  }, [loadingState, state, setKoldunCsgState]);

  const errorTableInput = useMemo(() => {
    return validationError.filter((err) => errorSection.indexOf(err.loc[4]) !== -1);
  }, [validationError]);

  return (
    <CustomCard>
      <h4
        style={{
          color: palette.primary.main,
          marginBottom: 10,
        }}
      >
        {dictionary.koldunCsg.modelComponentInput}
      </h4>

      <div
        style={{
          width: 450,
        }}
      >
        <InputField
          type="int"
          disabled={loadingState}
          value={state?.inputs.number_of_measures}
          label={dictionary.koldunCsg.numberOfCoalMeasures}
          debounceDelay={100}
          onChange={(v) => {
            onChangeInputs("number_of_measures", Number(v ?? 0));
          }}
          errors={validationError}
          keyField="options.number_of_measures"
        />
      </div>

      <div style={{ color: palette.error.main }}>
        {errorTableInput.map((err) => {
          return (
            <span key={err.msg}>
              {dictionary.koldunCsg.measure} {err.loc[3]}: {err.loc[err.loc.length - 1].replaceAll("_", " ")} {err.msg}
              <br />
            </span>
          );
        })}
      </div>
      {tabIndex === 0 && <Tabs customActiveTab={latestActiveState} onClickItem={(val) => setLatestActiveState(val)} tabList={tabList} />}
    </CustomCard>
  );
};

export default ModelComponentInput;
