/* eslint-disable no-useless-escape */
// saving in laTex syntax
const symbolUnits: { [key: string]: string } = {
  "Gas rate": "q_g",
  "Dry gas rate": "q_{g}\\left|_{dry}\\right.",
  "Recombined gas rate": "q_{g}\\left|_{recombined}\\right.",
  "Cumulative gas production": "Q_g",
  "Dry cumulative gas production": "Q_{g}\\left|_{dry}\\right.",
  "Recombined cumulative gas production": "Q_{g}\\left|_{recombined}\\right.",
  "Normalised gas rate": "N_{qg}",
  "Normalised cumulative gas production": "Q_{Ng}",
  "Gas formation volume factor": "B_{g}",
  "Gas viscosity": "\\mu_{g}",
  "Gas saturation": "s_{g}",
  "Gas relative permeability": "k_{rg}",
  "Gas compressibility": "c_{g}",
  "Water rate": "q_{w}",
  "Cumulative water production": "Q_{w}",
  "Normalised water rate": "N_{qw}",
  "Normalised cumulative water production": "Q_{Nw}",
  "Water formation volume factor": "B_{w}",
  "Water viscosity": "\\mu_{w}",
  "Water saturation": "s_{w}",
  "Water relative permeability": "k_{rw}",
  "Oil rate": "q_{o}",
  "Cumulative oil production": "Q_{o}",
  "Normalised oil rate": "N_{qo}",
  "Normalised cumulative oil production": "Q_{No}",
  "Oil formation volume factor": "B_{o}",
  "Oil viscosity": "\\mu_{o}",
  "Oil saturation": "s_{o}",
  "Oil relative permeability": "k_{ro}",
  "Initial pressure": "p_{i}",
  Permeability: "k",
  "Average pressure": "\\bar{p}",
  "Flowing pressure": "p_{wf}",
  "Average pseudo pressure": "\\bar{\\Psi}",
  "Pseudo flowing pressure": "\\Psi_{wf}",
  "Langmuir volume": "v_{L}",
  "Langmuir pressure": "p_{L}",
  "Formation density": "\\rho_{F}",
  "Desorption pressure": "p_{d}",
  Z: "z",
  "Z star": "z^{\\star}",
  "Z double star": "z^{\\star\\star}",
  "Average pressure on Z": "\\bar{p}/z",
  "Average pressure on Z star": "\\bar{p}/z^{\\star}",
  "Average pressure star on Z double star": "\\bar{p}^{\\star}/z^{\\star\\star}",
  "Average pressure star": "\\bar{p}^{\\star}",
  "Average pseudo pressure star": "\\bar{\\Psi}^{\\star}",
  "Productivity Index": "J",
  "Average total compressibility": '\\bar{c}_{t}',
  "Oil density": '\\bar{\\rho}_{o}',
  "Oil compressibility": "\\bar{c}_{o}"
};

// implementation based on here:
// https://predico.atlassian.net/wiki/spaces/SD/pages/322568215/Symbols+and+Units
export default symbolUnits;
