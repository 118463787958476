import React, { useCallback, useEffect, useMemo, useRef, useReducer } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

import { ProjectStructureErrorCode, PromiseResult, PromiseResultDetail, postProjectStructure } from "@/models/wells";

import { Container, VisuallyHiddenInput } from "../shared/style";
import IndividualItem from "../shared/components/IndividualItem";
import CustomTable, { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { mapArrayToDropdownReactGrid } from "@/utils/general";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";
import CustomCard from "@/components/Card";

import { importProjectStructureReducer, initialState, getColumnIndexWithAlphabets } from "./reducer";

const enumColumnHeader = ["well", "group", "project"];

const ImportProjectStructure = () => {
  const { refreshProjects } = useTreeViewState();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [state, dispatch] = useReducer(importProjectStructureReducer, initialState);
  const { file, res, isLoading, csvText, csvHeaderText, selectedField, isDropdownOpened } = state;

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const resetFileState = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const attemptAutoMapping = useCallback((headers: string[]): (string | undefined)[] => {
    const newSelectedFields: (string | undefined)[] = [undefined, undefined, undefined];
    const available = [...headers];

    enumColumnHeader.forEach((targetField, idx) => {
      const matchIndex = available.findIndex((col) => col.toLowerCase() === targetField.toLowerCase());
      if (matchIndex > -1) {
        newSelectedFields[idx] = available[matchIndex];
        available.splice(matchIndex, 1);
      }
    });

    return newSelectedFields;
  }, []);

  const handleOnChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files?.[0];
      if (!selectedFile) return;

      dispatch({ type: "SET_RES", payload: undefined });
      dispatch({ type: "SET_FILE", payload: selectedFile });

      const text = await selectedFile.text();
      const splitted = text.split("\n");
      const header = splitted[0]?.split(",") ?? [];

      if (header.length < 1 || header.every((col) => col.trim().length === 0)) {
        dispatch({
          type: "SET_RES",
          payload: {
            code: 400,
            detail: {
              code: "validate_error",
              message: dictionary.wellImport.minimumColumnsError,
            },
            status: "rejected",
          },
        });
        resetFileState();
      } else {
        dispatch({ type: "SET_CSV_HEADER_TEXT", payload: header });
        dispatch({ type: "SET_CSV_TEXT", payload: splitted.slice(1).join("\n") });

        const autoSelected = attemptAutoMapping(header);
        if (autoSelected.some((val) => val !== undefined)) {
          dispatch({ type: "SET_SELECTED_FIELD", payload: autoSelected });
        }
      }
    },
    [resetFileState, attemptAutoMapping]
  );

  const handleOnSubmit = useCallback(async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });

      if (csvText && csvHeaderText && file) {
        const newHeader = [...csvHeaderText];

        // Replace selected fields with enumColumnHeader
        selectedField.forEach((field, index) => {
          const headerIndex = newHeader.indexOf(field as string);
          if (headerIndex >= 0) {
            newHeader[headerIndex] = enumColumnHeader[index];
          }
        });

        const newText = [newHeader.join(","), csvText].join("\n");
        const newFile = new File([newText], file.name, {
          type: "data:text/csv;charset=utf-8",
        });

        const response = await postProjectStructure({ file: newFile });
        const safeRes = response as {
          code: number;
          detail: PromiseResultDetail<ProjectStructureErrorCode>;
        };

        const reMappedResult: PromiseResult<ProjectStructureErrorCode> = {
          code: safeRes?.code,
          detail: safeRes?.detail,
          status: "fulfilled",
        };

        dispatch({ type: "SET_RES", payload: reMappedResult });
        resetFileState();
        await refreshProjects();
      }
    } catch (error: any) {
      if (error.code === 400) {
        dispatch({
          type: "SET_RES",
          payload: {
            code: error.code,
            detail: error.detail,
            status: "rejected",
          },
        });
      }
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }, [csvText, csvHeaderText, file, refreshProjects, selectedField, resetFileState]);

  const handleCancel = useCallback(() => {
    dispatch({ type: "SET_RES", payload: undefined });
    resetFileState();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [resetFileState]);

  const renderRightButton = () => {
    // If upload was successful
    if (res?.status === "fulfilled") {
      return (
        <Button
          data-testid="ok-button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/modules";
          }}
          style={{ color: "white", marginLeft: 20 }}
          variant="contained"
        >
          {dictionary.wellImport.ok}
        </Button>
      );
    }

    const allSelected = selectedField.every((col) => col !== undefined);
    return (
      <Button
        data-testid="submit-button"
        onClick={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
        disabled={!file || isLoading || !allSelected}
        style={{ color: "white", marginLeft: 20 }}
        variant="contained"
      >
        {dictionary.wellImport.submit}
      </Button>
    );
  };

  // ReactGrid columns
  const columns = useMemo(
    () => [
      { columnId: "target", width: 250 },
      { columnId: "field", width: 250 },
    ],
    []
  );

  const headerCsv = useMemo(() => {
    if (!csvHeaderText || !csvText) return [];
    const columnIndexes = getColumnIndexWithAlphabets(csvText);
    return csvHeaderText.filter((_, index) => columnIndexes.includes(index));
  }, [csvHeaderText, csvText]);

  const rows: Row<any>[] = useMemo(() => {
    if (!csvHeaderText) return [];

    const option = mapArrayToDropdownReactGrid(headerCsv);

    return [
      {
        rowId: "header",
        cells: [
          {
            type: "header",
            text: "Target Fields",
            style: tableHeaderStyle,
          },
          {
            type: "header",
            text: "Input Column",
            style: tableHeaderStyle,
          },
        ],
        height: 50,
      },
      ...enumColumnHeader.map((header, index) => ({
        rowId: index,
        height: 30,
        cells: [
          {
            type: "text",
            text: header,
          },
          {
            type: "dropdown",
            text: header,
            selectedValue: selectedField[index],
            values: option,
            style: tableCellStyle,
            isOpen: isDropdownOpened === index + 1,
          },
        ],
      })),
    ];
  }, [csvHeaderText, headerCsv, isDropdownOpened, selectedField]);

  const onCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!csvHeaderText) return;
      const updatedFields = [...selectedField];

      for (const element of changes) {
        const { rowId, newCell, previousCell } = element;
        const prevDropdownCell = previousCell as DropdownCell;
        const newDropdownCell = newCell as DropdownCell;
        const rowIndex = Number(rowId);

        // Check if dropdown is opened/closed
        const newOpen = newDropdownCell.isOpen ? rowIndex + 1 : 0;
        if (prevDropdownCell.isOpen !== newDropdownCell.isOpen && newOpen !== isDropdownOpened) {
          dispatch({ type: "SET_DROPDOWN_OPEN", payload: newOpen });

          const newVal = String(newDropdownCell.selectedValue);
          if (prevDropdownCell.selectedValue !== newDropdownCell.selectedValue && newVal) {
            const dupIndex = updatedFields.indexOf(newVal);
            if (dupIndex > -1) {
              updatedFields[dupIndex] = undefined;
            }
            updatedFields[rowIndex] = newVal;
            dispatch({ type: "SET_SELECTED_FIELD", payload: [...updatedFields] });
          }
        }
      }
    },
    [csvHeaderText, isDropdownOpened, selectedField]
  );

  const allSelected = selectedField.every((col) => col !== undefined);

  return (
    <Container data-testid="import-project-structure-container">
      <CustomCard style={{ width: 700, maxHeight: "60vh" }} data-testid="import-project-structure-card">
        <h3 data-testid="import-project-structure-title">
          <a data-testid="help-link" target="_blank" rel="noreferrer" href={helpLinkUrl.projectImport}>
            {dictionary.nav.importProjects} <InfoOutlinedIcon fontSize="small" />
          </a>
        </h3>

        {/* If user has selected a file but hasn't mapped all fields yet, and there's no successful result */}
        {file && !allSelected && res?.status !== "fulfilled" && (
          <p style={{ color: "red", marginTop: 8 }} data-testid="all-fields-required-error">
            {dictionary.projectImport.selectAllRequired}
          </p>
        )}

        <div className="list-container" data-testid="list-container">
          <div data-testid="file-list">{file && !res && <IndividualItem key={file.name} name={file.name} />}</div>

          {res && (
            <div data-testid="response-container">
              <IndividualItem
                success={res.status === "fulfilled" ? dictionary.projectImport.success : undefined}
                error={res.detail?.message}
                key={file?.name}
                name={file?.name ?? ""}
              />
            </div>
          )}

          {csvHeaderText && (
            <div data-testid="mapping-table">
              <CustomTable style={{ display: "flex", height: 250 }} onCellsChanged={onCellChange} columns={columns} rows={rows} stickyTopRows={1} />
            </div>
          )}
        </div>

        <div className="button-container" data-testid="button-container">
          <Button
            data-testid="choose-button"
            disabled={isLoading}
            style={{ color: "white" }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {dictionary.wellImport.choose}
            <VisuallyHiddenInput
              data-testid="file-input"
              ref={fileInputRef}
              onClick={(e: any) => {
                e.target.value = null;
              }}
              onChange={handleOnChange}
              type="file"
              accept=".csv"
            />
          </Button>

          {file && res?.status !== "fulfilled" && (
            <Button data-testid="cancel-button" onClick={handleCancel} style={{ marginLeft: 20 }} variant="outlined">
              Cancel
            </Button>
          )}

          {renderRightButton()}
        </div>
      </CustomCard>
    </Container>
  );
};

export default ImportProjectStructure;
