import React, { createContext, useContext, useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { DataSet, isDataSet, ModuleId, Group, Project } from "@/model";

import { CsvData } from "@/features/app/app.types";
import { analysisOptionDummy, dummyValidation, forecastDummy, spadDeclineDefaultState } from "../constants";

import useInterval from "@/utils/useInterval";

import { ApiError } from "@/models/APIGeneric";
import {
  SpadDeclineState,
  postCalculationSpadDecline,
  postDragSpadDecline,
  postInitializeSpadDecline,
  postValidateSpadDecline,
  SpadDeclineCalculationScheme,
  spadDeclineChartDragScheme,
} from "@/models/spad/decline";
import { postExportSpadDecline } from "@/models/spad/decline/apiFetcher/export";
import { saveBlob } from "@/util";
import dictionary from "@/constants/dictionary";
import { FluidType, HandlebarCoordinateItem, Interval } from "@/models/Generic";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { capitalizeFirstLetter } from "@/utils/general";

export type ValidationError = {
  general: ErrorValidationDetail[];
  operational_forecast: string[];
  profile_forecast: string[];
};

type SpadDeclineContextState = {
  isLoading: boolean;

  dataSets: DataSet[];
  group?: Group;
  project?: Project;
  tabIndex: number;
  setTabIndex: (index: number) => void;

  setCsvData: (csvData?: CsvData[]) => void;
  isApiError?: ApiError;
  setLocalLoading: (val: boolean) => void;

  spadDeclineState?: SpadDeclineState;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  currentModule?: ModuleId;
  onChartDrag: (handleBar: HandlebarCoordinateItem) => void;
  onClickCalculate: () => void;
  onClickExport: (interval: Interval) => void;

  projects?: Project[];
  dataSetList?: DataSet[];
  selectedDataSets?: DataSet | DataSet[];
  validationError: ValidationError;
  spadDeclineType: FluidType;
};

const SpadDeclineContext = createContext<SpadDeclineContextState>(spadDeclineDefaultState);

type SpadDeclineContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  currentModule?: ModuleId;
  isLoading: boolean;
  group?: Group;
  project?: Project;
  setCsvData: (csvData?: CsvData[]) => void;
  apiError?: ApiError;

  projects?: Project[];
  dataSets?: DataSet[];
  setApiError: (error?: ApiError) => void;
  checkIfDatasetExists: ({ datasetIds, groupId, projectId }: { datasetIds: string[]; groupId: string; projectId: string }) => boolean;
};

const defaultInterval = 30000;

const SpadDeclineProvider = ({
  children,
  selectedDataSets,
  currentModule,
  isLoading,
  project,
  group,
  setCsvData,
  apiError,
  projects,
  dataSets: dataSetList,
  setApiError,
  checkIfDatasetExists,
}: Readonly<SpadDeclineContextProps>) => {
  const [tabIndex, setTabIndex] = useState(0);

  const [localLoading, setLocalLoading] = useState(false);

  const [spadDeclineState, setSpadDeclineState] = useState<SpadDeclineState>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const [validationError, setValidationError] = useState<ValidationError>(dummyValidation);
  // need this for comparing latest state with new payload for save feature
  const latestSavedPayload = useRef<any>();

  // we need this for saving state before unmounting
  const latestState = useRef<any>();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const dataSetIds = useMemo(() => dataSets.map((dataSet) => dataSet.id), [dataSets]);

  const spadDeclineType = currentModule === ModuleId.SPAD_DECLINE_GAS ? FluidType.gas : FluidType.oil;
  const client = useQueryClient();

  const { isLoading: isLoadingInitialize } = useQuery({
    queryKey: ["initialize-spad-decline", project?.id, dataSetIds, spadDeclineState],
    queryFn: async () => {
      return postInitializeSpadDecline(project?.id ?? "", dataSetIds, spadDeclineType);
    },
    select(data) {
      try {
        if (data?.data && !spadDeclineState) {
          const parsed = SpadDeclineCalculationScheme.parse(data.data);
          const restructed = {
            ...parsed,
          };

          // add default if null from backend
          if (!restructed.analysis_option) {
            // default analysis option
            restructed.analysis_option = _.cloneDeep(analysisOptionDummy);
          }

          if (!restructed.analysis_option.operational_forecast) restructed.analysis_option.operational_forecast = _.cloneDeep(forecastDummy);
          if (!restructed.analysis_option.profile_forecast) restructed.analysis_option.profile_forecast = _.cloneDeep(forecastDummy);

          // parse the days to date
          setSpadDeclineState(restructed);
          setLatestDataSets(dataSets.map((dataSet) => dataSet.id));
        }
      } catch (error: any) {
        console.log(error?.issues);
        if (error?.issues) {
          const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
          if (apiError?.message !== parseError) {
            setApiError({
              message: parseError,
            });
          }
        }
      }
    },
    throwOnError(error: any) {
      setSpadDeclineState({
        analysis_option: _.cloneDeep(analysisOptionDummy),

        analysis_result: null,
        analysed_data: null,
        chart_handlebars: null,
        forecast_start_day: null,
        forecast_end_day: null,
        day_zero_date: null,
      });
      setLatestDataSets(dataSets.map((dataSet) => dataSet.id));
      return false;
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !spadDeclineState && tabIndex === 1,
  });

  useEffect(() => {
    if (
      latestDataSets.length > 0 &&
      !_.isEqual(
        latestDataSets,
        dataSets.map((dataSet) => dataSet.id)
      )
    ) {
      setSpadDeclineState(undefined);
      setValidationError(_.cloneDeep(dummyValidation));
      latestSavedPayload.current = undefined;
      client?.invalidateQueries();
    }
  }, [dataSets, latestDataSets, client]);

  const isFetching = useIsFetching();

  const onClickTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      if (index !== 0) setCsvData();
    },
    [setCsvData]
  );

  const onSaveSpadDecline = useCallback(
    async (state?: SpadDeclineState, projectId?: string, groupId?: string) => {
      const latestState = state ?? spadDeclineState;
      const latestProjectId = projectId ?? project?.id;
      const latestGroupId = groupId ?? group?.id;
      if (latestState?.analysis_option && latestProjectId && latestGroupId) {
        if (
          !checkIfDatasetExists({
            projectId: latestProjectId,
            groupId: latestGroupId,
            datasetIds: dataSetIds,
          })
        )
          return;
        const payload = {
          data_set_ids: dataSetIds,
          analysis_option: latestState?.analysis_option,
        };

        if (_.isEqual(payload, latestSavedPayload.current)) return;
        try {
          setValidationError(_.cloneDeep(dummyValidation));
          latestSavedPayload.current = _.cloneDeep(payload);
          await postValidateSpadDecline(payload, spadDeclineType, latestProjectId);
        } catch (error: any) {
          if (error.code === 422 && error.detail?.length > 0) {
            // filter forecast table
            const validationErr: ValidationError = _.cloneDeep(dummyValidation);
            error.detail.forEach((err: ErrorValidationDetail) => {
              if (err.loc[2] === "operational_forecast" || err.loc[2] === "profile_forecast") {
                validationErr[err.loc[2]].push(`${capitalizeFirstLetter(err.loc[3])} - ${err.loc[4].replace(/_/g, " ")} ${err.msg}`);
              } else {
                validationErr.general.push(err);
              }
            });
            setValidationError(validationErr);
            console.log(error.detail);
          }
          console.log(error);
        }
      }
    },
    [checkIfDatasetExists, dataSetIds, group?.id, project?.id, spadDeclineState, spadDeclineType]
  );

  useEffect(() => {
    latestState.current = spadDeclineState;
  }, [spadDeclineState]);

  // validate every 30 seconds
  useInterval(async () => {
    if (tabIndex === 1) onSaveSpadDecline();
  }, defaultInterval);

  useEffect(() => {
    return () => {
      onSaveSpadDecline(latestState.current, project?.id ?? "", group?.id ?? "");
    };
    // Disable this lint because this is unmounting hooks,
    // when unmount take the state from ref if its from the state, it disappear already
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCalculate = useCallback(async () => {
    if (!spadDeclineState?.analysis_option || !project?.id) return;
    try {
      setLocalLoading(true);
      setValidationError(_.cloneDeep(dummyValidation));
      const res = await postCalculationSpadDecline(
        {
          data_set_ids: dataSetIds,
          analysis_option: spadDeclineState?.analysis_option,
        },
        spadDeclineType,
        project?.id
      );
      if (res.data) {
        const safeParsedRes = SpadDeclineCalculationScheme.parse(res.data);
        setSpadDeclineState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            ...safeParsedRes,
          };
        });
      }
    } catch (error: any) {
      console.log(error);
      if (error?.issues) {
        const parseError = error?.issues?.map((issue: any) => issue.message).join(", ");
        if (apiError?.message !== parseError && error?.issues?.length > 0) {
          setApiError({
            message: parseError,
          });
        }
        // API ERROR ON BELOW ONWARD
      } else if (error.message) {
        setApiError({
          code: error?.code,
          message: error?.message,
          severity: error?.severity,
        });
      } else if (error.code === 422 && error.detail?.length) {
        // filter forecast table
        const validationErr: ValidationError = _.cloneDeep(dummyValidation);
        error.detail.forEach((err: ErrorValidationDetail) => {
          if (err.loc[2] === "operational_forecast" || err.loc[2] === "profile_forecast") {
            validationErr[err.loc[2]].push(`${capitalizeFirstLetter(err.loc[3])} - ${err.loc[4].replace(/_/g, " ")} ${err.msg}`);
          } else {
            validationErr.general.push(err);
          }
        });
        setValidationError(validationErr);
        console.log(error.detail);
      } else if (error.detail) {
        if (apiError?.message !== dictionary.serverError) {
          setApiError({
            message: dictionary.serverError,
          });
        }
      }
    } finally {
      setLocalLoading(false);
    }
  }, [apiError?.message, dataSetIds, project?.id, setApiError, spadDeclineState?.analysis_option, spadDeclineType]);

  const onClickExport = useCallback(
    async (interval: Interval) => {
      if (!spadDeclineState?.analysis_option || !project?.id) return;

      try {
        const response = await postExportSpadDecline({
          body: {
            data_set_ids: dataSetIds,
            analysis_option: spadDeclineState?.analysis_option,
          },
          type: spadDeclineType,
          interval,
          projectId: project?.id,
        });

        if (response.data) {
          const fileName = `${response.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(response.data, fileName);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dataSetIds, project?.id, spadDeclineState, spadDeclineType]
  );

  const onChartDrag = useCallback(
    async (handlebar: HandlebarCoordinateItem) => {
      if (!spadDeclineState?.analysis_option || !project?.id) return;
      try {
        const chartDragResponse = await postDragSpadDecline(
          {
            data_set_ids: dataSetIds,
            analysis_option: spadDeclineState?.analysis_option,
            handlebar,
          },
          spadDeclineType,
          project?.id
        );
        if (chartDragResponse.data) {
          const safeParsedRes = spadDeclineChartDragScheme.parse(chartDragResponse.data);
          setSpadDeclineState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              ...safeParsedRes,
            };
          });
        }
      } catch (error: any) {
        console.log(error, error?.issues);
        if (error?.issues) {
          const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
          if (apiError?.message !== parseError && error?.issues?.length > 0) {
            setApiError({
              message: parseError,
            });
          }
        }
      }
    },
    [apiError?.message, dataSetIds, project?.id, setApiError, spadDeclineState, spadDeclineType]
  );

  const state = useMemo(() => {
    return {
      isLoading: isLoading || isFetching > 0 || localLoading || isLoadingInitialize,

      dataSets,
      tabIndex,
      setTabIndex: onClickTab,

      setCsvData,
      isApiError: apiError,
      setLocalLoading,
      spadDeclineState,
      setSpadDeclineState,
      currentModule,
      onChartDrag,
      onClickCalculate,
      onClickExport,
      projects,
      dataSetList,
      selectedDataSets,
      project,
      group,
      validationError,
      spadDeclineType,
    };
  }, [
    group,
    project,
    isLoading,
    isFetching,
    localLoading,
    isLoadingInitialize,
    dataSets,
    tabIndex,
    onClickTab,
    setCsvData,
    apiError,
    spadDeclineState,
    currentModule,
    onChartDrag,
    onClickCalculate,
    onClickExport,
    projects,
    dataSetList,
    selectedDataSets,
    validationError,
    spadDeclineType,
  ]);

  return <SpadDeclineContext.Provider value={state}>{children}</SpadDeclineContext.Provider>;
};

export function useSpadDeclineState() {
  return useContext(SpadDeclineContext);
}

export default SpadDeclineProvider;
