import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import useGenericDatasetInfo, { UseGenericDatasetInfoProps } from "@/utils/useGenericModuleInfo";
import { parseErrorThrown } from "@/utils/errorHandling";

import { FluidType, ModuleIdentity } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";
import { AnalysisFmbResult, AutoFmbState, autoFmbStateScheme, ForecastCalculationState, postInitializeFmb } from "@/models/autoFmb";

import { defaultAutoFmbState } from "../constants";
import { ModuleId } from "@/model";

export type ApiHelper = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
  currentModule?: ModuleId;
};

type AutoFmbContextState = {
  tabIndex: number;
  dataSets: string[];
  isLoading: boolean;
  analysisIdentity?: ModuleIdentity;
  fluidModuleType: FluidType;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  autoFmbState?: AutoFmbState;
  setAutoFmbState: React.Dispatch<React.SetStateAction<AutoFmbState | undefined>>;
  setAutoFmbForecastCalculation: React.Dispatch<React.SetStateAction<ForecastCalculationState | undefined>>;
  autoFmbForecastCalculation?: ForecastCalculationState;
  autoFmbAnalysisCalculation?: AnalysisFmbResult;
  setAutoFmbAnalysisCalculation: React.Dispatch<React.SetStateAction<AnalysisFmbResult | undefined>>;
  updateInputFromAnalysis: boolean;
  setUpdateInputFromAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
  flowingPressureOptions?: string[];
  expandParameter: boolean;
} & ApiHelper;

type AutoFmbProps = {
  children: React.ReactNode;
  isLoading: boolean;
  expandParameter: boolean;
} & UseGenericDatasetInfoProps &
  ApiHelper;

export type SummaryFmb = {
  parameter: string | number;
  low: string | number;
  mid: string | number;
  high: string | number;
  units: string | number;
}[];

const AutoFmbContext = createContext<AutoFmbContextState>(defaultAutoFmbState);

const AutoFmbProvider = ({
  project,
  group,
  isLoading,
  selectedDataSets,
  children,
  setApiError,
  setIsLoading,
  apiError,
  setProgress,
  setPollStatus,
  expandParameter = true,
  currentModule,
}: Readonly<AutoFmbProps>) => {
  const { analysisIdentity, dataSets, setTabIndex, tabIndex } = useGenericDatasetInfo({
    selectedDataSets,
    group,
    project,
  });

  const [autoFmbState, setAutoFmbState] = useState<AutoFmbState | undefined>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const [autoFmbForecastCalculation, setAutoFmbForecastCalculation] = useState<ForecastCalculationState>();
  const [autoFmbAnalysisCalculation, setAutoFmbAnalysisCalculation] = useState<AnalysisFmbResult>();

  const [flowingPressureOptions, setFlowingPressureOptions] = useState<string[]>();
  const [updateInputFromAnalysis, setUpdateInputFromAnalysis] = useState(false);

  const client = useQueryClient();

  const fluidModuleType = useMemo(() => {
    return currentModule === ModuleId.GAZ_FMB ? FluidType.gas : FluidType.oil;
  }, [currentModule]);

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-auto-fmb", dataSets, analysisIdentity.project_id, fluidModuleType],
    queryFn: async () => {
      return postInitializeFmb(analysisIdentity.project_id, fluidModuleType, {
        data_set_ids: dataSets,
      });
    },
    select(data) {
      try {
        if (data?.data && !autoFmbState) {
          const parsed = autoFmbStateScheme.parse(data.data);
          setAutoFmbState(parsed);
          setLatestDataSets(dataSets);
          if (parsed.available_flowing_pressures) {
            setFlowingPressureOptions(parsed.available_flowing_pressures);
          }
          if (parsed.forecast_result) {
            setAutoFmbForecastCalculation({
              forecast_result: {
                low: parsed.forecast_result!.low,
                mid: parsed.forecast_result!.mid,
                high: parsed.forecast_result!.high,
              },
              field_data: parsed.forecast_result!.field_data,
            });
          }
          if (parsed.analysis_result) {
            setAutoFmbAnalysisCalculation({
              analysis_results: parsed.analysis_result,
              analysis_input: parsed.analysis,
            });
          }
        }
      } catch (error: any) {
        console.log(error.issues);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!analysisIdentity.project_id && tabIndex > 0,
  });

  const resetState = useCallback(() => {
    setAutoFmbState(undefined);
    setAutoFmbForecastCalculation(undefined);
    setAutoFmbAnalysisCalculation(undefined);
    setFlowingPressureOptions(undefined);
    setUpdateInputFromAnalysis(false);
    client?.invalidateQueries();
  }, [client]);

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      resetState();
    }
  }, [client, dataSets, latestDataSets, resetState]);

  useEffect(() => {
    resetState();
  }, [currentModule, resetState]);

  const state = useMemo(() => {
    return {
      tabIndex,
      analysisIdentity,
      dataSets,
      isLoading: isLoading || isLoadingInitialize || isFetching,
      fluidModuleType,
      setTabIndex,
      setApiError,
      setIsLoading,
      apiError,
      autoFmbState,
      setAutoFmbState,
      setProgress,
      setPollStatus,
      autoFmbForecastCalculation,
      setAutoFmbForecastCalculation,
      autoFmbAnalysisCalculation,
      setAutoFmbAnalysisCalculation,
      updateInputFromAnalysis,
      setUpdateInputFromAnalysis,
      flowingPressureOptions,
      expandParameter,
      currentModule,
    };
  }, [
    tabIndex,
    analysisIdentity,
    dataSets,
    isLoading,
    isLoadingInitialize,
    isFetching,
    fluidModuleType,
    setTabIndex,
    setApiError,
    setIsLoading,
    apiError,
    autoFmbState,
    setProgress,
    setPollStatus,
    autoFmbForecastCalculation,
    setAutoFmbForecastCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbAnalysisCalculation,
    updateInputFromAnalysis,
    setUpdateInputFromAnalysis,
    flowingPressureOptions,
    expandParameter,
    currentModule,
  ]);

  return <AutoFmbContext.Provider value={state}>{children}</AutoFmbContext.Provider>;
};

export function useAutoFmbState() {
  return useContext(AutoFmbContext);
}

export default AutoFmbProvider;
