import Tabs from "@/components/Tabs";
import { Column, Row } from "@silevis/reactgrid";

import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";
import { TahkCsgAnalysisCalculateResponse } from "@/models/tahk";

import useTahkCsgAnalysis from "../../hooks/analysis/useTahkCsgAnalysis";
import { useTahkCsgState } from "../../context/TahkCsgContext";

import { Container } from "./style";
import LoadingIndicator from "@/components/LoadingIndicator";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";
import { useMemo, useState } from "react";
import { getDataTableColumn } from "../../constants/grid";
import AnalysisParameter, { AnalysisParameterProps } from "./AnalysisParameter";

import DropdownField, { DropdownOption } from "@/components/fields/DropdownField";
import CustomCard from "@/components/Card";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";

type TahkCsgAnalysisProps = {
  dataTableRows: Row[];
  chartXAxes: FossilyticsChartAxis[];
  chartYAxes: FossilyticsChartAxis[];
  chartSeries: FossilyticsChartSeries[];
  calculation: TahkCsgAnalysisCalculateResponse | null;
  parameterTableColumn: Column[];
  parameterTableRows: Row[];
  dataTableLayerOption: DropdownOption[];
  selectedDataTableLayer: string;
  setSelectedDataTableLayer: (key: string) => void;
  cumulativeYAxes: FossilyticsChartAxis[];
  cumulativeChartSeries: FossilyticsChartSeries[];
  expandParameter: boolean;
} & AnalysisParameterProps;

const TahkCsgAnalysis = ({
  layerOption,
  loadingState,
  selectedLayer,
  setSelectedLayer,
  currentLayer,
  onChangeAnalysisInput,
  analysis,
  onChangeAnalysisInputLayer,
  onClickHistoryMatch,
  dataTableRows,
  chartXAxes,
  chartYAxes,
  chartSeries,
  calculation,
  parameterTableColumn,
  parameterTableRows,
  dataTableLayerOption,
  selectedDataTableLayer,
  setSelectedDataTableLayer,
  cumulativeYAxes,
  cumulativeChartSeries,
  errorInputValidation,
  canClickHistoryMatch,
  canCancelPoll,
  onCancelPoll,
  expandParameter,
}: TahkCsgAnalysisProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const { palette } = useThemeStyling();

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.combinedRates}</span>,
        key: dictionary.dataView.combinedRates,
        content: (
          <div>
            <div
              style={{
                height: 600,
                paddingRight: 30,
              }}
              className="chartWrapper "
            >
              <FossilyticsChart
                id="analysis_combined_rates_tahk_csg"
                key="analysis_combined_rates_tahk_csg"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={chartYAxes}
                series={chartSeries}
              />
            </div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <div className="final-param-table-title" style={{ color: palette.primary.main }}>
                {dictionary.tahk.tableOfFinalParameter}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflowX: "scroll",
                  height: 500,
                }}
              >
                <CustomTable
                  className="noScrollbarAll"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    marginTop: 20,
                  }}
                  stickyTopRows={1}
                  rows={parameterTableRows}
                  columns={parameterTableColumn}
                  isLoading={loadingState}
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.cumulativeChart}</span>,
        key: dictionary.dataView.cumulativeChart,
        content:
          loadingState || !calculation || activeTab !== 1 ? (
            <div className="center-flex ">
              <LoadingIndicator />
            </div>
          ) : (
            <div>
              <div
                style={{
                  height: 600,
                  paddingRight: 30,
                }}
                className="chartWrapper "
              >
                <FossilyticsChart
                  id="analysis_cumulative_chart_tahk_csg"
                  key="analysis_cumulative_chart_tahk_csg"
                  isLoading={loadingState}
                  xAxes={chartXAxes}
                  yAxes={cumulativeYAxes}
                  series={cumulativeChartSeries}
                />
              </div>
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: dictionary.dataTable,
        content:
          loadingState || !calculation ? (
            <div className="center-flex ">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 500,
                paddingRight: 30,
              }}
            >
              <DropdownField
                label={dictionary.tahk.layer}
                options={dataTableLayerOption}
                disabled={loadingState}
                selectedKey={selectedDataTableLayer}
                onChange={(v) => {
                  setSelectedDataTableLayer(String(v));
                }}
              />

              <CustomTable
                style={{
                  display: "flex",
                  maxHeight: 500,
                }}
                rows={dataTableRows}
                columns={getDataTableColumn(selectedDataTableLayer === "total" ? dictionary.tahkTotalDataTable : dictionary.tahkDataTable)}
                isLoading={loadingState}
              />
            </div>
          ),
      },
    ];
  }, [
    loadingState,
    calculation,
    chartXAxes,
    chartYAxes,
    chartSeries,
    palette.primary.main,
    parameterTableRows,
    parameterTableColumn,
    activeTab,
    cumulativeYAxes,
    cumulativeChartSeries,
    dataTableLayerOption,
    selectedDataTableLayer,
    dataTableRows,
    setSelectedDataTableLayer,
  ]);

  return (
    <Container
      style={{
        gridTemplateColumns: expandParameter ? "420px 1fr" : "1fr",
      }}
    >
      <div
        style={{
          display: expandParameter ? "unset" : "none",
        }}
      >
        <AnalysisParameter
          {...{
            layerOption,
            loadingState,
            selectedLayer,
            setSelectedLayer,
            currentLayer,
            onChangeAnalysisInput,
            analysis,
            onChangeAnalysisInputLayer,
            onClickHistoryMatch,
            errorInputValidation,
            canClickHistoryMatch,
            canCancelPoll,
            onCancelPoll,
          }}
        />
      </div>
      <CustomCard style={{ position: "relative", padding: 10 }}>
        <Tabs onClickItem={(index) => setActiveTab(index)} tabList={tabList} />
      </CustomCard>
    </Container>
  );
};

const TahkCsgAnalysisContainer = () => {
  const {
    analysisIdentity,
    tahkCsgState,
    setTahkCsgState,
    isLoading,
    tabIndex,
    setApiError,
    setCsvData,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    setLoadingBlocker,
    setLoadingBlockerText,
    setLoadingDependency,
    expandParameter,
  } = useTahkCsgState();
  const { checkIfDatasetExists } = useTreeViewState();
  const {
    layerOption,
    loadingState,
    selectedLayer,
    setSelectedLayer,
    currentLayer,
    onChangeAnalysisInput,
    analysis,
    onChangeAnalysisInputLayer,
    onClickHistoryMatch,
    dataTableRows,
    chartXAxes,
    chartYAxes,
    chartSeries,
    calculation,
    parameterTableColumn,
    parameterTableRows,
    dataTableLayerOption,
    selectedDataTableLayer,
    errorInputValidation,
    setSelectedDataTableLayer,
    cumulativeYAxes,
    cumulativeChartSeries,
    canClickHistoryMatch,
    canCancelPoll,
    onCancelPoll,
  } = useTahkCsgAnalysis({
    checkIfDatasetExists,
    analysisIdentity,
    tahkCsgState,
    setTahkCsgState,
    isLoading,
    tabIndex,
    setCsvData,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    setLoadingBlocker,
    setLoadingBlockerText,
    setLoadingDependency,
  });

  return (
    <TahkCsgAnalysis
      {...{
        expandParameter,
        layerOption,
        loadingState,
        selectedLayer,
        setSelectedLayer,
        currentLayer,
        onChangeAnalysisInput,
        analysis,
        onChangeAnalysisInputLayer,
        onClickHistoryMatch,
        dataTableRows,
        chartXAxes,
        chartYAxes,
        chartSeries,
        calculation,
        parameterTableColumn,
        parameterTableRows,
        dataTableLayerOption,
        selectedDataTableLayer,
        setSelectedDataTableLayer,
        cumulativeYAxes,
        cumulativeChartSeries,
        errorInputValidation,
        canClickHistoryMatch,
        canCancelPoll,
        onCancelPoll,
      }}
    />
  );
};

export default TahkCsgAnalysisContainer;
