import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

import DropdownField from "@/components/fields/DropdownField";
import InputField from "@/components/fields/InputField";

import dictionary from "@/constants/dictionary";
import { InputType } from "@/models/koldunV2";
import { mapEnumToDropdown } from "@/utils/general";
import useThemeStyling from "@/utils/useThemeStyling";

import { useKoldunCsgState } from "../../../hooks/KoldunCsgContext";
import useDependencyMatrix from "../../../hooks/input/useDependencyMatrix";
import InputList from "./InputList";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

const MonteCarloInput = () => {
  const {
    layerOption,
    selectedLayer,
    setSelectedLayer,
    state,
    loadingState,
    onChangeInputs,
    setKoldunCsgState,
    validationError,
    tabIndex,
    setValidationError,
  } = useKoldunCsgState();

  const { dependencyMatrixColumns, dependencyMatrixRow, onChangeDependencyMatrix } = useDependencyMatrix({
    loadingState,
    selectedLayer,
    setKoldunCsgState,
    matrixKeys: state?.inputs.dependency_matrices_keys ?? [],
    measures: state?.inputs?.measures,
    setValidationError,
  });
  const { elevatedCard } = useThemeStyling();
  const { palette } = useTheme();

  const dependencyMatrixError = useMemo(() => {
    return validationError.filter((err) => err.loc.includes("dependency_matrix"));
  }, [validationError]);

  return (
    <div
      style={{
        ...elevatedCard,
      }}
    >
      <h4
        style={{
          color: palette.primary.main,
          marginBottom: 10,
        }}
      >
        {dictionary.koldunCsg.monteCarloInputs}
      </h4>

      {state && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2.4fr 4fr",
          }}
        >
          <div>
            <InputField
              type="int"
              disabled={loadingState}
              value={state?.inputs.number_of_simulation}
              label={dictionary.koldunCsg.numberOfSimulation}
              debounceDelay={100}
              onChange={(v) => {
                onChangeInputs("number_of_simulation", Number(v));
              }}
              keyField="options.number_of_simulation"
              errors={validationError}
              isHorizontal
            />
            <DropdownField
              label={dictionary.koldunCsg.specifyInput}
              options={mapEnumToDropdown(InputType)}
              selectedKey={state?.inputs.specify_inputs}
              onChange={(val) => onChangeInputs("specify_inputs", val)}
              isHorizontal
              errors={validationError}
              keyField="options.specify_inputs"
            />
            <DropdownField
              label={dictionary.koldunCsg.measure}
              options={layerOption}
              selectedKey={String(selectedLayer)}
              onChange={(val) => setSelectedLayer(Number(val))}
              isHorizontal
              errors={validationError}
            />
          </div>
        </div>
      )}
      <InputList />

      {/* dependency matrix */}
      {tabIndex === 0 && (
        <div
          style={{
            paddingTop: 20,
          }}
          data-testid="dependency-matrix-container"
        >
          <div
            style={{
              color: palette.warning.main,
              marginBottom: 10,
            }}
          >
            {dependencyMatrixError.map((err) => err.msg)}
          </div>
          <ReactGridTable
            data-testid="monte-carlo-dependency-matrix"
            key={"monte-carlo-dependency-matrix-" + tabIndex}
            viewWidth={1100}
            viewHeight={500}
            columns={dependencyMatrixColumns}
            rows={dependencyMatrixRow}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={1}
            onCellsChanged={onChangeDependencyMatrix}
          />
        </div>
      )}
    </div>
  );
};

export default MonteCarloInput;
