import { ParsedFile, HeaderMapping, ResultState, WellErrorCode } from "@/models/wells";

export type State = {
  files: File[];
  res: ResultState<WellErrorCode>;
  isLoading: boolean;
  isOverrideActive: boolean;
  activeTab: number;
  progressPercentage: number;
  parsedFiles: ParsedFile[];
  currentFileIndex: number;
  manualWellType: string | null;
  headerMapping: Record<string, HeaderMapping>;
  dateFormats: string[];
  mappingDialogOpen: boolean;
  errorMessage: string;
};

export type Action =
  | { type: "SET_FILES"; payload: File[] }
  | { type: "SET_RES"; payload: ResultState<WellErrorCode> }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_IS_OVERRIDE_ACTIVE"; payload: boolean }
  | { type: "SET_ACTIVE_TAB"; payload: number }
  | { type: "SET_PROGRESS_PERCENTAGE"; payload: number }
  | { type: "SET_PARSED_FILES"; payload: ParsedFile[] }
  | { type: "SET_CURRENT_FILE_INDEX"; payload: number }
  | { type: "SET_MANUAL_WELL_TYPE"; payload: string | null }
  | { type: "SET_HEADER_MAPPING"; payload: Record<string, HeaderMapping> }
  | { type: "SET_DATE_FORMATS"; payload: string[] }
  | { type: "SET_MAPPING_DIALOG_OPEN"; payload: boolean }
  | { type: "SET_ERROR_MESSAGE"; payload: string }
  | { type: "RESET_STATE" };

export const initialState: State = {
  files: [],
  res: {},
  isLoading: false,
  isOverrideActive: false,
  activeTab: 0,
  progressPercentage: 0,
  parsedFiles: [],
  currentFileIndex: 0,
  manualWellType: null,
  headerMapping: {},
  dateFormats: [],
  mappingDialogOpen: false,
  errorMessage: "",
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FILES":
      return { ...state, files: action.payload };
    case "SET_RES":
      return { ...state, res: action.payload };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_IS_OVERRIDE_ACTIVE":
      return { ...state, isOverrideActive: action.payload };
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload };
    case "SET_PROGRESS_PERCENTAGE":
      return { ...state, progressPercentage: action.payload };
    case "SET_PARSED_FILES":
      return { ...state, parsedFiles: action.payload };
    case "SET_CURRENT_FILE_INDEX":
      return { ...state, currentFileIndex: action.payload };
    case "SET_MANUAL_WELL_TYPE":
      return { ...state, manualWellType: action.payload };
    case "SET_HEADER_MAPPING":
      return { ...state, headerMapping: action.payload };
    case "SET_DATE_FORMATS":
      return { ...state, dateFormats: action.payload };
    case "SET_MAPPING_DIALOG_OPEN":
      return { ...state, mappingDialogOpen: action.payload };
    case "SET_ERROR_MESSAGE":
      return { ...state, errorMessage: action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
