import { CellChange, Row } from "@silevis/reactgrid";

import CustomCard from "@/components/Card";
import CustomTable from "@/components/CustomTable";
import InputField from "@/components/fields/InputField";
import dictionary from "@/constants/dictionary";

import { EconomicsOption } from "@/models/economics/state";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import useThemeStyling from "@/utils/useThemeStyling";

import { grossCapexColumn } from "../hooks/useEconomics";
import { Button } from "@mui/material";
import { useState } from "react";
import Collapsible from "@/components/Collapsible";

type EconomicParameterProps = {
  parameter?: EconomicsOption;
  onChangeParameter: (val: any, key: string) => void;
  isLoading: boolean;
  validationError: ErrorValidationDetail[];
  grossCapexRow: Row[];
  onChangeGrossCapexTable: (changes: CellChange[]) => void;
  onClickCalculateEconomic: () => void;
  canClickCalculate: boolean;
};

const EconomicParameter = ({
  parameter,
  onChangeParameter,
  isLoading,
  validationError,
  grossCapexRow,
  onChangeGrossCapexTable,
  onClickCalculateEconomic,
  canClickCalculate,
}: EconomicParameterProps) => {
  const { palette } = useThemeStyling();

  const [haveClickCalc, setHaveClickCalc] = useState(false);

  return (
    <CustomCard
      style={{
        height: "100%",
      }}
    >
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.economics.economicSetting}</h3>
      <div
        style={{
          paddingRight: 12,
          height: "69vh",
          overflow: "auto",
        }}
      >
        <Collapsible defaultOpen headerText={dictionary.economics.constants}>
          <InputField
            horizontalPercentage={55}
            isHorizontal
            label={dictionary.economics.gasShrinkage}
            suffix="%"
            min={0}
            max={100}
            debounceDelay={1000}
            value={parameter?.gas_shrinkage}
            onChange={(v) => onChangeParameter(v, "gas_shrinkage")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.gas_shrinkage"
            dataTestId="economic_option.gas_shrinkage"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.higherHeatingVal}
            suffix="BTU/scf"
            debounceDelay={1000}
            value={parameter?.gas_higher_heating_value}
            onChange={(v) => onChangeParameter(v, "gas_higher_heating_value")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.gas_higher_heating_value"
            dataTestId="economic_option.gas_higher_heating_value"
          />

          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.workingInterest}
            suffix="%"
            min={0}
            max={100}
            debounceDelay={1000}
            value={parameter?.working_interest}
            onChange={(v) => onChangeParameter(v, "working_interest")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.working_interest"
            dataTestId="economic_option.working_interest"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.royaltyBurden}
            suffix="%"
            min={0}
            max={100}
            debounceDelay={1000}
            value={parameter?.royalty_burden}
            onChange={(v) => onChangeParameter(v, "royalty_burden")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.royalty_burden"
            dataTestId="economic_option.gas_shrinkage"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.fixedOpex}
            suffix="$/Month"
            debounceDelay={1000}
            value={parameter?.fixed_opex}
            onChange={(v) => onChangeParameter(v, "fixed_opex")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.fixed_opex"
            dataTestId="economic_option.fixed_opex"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.variableGasOpex}
            suffix="$/Mscf"
            debounceDelay={1000}
            value={parameter?.variable_gas_opex}
            onChange={(v) => onChangeParameter(v, "variable_gas_opex")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.variable_gas_opex"
            dataTestId="economic_option.variable_gas_opex"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.variableOilOpex}
            suffix="$/STB"
            debounceDelay={1000}
            value={parameter?.variable_oil_opex}
            onChange={(v) => onChangeParameter(v, "variable_oil_opex")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.variable_oil_opex"
            dataTestId="economic_option.variable_oil_opex"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.variableWaterOpex}
            suffix="$/STB"
            debounceDelay={1000}
            value={parameter?.variable_water_opex}
            onChange={(v) => onChangeParameter(v, "variable_water_opex")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.variable_water_opex"
            dataTestId="economic_option.variable_water_opex"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.abandonmentCapex}
            suffix="$"
            debounceDelay={1000}
            value={parameter?.gross_abandonment_capex}
            onChange={(v) => onChangeParameter(v, "gross_abandonment_capex")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.gross_abandonment_capex"
            dataTestId="economic_option.gross_abandonment_capex"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.severanceAd}
            suffix="%"
            debounceDelay={1000}
            value={parameter?.severance_ad_valorem_tax_rate}
            onChange={(v) => onChangeParameter(v, "severance_ad_valorem_tax_rate")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.severance_ad_valorem_tax_rate"
            dataTestId="economic_option.severance_ad_valorem_tax_rate"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.gasPrice}
            suffix="$/MMBTU"
            debounceDelay={1000}
            value={parameter?.gas_price}
            onChange={(v) => onChangeParameter(v, "gas_price")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.gas_price"
            dataTestId="economic_option.gas_price"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.oilPrice}
            suffix="$/STB"
            debounceDelay={1000}
            value={parameter?.oil_price}
            onChange={(v) => onChangeParameter(v, "oil_price")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.oil_price"
            dataTestId="economic_option.oil_price"
          />
          <InputField
            isHorizontal
            horizontalPercentage={55}
            label={dictionary.economics.discountRate}
            suffix="%/annum"
            debounceDelay={1000}
            value={parameter?.discount_rate}
            onChange={(v) => onChangeParameter(v, "discount_rate")}
            disabled={isLoading}
            errors={validationError}
            keyField="economic_option.discount_rate"
            dataTestId="economic_option.discount_rate"
          />
        </Collapsible>

        <Collapsible headerText={dictionary.economics.capexTable}>
          <CustomTable
            columns={grossCapexColumn}
            rows={grossCapexRow}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={1}
            onCellsChanged={onChangeGrossCapexTable}
            isLoading={false}
            style={{ height: 200 }}
          />
        </Collapsible>
      </div>

      <Button
        style={{
          color: "white",
          width: "100%",
          fontWeight: "bold",
          marginTop: 15,
        }}
        disabled={isLoading || (!canClickCalculate && haveClickCalc)}
        variant="contained"
        onClick={() => {
          setHaveClickCalc(true);
          onClickCalculateEconomic();
        }}
      >
        {dictionary.economics.calculateEconomic}
      </Button>
    </CustomCard>
  );
};

export default EconomicParameter;
