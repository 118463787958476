export const spadDeclineColumnKey = [
  "data_set_id",
  "type",
  "autoUpdateClusterStartDay",
  "analysisStartDay",
  "autoUpdateSmartFit",
  "economicCutoff",
  "selectedForecastRollup",

  "operationalForecast.low.initialRate",
  "operationalForecast.low.arpsExponent",
  "operationalForecast.low.initialDeclineRate",
  "operationalForecast.low.initialDay",

  "operationalForecast.mid.initialRate",
  "operationalForecast.mid.arpsExponent",
  "operationalForecast.mid.initialDeclineRate",
  "operationalForecast.mid.initialDay",

  "operationalForecast.high.initialRate",
  "operationalForecast.high.arpsExponent",
  "operationalForecast.high.initialDeclineRate",
  "operationalForecast.high.initialDay",

  "profileForecast.low.initialRate",
  "profileForecast.low.arpsExponent",
  "profileForecast.low.initialDeclineRate",
  "profileForecast.low.initialDay",

  "profileForecast.mid.initialRate",
  "profileForecast.mid.arpsExponent",
  "profileForecast.mid.initialDeclineRate",
  "profileForecast.mid.initialDay",

  "profileForecast.high.initialRate",
  "profileForecast.high.arpsExponent",
  "profileForecast.high.initialDeclineRate",
  "profileForecast.high.initialDay",
];
