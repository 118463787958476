import { useMemo } from "react";
import { Row } from "@silevis/reactgrid";

import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";

import { EconomicsResult } from "@/models/economics/state";
import useThemeStyling from "@/utils/useThemeStyling";
import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { formatMoney, snakeToCamelFirstCaseLower } from "@/utils/general";
import { moneyFormatter } from "@/util";

export type EconomicChartProps = {
  economicResult?: EconomicsResult | null;
  loadingState: boolean;
};

const dataTableColumnHeader = [
  "dates",
  "gross_income",
  "net_severance_taxes",
  "net_income",
  "net_opex",
  "net_operating_income",
  "net_capex",
  "cash_flow_bfit",
  "discounted_cash_flow_bfit",
  "cumulative_discounted_cash_bfit",
];

const safeDictionary: { [key: string]: string } = dictionary.economics;

const useEconomicChart = ({ economicResult }: EconomicChartProps) => {
  const { palette } = useThemeStyling();

  const chartAxes = useMemo(() => {
    const maxCash = economicResult?.cumulative_discounted_cash_bfit ? Math.round(Math.max(...economicResult.cumulative_discounted_cash_bfit)) : 10000;

    return {
      xAxes: [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }],
      yAxes: [
        {
          name: dictionary.economics.cumulativeCashBfit,
          type: "value",
          color: palette.primary.main,
          formatter: moneyFormatter,
          nameGap: 45,
          max: maxCash + maxCash * 0.15,
        },
      ],
    };
  }, [economicResult?.cumulative_discounted_cash_bfit, palette.customColor.black, palette.primary.main]);

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!economicResult) return [];
    return [
      {
        name: dictionary.economics.cumulativeCashBfit,
        type: "scatter",
        color: palette.primary.main,
        yAxisIndex: 0,
        data: economicResult.dates.map((x: string, i: number) => [x, economicResult.cumulative_discounted_cash_bfit?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 2,
      },
    ];
  }, [economicResult, palette.primary.main]);

  const economicDataTableCol = dataTableColumnHeader.map((header) => {
    return {
      columnId: header,
    };
  });

  const economicDataTableRow = useMemo(() => {
    if (!economicResult) return [];

    const constantInputsRows = [
      {
        rowId: "header",
        height: 50,
        cells: dataTableColumnHeader.map((head: string) => {
          return {
            type: "header",
            text: safeDictionary[snakeToCamelFirstCaseLower(head)],
            style: tableHeaderStyle,
          };
        }),
      },
      ...economicResult.dates.map((_: any, index: number) => {
        return {
          key: index,
          rowId: index + 1,
          cells: dataTableColumnHeader.map((head) => {
            const result: { [key: string]: any } = {
              type: head === "dates" ? "date" : "text",
              nonEditable: true,
              style: tableCellStyle,
            };
            let text = economicResult?.[head as keyof EconomicsResult]?.[index];
            if (head === "dates") {
              result.date = new Date(String(text));
              result.format = Intl.DateTimeFormat();
            } else {
              result.text = formatMoney(Number(text));
            }
            return result;
          }),
        };
      }),
    ] as Row[];
    return constantInputsRows;
  }, [economicResult]);

  return { forecastCalcSeries, chartAxes, economicDataTableCol, economicDataTableRow };
};

export default useEconomicChart;
