import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { CellChange, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { isDataSet } from "@/model";
import { groupRunSchema, postInitializeGroupRun, RulonGroupRunResponse } from "@/models/rulon";
import dictionary from "@/constants/dictionary";

import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { usePolling } from "@/utils/apiFetcher";
import { calculateRullon } from "@/constants/apiUrl";
import { parseErrorThrown } from "@/utils/errorHandling";
import { CustomRow, GenericPollProps, GroupRunGenericProps, SortState } from "./type";
import { getWellType, sortRulonRows } from "../utils";

const useGroupRun = ({
  selectedDataSets,
  isLoading,
  project,
  setApiError,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
}: GroupRunGenericProps & GenericPollProps) => {
  const [rulonState, setRulonState] = useState<RulonGroupRunResponse[] | null>();

  const [selectAllState, setSelectAllState] = useState<{ [key: string]: boolean }>({});

  // if true mean sort alphanumerically
  // select either one will reset the other one ( only 1 active sort )
  const [sortState, setSortState] = useState<SortState>({ name: false, type: false });
  const haveInitialize = useRef(false);

  const { createPoll, canCancelPoll, onCancelPoll, forceStopPoll } = usePolling({
    setApiError,
    apiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
  });

  const dataSetsFullInfo = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const dataSets = useMemo(() => {
    return dataSetsFullInfo?.map((dataset) => dataset.id);
  }, [dataSetsFullInfo]);

  const initializeRulonGroupRun = useCallback(async () => {
    try {
      if (!project || !dataSets || dataSets.length === 0) return;
      const res = await postInitializeGroupRun(project?.id ?? "", dataSets);

      if (res.data && !rulonState) {
        const parsed = groupRunSchema.array().parse(res.data);
        const remmaped = parsed.map((item) => ({
          ...item,
          name: dataSetsFullInfo?.find((dataset) => dataset.id === item.data_set_id)?.name ?? "",
        }));

        setRulonState(remmaped);
      }
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [apiError, dataSets, dataSetsFullInfo, project, rulonState, setApiError]);

  useEffect(() => {
    if (haveInitialize.current) return;
    haveInitialize.current = true;
    initializeRulonGroupRun();
  }, [initializeRulonGroupRun]);

  const loading = isLoading;

  const rulonColumns = useMemo(() => {
    if (!rulonState || rulonState?.length === 0) return [];

    return ["data_set_id", "type", ...Object.keys(rulonState[0]).filter((item) => item !== "data_set_id" && item !== "name")].map(
      (columnId, index) => {
        return { columnId: columnId, width: index === 0 ? 230 : 180 };
      }
    );
  }, [rulonState]);

  const rulonRows: Row<any>[] = useMemo(() => {
    const header = {
      rowId: "header",
      cells: rulonColumns.map((header, index) => {
        if (index === 0) {
          return {
            type: "custom",
            text: dictionary.groupRunModuleName.data_set_id,
            style: tableHeaderStyle,
            nonEditable: true,
            onClick: () => {
              setSortState((prev) => {
                return {
                  type: false,
                  name: !prev.name,
                };
              });
            },
          };
        } else if (index === 1) {
          return {
            type: "custom",
            text: dictionary.groupRunModuleName.type,
            style: tableHeaderStyle,
            nonEditable: true,
            onClick: () => {
              setSortState((prev) => {
                return {
                  name: false,
                  type: !prev.type,
                };
              });
            },
          };
        }
        return {
          type: "text",
          text: dictionary.groupRunModuleName[String(header.columnId)],
          style: tableHeaderStyle,
          nonEditable: true,
        };
      }),
      height: 55,
    };

    if (!rulonState) return [header];

    const selectAllRow = {
      rowId: "bulkSelect",
      cells: rulonColumns.map((header, index) => {
        if (index === 1) {
          return {
            type: "text",
            text: "-",
            style: tableHeaderNotationStyle,
            nonEditable: true,
          };
        }
        if (index === 0) {
          return {
            type: "text",
            text: dictionary.rulon.selectDeselect,
            style: tableHeaderNotationStyle,
            nonEditable: true,
          };
        }

        return {
          type: "checkbox",
          checked: !!selectAllState[header.columnId],
          style: tableHeaderNotationStyle,
        };
      }),
      height: 35,
    };

    const modules = _.cloneDeep(rulonState);
    let moduleList: any = modules.map((row: any, rowIndex) => {
      const dataSetTag = dataSetsFullInfo.filter((dataSet) => row?.data_set_id === dataSet.id)[0]?.tags;
      const wellType = getWellType(dataSetTag).join(", ");

      return {
        rowId: rowIndex,
        height: 30,
        name: row.name,
        wellType,
        cells: [
          ...rulonColumns.map((header, index) => {
            if (index === 0) {
              return {
                type: "text",
                text: row.name ?? "-",
                style: {
                  ...tableCellStyle,
                  border: {
                    right: {
                      width: "1px",
                      color: "black",
                    },
                  },
                },
                nonEditable: true,
              };
            }

            if (index === 1) {
              return {
                type: "text",
                text: wellType,
                style: tableCellStyle,
                nonEditable: true,
              };
            }
            return {
              type: "checkbox",
              checked: Boolean(row[header.columnId]),
              style: tableCellStyle,
              nonEditable: loading,
            };
          }),
        ],
      } as CustomRow;
    });
    moduleList = sortRulonRows(moduleList, sortState) as Row<any>[];

    return [header, selectAllRow, ...moduleList];
  }, [dataSetsFullInfo, loading, rulonColumns, rulonState, selectAllState, sortState]);

  const onChangeCell = useCallback(
    (changes: CellChange[]) => {
      if (!rulonState) return;
      let updatedRows: any = [...rulonState];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, previousCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        if (String(rowId) === "bulkSelect") {
          setSelectAllState((prev) => ({
            ...prev,
            [columnId]: !previousCell.checked,
          }));

          updatedRows = updatedRows.map((row: { [x: string]: boolean }) => {
            row[columnId] = !previousCell.checked;
            return row;
          });
        } else {
          setSelectAllState((prev) => ({
            ...prev,
            [columnId]: false,
          }));
          updatedRows[rowId][columnId] = !previousCell.checked;
        }
      }
      setRulonState(updatedRows);
    },
    [rulonState]
  );

  const onCalculateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState) return;

      const res = await createPoll<
        any,
        {
          selection: RulonGroupRunResponse[];
        }
      >({
        path: calculateRullon(project?.id),
        body: {
          selection: rulonState,
        },
        type: "post",
      });

      if (res.task_result) {
        setApiError({
          severity: "success",
          message: dictionary.rulon.successGroupRun,
        });
        setTimeout(() => {
          setApiError();
        }, 3500);
      }
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [project, rulonState, createPoll, setApiError, apiError]);

  useEffect(() => {
    return () => {
      forceStopPoll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    rulonRows,
    rulonColumns,
    onChangeCell,
    onCalculateRulon,
    rulonState,
    canCancelPoll,
    onCancelPoll,
  };
};

export default useGroupRun;
