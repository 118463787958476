import { useCallback } from "react";

import CustomCard from "@/components/Card";
import SummaryTable from "@/components/SummaryTable";

import dictionary from "@/constants/dictionary";
import { SummaryCardTable } from "@/models/InputGeneric";
import { formatNumber } from "@/util";
import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";

const dataTableColumns = [
  { key: "parameter", label: "Parameter" },
  { key: "value", label: "Value" },
  { key: "units", label: "Units" },
];

type InputSummaryCardProps = {
  summaryCard?: SummaryCardTable[];
  withCard?: boolean;
  isLoading?: boolean;
  customFormat?: (val: string | number) => string | number;
};

const InputSummaryCard = ({ summaryCard = [], withCard = false, isLoading = false, customFormat }: InputSummaryCardProps) => {
  const { palette } = useThemeStyling();

  const renderContent = useCallback(() => {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.inputPage.summary}</h3>
        <SummaryTable
          rows={summaryCard.map((item: any) => {
            return {
              parameter: item.parameter,
              value: customFormat ? customFormat(item.value) : formatNumber(item.value),
              units: item.unit,
            };
          })}
          style={{
            opacity: isLoading && summaryCard.length !== 0 ? 0.5 : 1,
          }}
          headers={dataTableColumns}
        />

        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: summaryCard.length !== 0 ? "50%" : "100%",
              width: "100%",
            }}
            className="center-flex"
          >
            <LoadingIndicator />
          </div>
        )}
      </div>
    );
  }, [palette.primary.main, summaryCard, isLoading, customFormat]);

  if (withCard) {
    return <CustomCard>{renderContent()}</CustomCard>;
  }
  return <div>{renderContent()}</div>;
};

export default InputSummaryCard;
