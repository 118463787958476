import { useMemo } from "react";
import { Column, Row } from "@silevis/reactgrid";

import Tabs from "@/components/Tabs";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import CustomCard from "@/components/Card";
import DropdownField from "@/components/fields/DropdownField";
import CustomTable from "@/components/CustomTable";

import dictionary from "@/constants/dictionary";
import { AutoFmbState, ForecastCalculationState, ForecastFmbFieldDataGas, ForecastFmbFieldDataOil } from "@/models/autoFmb";
import { capitalizeFirstLetter } from "@/utils/general";

import { caseKey } from "../../constants";
import useAutoFmbForecastResult from "../../hooks/useAutoFmbForecastResult";
import { FluidType } from "@/models/Generic";
import useAutoFmbForecastResultOil from "../../hooks/useAutoFmbForecastResultOil";

type XYAxes = {
  xAxes: FossilyticsChartAxis[];
  yAxes: FossilyticsChartAxis[];
};

type ForecastChartFmbProps = {
  setSelectedCase: React.Dispatch<React.SetStateAction<string>>;
  chartAxes: XYAxes;
  forecastCalcSeries: FossilyticsChartSeries[];
  cumulativeChartSeries: FossilyticsChartSeries[];
  cumulativeChartAxes: XYAxes;
  forecastCalculationDataTableCol: Column[];
  forecastCalculationDataTableRow: Row[];
  fieldDataCol: Column[];
  fieldDataRow: Row[];
  selectedCase: string;
  isLoading: boolean;
  autoFmbForecastCalculation?: ForecastCalculationState;
};

const ForecastChartFmb = ({
  setSelectedCase,
  chartAxes,
  forecastCalcSeries,
  cumulativeChartAxes,
  cumulativeChartSeries,
  forecastCalculationDataTableCol,
  forecastCalculationDataTableRow,
  fieldDataCol,
  fieldDataRow,
  selectedCase,
  isLoading,
  autoFmbForecastCalculation,
}: ForecastChartFmbProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.genericChart.chart}</span>,
        key: dictionary.genericChart.chart,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"fmb_chart"}
              key={"fmb_chart"}
              isLoading={isLoading}
              xAxes={chartAxes.xAxes}
              yAxes={chartAxes.yAxes}
              series={forecastCalcSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.cumulativeGasChart}</span>,
        key: dictionary.genericChart.cumulativeGasChart,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"pz_chart"}
              key={"pz_chart"}
              isLoading={isLoading}
              xAxes={cumulativeChartAxes.xAxes}
              yAxes={cumulativeChartAxes.yAxes}
              series={cumulativeChartSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.modelDataTable}</span>,
        key: "Data table",
        content: (
          <div>
            <DropdownField
              label={dictionary.fmb.caseOption}
              options={caseKey.map((key) => ({
                key,
                text: capitalizeFirstLetter(key),
              }))}
              selectedKey={selectedCase}
              onChange={(v) => setSelectedCase(v)}
              disabled={isLoading}
            />
            <div style={{ height: "90%", position: "absolute", overflow: "auto", width: "98%" }}>
              <div style={{ height: "96%", position: "relative" }}>
                <CustomTable
                  rows={forecastCalculationDataTableRow}
                  style={{ height: "90%", width: "98%", maxHeight: "unset" }}
                  columns={forecastCalculationDataTableCol}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.measuredDataTable}</span>,
        key: "Measured Data table",
        content: (
          <div style={{ height: "100%", position: "absolute", overflow: "auto", width: "98%" }}>
            <div style={{ height: "96%", position: "relative" }}>
              <CustomTable
                style={{ height: "90%", width: "98%", maxHeight: "unset" }}
                rows={fieldDataRow}
                columns={fieldDataCol}
                isLoading={isLoading}
              />
            </div>
          </div>
        ),
      },
    ];
  }, [
    chartAxes.xAxes,
    chartAxes.yAxes,
    cumulativeChartAxes.xAxes,
    cumulativeChartAxes.yAxes,
    cumulativeChartSeries,
    fieldDataCol,
    fieldDataRow,
    forecastCalcSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    isLoading,
    selectedCase,
    setSelectedCase,
  ]);

  return (
    <CustomCard
      style={{
        opacity: isLoading || !autoFmbForecastCalculation ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {!autoFmbForecastCalculation && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

type ForecastChartContainerFmbProps = {
  isLoading: boolean;
  autoFmbForecastCalculation?: ForecastCalculationState;
  autoFmbState?: AutoFmbState;
  fluidModuleType: FluidType;
};

const ForecastChartFmbOil = ({ isLoading, autoFmbForecastCalculation, autoFmbState }: ForecastChartContainerFmbProps) => {
  const props = useAutoFmbForecastResultOil({
    autoFmbState,
    fieldData: autoFmbForecastCalculation?.field_data as ForecastFmbFieldDataOil,
    forecastResult: autoFmbForecastCalculation?.forecast_result,
  });
  return <ForecastChartFmb {...props} isLoading={isLoading} autoFmbForecastCalculation={autoFmbForecastCalculation} />;
};

const ForecastChartFmbGas = ({ isLoading, autoFmbForecastCalculation, autoFmbState }: ForecastChartContainerFmbProps) => {
  const props = useAutoFmbForecastResult({
    autoFmbState,
    fieldData: autoFmbForecastCalculation?.field_data as ForecastFmbFieldDataGas,
    forecastResult: autoFmbForecastCalculation?.forecast_result,
  });
  return <ForecastChartFmb {...props} isLoading={isLoading} autoFmbForecastCalculation={autoFmbForecastCalculation} />;
};

const ForecastChartFmbContainer = (props: ForecastChartContainerFmbProps) => {
  if (props.fluidModuleType === FluidType.gas) return <ForecastChartFmbGas {...props} />;
  return <ForecastChartFmbOil {...props} />;
};

export default ForecastChartFmbContainer;
