import React from "react";
import Popover from "@mui/material/Popover";

import styled from "@emotion/styled";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { colorPalette } from "@/features/themes/mui-theme/mui-theme";

export type FieldLabelProps = {
  calloutContent?: string | React.ReactNode;
  label?: string;
  helpUrl?: string;
  ariaLabel?: string;
  width?: string;
};

const StyledLabel = styled.label<{ width?: string }>`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px;
  ${(props) => props.width && `width: ${props.width}`}
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    span {
      color: ${colorPalette.primary.main};
    }
    label {
      color: ${colorPalette.primary.main};
    }
  }
`;
const FieldLabel = ({ calloutContent, label, helpUrl, ariaLabel, width }: FieldLabelProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (calloutContent) {
    return (
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          {calloutContent}
        </Popover>
        <div
          onKeyDown={() => {
            /* no-op*/
          }}
          onClick={(e) => handleClick(e)}
        >
          <StyledLabel width={width} htmlFor={label}>
            {label}
          </StyledLabel>
        </div>
      </>
    );
  }

  if (helpUrl) {
    return (
      <StyledLabel width={width} htmlFor={label}>
        <StyledLink target="_blank" rel="noreferrer" href={helpUrl}>
          {label}
          <InfoOutlinedIcon sx={{ fontSize: 12, marginLeft: 1, marginBottom: 1 }} />
        </StyledLink>
      </StyledLabel>
    );
  }
  return (
    <StyledLabel width={width} htmlFor={label} aria-label={ariaLabel}>
      {label}
    </StyledLabel>
  );
};

export default FieldLabel;
