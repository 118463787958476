import { useEffect, useCallback, useMemo, useState, useRef } from "react";
import _ from "lodash";

// utils
import { findGroupRecursive, getIdFromActiveKey, loopGroup, mergeStringArrays } from "../utils";
import { DataSet, Group, ModuleId, Project } from "@/model";
import { postMoveGroup, postWellToGroup } from "@/models/settings";
import { SelectItemProp } from "../types";
import dictionary from "@/constants/dictionary";
import { postMoveWells } from "@/models/wells";
import { TreeNode } from "../Components/DndProjectTree/helper";
import { moduleMappingMulti } from "@/components/Modules/constants";

export type UseProjectNavProps = {
  projects?: Project[];
  refreshProjects: () => Promise<Project[] | undefined> | void;
  setProject: (project?: Project) => void;
  setGroup: (group?: Group) => void;
  setSelectedDataSets: (dataSets?: DataSet | DataSet[]) => void;
  setSelectedKey: (key: string) => void;
  dataSets?: DataSet[];
  selectedKey?: string;
  navigate: (path: string) => void;
  setErrorWarning: (error?: string) => void;
  currentModule?: ModuleId;
};

const useProjectNav = ({
  selectedKey,
  projects,
  refreshProjects,
  setGroup,
  setProject,
  setSelectedDataSets,
  setSelectedKey,
  dataSets,
  navigate,
  setErrorWarning,
  currentModule,
}: UseProjectNavProps) => {
  const [showAll, setShowAll] = useState(false);
  const [hideProjects, setHideProjects] = useState(false);
  // this is for selected data set in well list
  // use it for drag and drop functionality
  const [selectedDataSet, setSelectedDataSet] = useState<string[]>([]);

  // use when user click ... dots menu on the nav
  // will set active key with format below:
  // projectId;gorupId,groupId,groupId;dataSet,dataSet,dataSet
  const [activeKey, setActiveKey] = useState("");
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [shiftHeld, setShiftHeld] = useState(false);
  const [commandControlHeld, setCommandControlHeld] = useState(false);
  const wellDestination = useRef<any>();

  const downHandler = useCallback(({ key }: KeyboardEvent) => {
    if (key === "Shift") {
      setShiftHeld(true);
    } else if (key === "Meta" || key === "Control") {
      setCommandControlHeld(true);
    }
  }, []);

  const upHandler = useCallback(({ key }: KeyboardEvent) => {
    if (key === "Shift") {
      setShiftHeld(false);
    } else if (key === "Meta" || key === "Control") {
      setCommandControlHeld(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  // init maps for projects and dataSets for faster lookups
  const projectsMap = useMemo(() => {
    const map = new Map<string, Project>();
    projects?.forEach((project) => map.set(project.id, project));
    return map;
  }, [projects]);

  // to get latest project map
  const projectMapRef = useRef<Map<string, Project>>();

  useEffect(() => {
    projectMapRef.current = projectsMap;
  }, [projectsMap]);

  const dataSetsMap = useMemo(() => {
    const map = new Map<string, DataSet>();
    dataSets?.forEach((dataSet) => map.set(dataSet.id, dataSet));
    return map;
  }, [dataSets]);

  const checkIfDatasetExists = useCallback(
    ({ datasetIds, groupId, projectId }: { datasetIds: string[]; groupId: string; projectId: string }): boolean => {
      if (!projectMapRef.current) return false;
      const currentGroup = projectMapRef.current.get(projectId)?.groups.find((group) => group.id === groupId);
      return datasetIds?.every((id) => currentGroup?.data_set_ids?.includes(id)) ?? false;
    },
    []
  );

  // map to store total items and ids per project/group
  const mappedItemKeyTotal = useMemo(() => {
    if (!projects || projects.length === 0) return {};
    let res: {
      [key: string]: {
        total: number;
        ids?: string[];
        name: string;
      };
    } = {};

    projects.forEach((project) => {
      res[project.id] = {
        total: 0,
        name: project.name,
      };
      project.groups.forEach((group) => {
        let groupMapped = loopGroup(group);
        const reduced = Object.values(groupMapped).reduce((total, curr) => {
          if (!curr.isChild) {
            total += curr.total;
          }
          return total;
        }, 0);

        for (let item in groupMapped) {
          res[item] = {
            total: groupMapped[item].total,
            ids: groupMapped[item].ids,
            name: groupMapped[item].name,
          };
        }
        res[project.id].total += reduced;
      });
    });
    return res;
  }, [projects]);

  // Update activeKey and selection states
  const onUpdateActiveKey = useCallback(
    (activeKey: string) => {
      setActiveKey(activeKey);

      if (!projectsMap || activeKey === selectedKey) return;
      setSelectedKey(activeKey);

      if (!activeKey) {
        setSelectedDataSets(undefined);
        setProject(undefined);
        setGroup(undefined);
        return;
      }

      const { projectId, groupIds, isCollapsible, dataSet } = getIdFromActiveKey(activeKey);
      const currentProject = projectsMap.get(projectId);
      if (!currentProject) return;

      const selectedGroup = groupIds.length > 0 ? findGroupRecursive(groupIds, currentProject.groups) : undefined;

      if (dataSet.length > 0) {
        const filteredDataSets = dataSet.map((id) => dataSetsMap.get(id)).filter(Boolean) as DataSet[];

        if (filteredDataSets.length > 0) setSelectedDataSets(filteredDataSets);
        setProject(currentProject);
        setGroup(selectedGroup);
      } else if (groupIds.length > 0 && selectedGroup && !isCollapsible) {
        let dataSetList = selectedGroup.data_set_ids ?? [];

        if (dataSetList.length === 0) {
          dataSetList = selectedGroup.groups.reduce<string[]>((acc, group) => {
            if (group.data_set_ids) acc.push(...group.data_set_ids);
            return acc;
          }, []);
        }

        const filteredDataSets = dataSetList.map((id) => dataSetsMap.get(id)).filter(Boolean) as DataSet[];

        setSelectedDataSets(filteredDataSets);
        setProject(currentProject);
        setGroup(selectedGroup);
      } else if (!isCollapsible) {
        const flattenDataset = currentProject.groups.reduce<Set<string>>((res, group) => {
          group.data_set_ids?.forEach((id) => res.add(id));
          return res;
        }, new Set<string>());

        const filteredDataSets = Array.from(flattenDataset)
          .map((id) => dataSetsMap.get(id))
          .filter(Boolean) as DataSet[];

        setSelectedDataSets(filteredDataSets);
        setProject(currentProject);
        setGroup(undefined);
      } else {
        setSelectedDataSets(undefined);
        setProject(undefined);
        setGroup(undefined);
      }
    },
    [projectsMap, dataSetsMap, selectedKey, setGroup, setProject, setSelectedDataSets, setSelectedKey]
  );

  useEffect(() => {
    if (activeKey === selectedKey) return;
    if (!selectedKey) {
      onUpdateActiveKey("");
      setSelectedKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  const onMoveGroupProject = useCallback(
    async (info: any) => {
      try {
        const destination = info.node;
        const currentItem = info.dragNode;

        // cannot drag group to inside a group with dataset
        if (destination.type === "dataset") {
          setErrorWarning(dictionary.errorMessage.groupHaveDataset);
          setTimeout(() => {
            setErrorWarning();
          }, 5000);
          return;
        }

        const prevLocation = {
          groupId: currentItem.id,
          projectId: currentItem.parents[0],
        };

        const newLocation = {
          groupId: destination.type === "project" ? null : destination.id,
          projectId: destination.type === "project" ? destination.id : destination.parents[0],
        };

        const canHaveSubGroup = destination.data_set_ids?.length === 0 || destination.type === "dataset" || destination.type === "project";
        if (!canHaveSubGroup) {
          setErrorWarning(dictionary.errorMessage.droppableHaveDataset);
          setTimeout(() => {
            setErrorWarning();
          }, 5000);
          return;
        }

        await postMoveGroup(prevLocation, newLocation);
        await refreshProjects();
        setSelectedKeys([]);
      } catch (error: any) {
        console.log(error);
        setErrorWarning(error?.detail ?? dictionary.serverError);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }
    },
    [refreshProjects, setErrorWarning]
  );

  const onMoveWellGroup = useCallback(
    async (info: any) => {
      const destination = info.node;
      const currentItem = info.dragNode;

      // this mean use dragging in same place
      if (_.isEqual(currentItem.parents, destination.parents)) return;

      // - if the group have subgroup || drag over a project
      if (destination.type === "project" || (destination?.children?.length > 0 && destination.data_set_ids?.length === 0)) {
        setErrorWarning(dictionary.errorMessage.droppableWellMoveHaveSubgroup);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      // if user drop to different project
      if (destination.parents[0] !== currentItem.parents[0]) {
        setErrorWarning(dictionary.errorMessage.droppableDifferentProjectWellMove);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      const selectedWells = selectedKeys.reduce((res: string[], key) => {
        if (key.includes(";")) {
          res.push(key.split(";")[0]);
        }
        return res;
      }, []);

      let destinationGroupId = destination.id;
      const currentGroupId = currentItem.parents[currentItem.parents.length - 1];
      const projectId = currentItem.parents[0];

      // if user drop to dataset, take it parent group
      if (destination.type === "dataset") {
        destinationGroupId = destination.parents[destination.parents.length - 1];
      }

      try {
        await postMoveWells({
          body: {
            dataset_ids: selectedWells,
            group_id: destinationGroupId,
          },
          groupId: currentGroupId,
          projectId,
        });
        await refreshProjects();
        setSelectedKeys([]);
      } catch (error: any) {
        setErrorWarning(error?.detail ?? dictionary.serverError);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);

        return;
      }
    },
    [refreshProjects, selectedKeys, setErrorWarning]
  );

  const onDragEnd = async (info: any) => {
    const destination = info.node;
    const currentItem = info.dragNode;

    if (currentItem.type === "project" || destination.id === currentItem.id) {
      setErrorWarning(dictionary.errorMessage.moveProjectNotAllowed);
      setTimeout(() => {
        setErrorWarning();
      }, 5000);
      return;
    }

    if (!currentItem.dataSet && currentItem.type === "dataset") {
      setErrorWarning(dictionary.errorMessage.wellNotFound);
      setTimeout(() => {
        setErrorWarning();
      }, 5000);
      return;
    }

    if (currentItem.type === "group") {
      await onMoveGroupProject(info);
    } else {
      await onMoveWellGroup(info);
    }
  };

  const onSelectProject = useCallback(
    (projectId: string, prevSelection: boolean, childKeyList: string[]) => {
      const project = projectsMap.get(projectId);
      if (!project) return;

      let newSelectedKeys: string[] = [];

      if (prevSelection) {
        onUpdateActiveKey("");
      } else {
        onUpdateActiveKey(`${projectId}`);
        newSelectedKeys = [projectId, ...childKeyList];
      }
      setSelectedKeys(newSelectedKeys);

      if (!prevSelection) {
        navigate("/modules");
      }
    },
    [projectsMap, onUpdateActiveKey, navigate]
  );

  const onSelectGroup = useCallback(
    (id: string, prevSelection: boolean, item: TreeNode) => {
      const projectId = item.parents[0];
      const project = projectsMap.get(projectId);
      if (!project) return;

      let newSelectedKeys = [...selectedKeys];

      if (!prevSelection || !_.isEqual([id, ...(item.childKeyList ?? [])], newSelectedKeys)) {
        newSelectedKeys = [id, ...(item.childKeyList ?? [])];
        onUpdateActiveKey(`${projectId};${[...item.parents.slice(1), item.id].join(",")}`);
      } else {
        onUpdateActiveKey("");
        newSelectedKeys = [];
      }
      setSelectedKeys(newSelectedKeys);

      if (!prevSelection) {
        navigate("/modules");
      }
    },
    [projectsMap, selectedKeys, onUpdateActiveKey, navigate]
  );

  const canEnabledMultipleMod = useCallback(
    (fullSelectedDataSets: DataSet[]) => {
      return currentModule ? mergeStringArrays(moduleMappingMulti).includes(currentModule) && fullSelectedDataSets.length > 1 : true;
    },
    [currentModule]
  );

  const onSelectDataSet = useCallback(
    async (projectId: string, groupIds: string[], dataSetId: string, prevSelection: boolean, key: string, isDrag: boolean) => {
      const project = projectsMap.get(projectId);
      if (!project) return;

      const isSelect = !prevSelection;
      let newSelectedKeys = [...selectedKeys];

      const { dataSet: activeDataSetIds, groupIds: activeGroupIds, projectId: activeProjectId } = getIdFromActiveKey(activeKey);

      const dataSet = dataSetsMap.get(dataSetId);
      if (!dataSet) return;

      if (commandControlHeld || isDrag) {
        if (projectId === activeProjectId && _.isEqual(groupIds, activeGroupIds)) {
          let updatedDataSetIds = [...activeDataSetIds];
          if (prevSelection) {
            // Deselect the dataset
            updatedDataSetIds = activeDataSetIds.filter((id) => id !== dataSetId);
            newSelectedKeys = selectedKeys.filter((k) => k !== key);
          } else {
            // Select the dataset
            updatedDataSetIds.push(dataSetId);
            newSelectedKeys.push(key);
          }

          const fullSelectedDataSets = updatedDataSetIds.map((id) => dataSetsMap.get(id)).filter(Boolean) as DataSet[];

          if (canEnabledMultipleMod(fullSelectedDataSets) && !isDrag) {
            onUpdateActiveKey(`${projectId};${groupIds.join(",")};${updatedDataSetIds.join(",")}`);
            setSelectedDataSets(fullSelectedDataSets);
          } else {
            // Reset to single selection
            onUpdateActiveKey(`${projectId};${groupIds.join(",")};${dataSetId}`);
            setSelectedDataSets(dataSet);
            newSelectedKeys = [key];
          }
        } else {
          // Reset selection if not the same project or group
          onUpdateActiveKey(`${projectId};${groupIds.join(",")};${dataSetId}`);
          setSelectedDataSets(dataSet);
          newSelectedKeys = [key];
        }
      } else if (isSelect) {
        // Single-selection logic
        onUpdateActiveKey(`${projectId};${groupIds.join(",")};${dataSetId}`);
        setSelectedDataSets(dataSet);
        newSelectedKeys = [key];
      } else {
        // Deselect the dataset
        newSelectedKeys = [];
        onUpdateActiveKey("");
        setSelectedDataSets(undefined);
      }

      setSelectedKeys(newSelectedKeys);
    },
    [projectsMap, canEnabledMultipleMod, dataSetsMap, selectedKeys, commandControlHeld, activeKey, onUpdateActiveKey, setSelectedDataSets]
  );

  const onSelectItem = ({ id, type, dataSetId = "", groupId = [], prevSelection = false, key, item, isDrag = false }: SelectItemProp) => {
    setSelectedDataSet([]);

    if (type === "dataset") {
      if (!item?.dataSet) {
        setSelectedDataSets();
      }
      onSelectDataSet(id, groupId.slice(1), dataSetId, prevSelection, key, isDrag);
    } else if (type === "group") onSelectGroup(id, prevSelection, item);
    else onSelectProject(id, prevSelection, item.childKeyList ?? []);
  };

  const onClickSelectDataSet = (wells: string[]) => {
    setSelectedKeys([]);
    setSelectedDataSet(wells);
  };

  // hacky way to handle drop between 2 tree
  const onDragWellList = (info: any) => {
    if (!_.isEqual(wellDestination.current, info)) {
      wellDestination.current = info;
    }
  };

  const onAddWellToGroup = useCallback(async () => {
    if (!wellDestination.current) return;
    try {
      if (wellDestination.current.type === "project") {
        setErrorWarning(dictionary.errorMessage.groupDroppable);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      let groupId = wellDestination.current.id;
      const projectId = wellDestination.current.parents[0];
      if (wellDestination.current.type === "dataset") {
        groupId = wellDestination.current.parents[wellDestination.current.parents.length - 1];
      }

      // Check if the destination group has subgroups
      const project = projectsMap.get(projectId);
      if (!project) return;

      const destinationGroupIds = wellDestination.current.parents.slice(1);
      destinationGroupIds.push(groupId);
      const destinationGroup = findGroupRecursive(destinationGroupIds, project.groups);

      if (destinationGroup?.groups && destinationGroup.groups.length > 0) {
        setErrorWarning(dictionary.errorMessage.dropToGroupHasSubgroups);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      if (mappedItemKeyTotal[groupId].ids) {
        const safeIdListByGroup = mappedItemKeyTotal[groupId].ids ?? [];
        let newDataSets: string[] = selectedDataSet;
        newDataSets = newDataSets.filter((dataSetId) => safeIdListByGroup.indexOf(dataSetId) < 0);
        if (newDataSets.length === 0) return;
        await postWellToGroup(projectId, groupId, newDataSets);
        await refreshProjects();

        setSelectedDataSet([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [mappedItemKeyTotal, refreshProjects, selectedDataSet, setErrorWarning, projectsMap]);

  return {
    showAll,
    setShowAll,
    hideProjects,
    setHideProjects,
    onDragEnd,
    mappedItemKeyTotal,
    selectedItems: projects ?? [],
    setActiveKey: onUpdateActiveKey,
    activeKey,
    selectedDataSet,
    setSelectedDataSet: onClickSelectDataSet,

    shiftHeld,
    commandControlHeld,

    onMoveWellGroup,
    selectedKeys,
    onSelectItem,
    onDragWellList,
    onDropWell: onAddWellToGroup,
    setSelectedKeys,
    checkIfDatasetExists,
    onSelectDataSet,
    onUpdateActiveKey,
    canEnabledMultipleMod,
  };
};

export default useProjectNav;
