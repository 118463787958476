import { Row } from "@silevis/reactgrid";

import dictionary from "@/constants/dictionary";
import { tableHeaderStyle } from "@/components/CustomTable";
import { CustomRow, SortState } from "./hooks/type";
import { AFA_WELL_TYPE } from "@/components/TreeView/constants";

const safeSpadDictionary: any = dictionary.spadDecline;
const safeSpadParamDictionary: any = dictionary.spadDeclineParams;

export const generateHeaderSpad = (list: string[], onClick: (obj: { [key: string]: boolean }) => void): Row<any> => {
  return {
    rowId: "header",
    cells: list.map((header, index) => {
      let text = header;
      if (index === 0) {
        return {
          type: "custom",
          text: dictionary.rulon.data_set_id,
          nonEditable: true,
          style: tableHeaderStyle,
          onClick: () => {
            onClick({ name: true, type: false });
          },
        };
      } else if (index === 1) {
        return {
          type: "custom",
          text: dictionary.groupRunModuleName.type,
          nonEditable: true,
          style: tableHeaderStyle,
          onClick: () => {
            onClick({ type: true, name: false });
          },
        };
      } else if (text.includes("operationalForecast") || text.includes("profileForecast")) {
        const section = header.split(".");
        return {
          type: "custom",
          text: `${safeSpadDictionary[section[0]]} - ${safeSpadDictionary[section[1]]} - ${safeSpadParamDictionary[section[2]].label}`,
          style: tableHeaderStyle,
          sub: safeSpadParamDictionary[section[2]].sub ?? "",
        };
      } else text = safeSpadDictionary[header];
      return {
        type: "text",
        text,
        style: tableHeaderStyle,
      };
    }),
    height: 50,
  };
};

export const sortRulonRows = (list: CustomRow[], sortState: SortState) => {
  let sortedList = list;

  if (sortState.name) {
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (sortState.type) {
    return list.sort((a, b) => a.wellType.localeCompare(b.wellType));
  }

  return sortedList as Row<any>[];
};

export const getWellType = (tags: string[]) => {
  if (!tags) return [];
  return tags.reduce((res, tag) => {
    if (tag.includes(AFA_WELL_TYPE)) {
      res.push(tag.split(":")?.[1]);
    }
    return res;
  }, [] as string[]);
};
