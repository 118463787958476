import { API } from "aws-amplify";
import { GenericApiParam, CustomError, GenericApiResponse } from "./type";
import { APIName, calculateRollup, cancelTask, validateRollup } from "@/constants/apiUrl";
import { safeTimeStampToLS } from "@/utils/session";
import { ApiError } from "@/models/APIGeneric";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { parseErrorThrown } from "../errorHandling";
import { useRef, useState } from "react";
import { setExtraErrException } from "../errorMonitoring";
import useInterval from "../useInterval";
import { DateTime } from "luxon";
import { fetchApi } from "./genericFetcher";
import dictionary from "@/constants/dictionary";

let intervalTime = 500;

type PollResponse<Response> = {
  progress?: string;
  task_id?: string;
  task_status?: string;
  task_result?: Response | null;
  task_info?: {
    status: string;
    progress: number;
  } | null;
} & GenericApiResponse<Response>;

type PollParam<Request, QueryParams> = {
  withTaskInfo?: boolean;
  loadingBlockerText?: string;
  isBlocking?: boolean;
} & GenericApiParam<Request, QueryParams>;

export type ProgressStatus = {
  progress?: number;
  pollStatus?: string;
};

type LoadingBlocker = {
  isBlocking?: boolean;
  loadingBlockerText?: string;
};

type ContinuePollParam = {
  path: string;
  taskId: string;
  withTaskInfo?: boolean;
  isBlocking?: boolean;
  body?: any;
};

type UsePollingProps = {
  setApiError: (error?: ApiError) => void;
  setLoadingState?: (val: boolean) => void;
  setLoadingBlocker?: (val: LoadingBlocker) => void;
  setErrorValidation?: (validation: ErrorValidationDetail[]) => void;
  setProgressStatus?: (val: ProgressStatus) => void;
  apiError?: ApiError;

  // if this props was sent, always set the result event hought the task is not done ( status === 'started' )
  // currently use to view progress for group run
  setInfoContinuous?: (result: any) => void;
};

type TaskInfo = {
  isBatchTask: boolean;
  timeLastFetch: Date;
  taskId: string;
  taskStarted: boolean;
  isTaskDone?: boolean;
};

const defaultTimeLimit = 60000;

export const usePolling = ({
  setApiError,
  setErrorValidation = () => {},
  setLoadingBlocker,
  setLoadingState = () => {},
  setProgressStatus = () => {},
  apiError,
  setInfoContinuous,
}: UsePollingProps) => {
  const haveRetryTimes = useRef(0);
  const taskInfo = useRef<TaskInfo | null>();

  const [canCancelPoll, setCanCancelPoll] = useState(false);

  // need ref because poll function doens't update, but the ui need state
  const canCancelPollRef = useRef(false);

  const totalResult = useRef<string>("");
  const currentPage = useRef<number>(1);

  // for group run, if user change pages dont continue the poll;
  const haveStopPoll = useRef<boolean>(false);

  const forceStopPoll = () => {
    haveStopPoll.current = true;
  };

  const continuePollRequest = <Response>({
    path,
    taskId,
    withTaskInfo,
    isBlocking = false,
    body,
  }: ContinuePollParam): Promise<PollResponse<Response>> => {
    safeTimeStampToLS();
    haveRetryTimes.current = 0;
    // reset all state when start
    setLoadingState(true);
    setErrorValidation?.([]);
    setApiError?.(undefined);
    haveStopPoll.current = false;

    if (!canCancelPollRef.current)
      setLoadingBlocker?.({
        isBlocking,
      });

    return new Promise((resolve, reject) => {
      const handleRetry = (err: any) => {
        setExtraErrException(err);
        setProgressStatus({});
        // only if network error
        if (haveRetryTimes.current < 4 && !err?.response?.status) {
          let nextIntervalTime = intervalTime * 2;
          if (nextIntervalTime > 10000) {
            // Max interval time is 10 seconds
            nextIntervalTime = 10000;
          }

          setTimeout(() => {
            haveRetryTimes.current = haveRetryTimes.current + 1;
            poll();
          }, intervalTime);

          intervalTime = nextIntervalTime;
        } else {
          const errorStructure = {
            code: err.response?.data?.code ?? err.response?.status,
            detail: err.response?.data?.detail,
            message: err.response?.data?.message,
            severity: err.response?.data?.severity,
          };
          setLoadingState(false);
          setExtraErrException(errorStructure);

          parseErrorThrown({
            error: errorStructure,
            setApiError,
            setValidationError: setErrorValidation,
            apiError,
          });
          throw new CustomError(errorStructure);
        }
      };
      const poll = async () => {
        try {
          if (haveStopPoll.current) return;

          const { data } = await API.get("afa", path + "/task_poll", {
            queryStringParameters: { task_id: taskId, page: currentPage.current },
            response: true,
          });

          if (data.task_status === "STARTED" && taskInfo.current) {
            taskInfo.current = {
              ...taskInfo.current,
              taskStarted: true,
            };
          }

          if (data?.task_result) {
            const transformedResult = typeof data.task_result === "string" ? data.task_result : JSON.stringify(data.task_result);
            totalResult.current = totalResult.current + transformedResult;
            currentPage.current = data?.task_current_page + 1;
          }

          if (data.task_status === "SUCCESS" && data.task_current_page === data.task_total_page) {
            setCanCancelPoll(false);
            canCancelPollRef.current = false;
            resolve(
              withTaskInfo
                ? {
                    ...data,
                    task_result: JSON.parse(totalResult.current),
                  }
                : JSON.parse(totalResult.current)
            );
            if (taskInfo.current)
              taskInfo.current = {
                ...taskInfo.current,
                isTaskDone: true,
              };

            setProgressStatus({});
            setLoadingState(false);
            setLoadingBlocker?.({
              isBlocking: false,
              loadingBlockerText: undefined,
            });

            return;
          } else if (data.task_status === "FAILURE" || data.task_status === "REVOKED") {
            setProgressStatus({});
            reject(new Error(data));
            return;
          }
          // means it is either  "PENDING" || "STARTED" || "SUCCESS" but the total isn't fulfilled yet
          // When progress value is given (for long-term api)
          const isRollup = path === calculateRollup || path === validateRollup;
          const progress: ProgressStatus = {};

          let loadingBlockerText = "";
          if (isRollup) {
            const typedBody = body as { data_set_ids: string[] };
            const wells = typedBody?.data_set_ids?.length * (Number(data.task_info?.progress) / 100);
            loadingBlockerText = `${path === calculateRollup ? "Calculating" : "Validating"}: ${wells.toFixed()} of ${
              typedBody?.data_set_ids?.length
            } wells`;
          }
          // only block user if they cannot cancel poll
          if (!canCancelPollRef.current) setLoadingBlocker?.({ loadingBlockerText, isBlocking });
          if (data?.task_info?.progress) progress.progress = data.task_info.progress;
          if (data?.task_info?.status) progress.pollStatus = data.task_info.status;

          setInfoContinuous?.(data);
          setProgressStatus?.(progress);

          poll();
        } catch (err: any) {
          handleRetry(err);
        }
      };

      poll();
    });
  };

  const createPoll = async <Response, Request = any, QueryParams = any>({
    path,
    body,
    config,
    queryStringParameters,
    responseType,
    withTaskInfo = true,
    loadingBlockerText = "",
    isBlocking,
  }: // will return PollResponse if withTaskInfo is true
  PollParam<Request, QueryParams>): Promise<PollResponse<Response>> => {
    try {
      // reset all state when start
      setCanCancelPoll(false);
      setLoadingState?.(true);
      setErrorValidation?.([]);
      safeTimeStampToLS();
      setApiError?.(undefined);
      setLoadingBlocker?.({
        loadingBlockerText,
        isBlocking,
      });
      // reset the result every time make a new poll request
      totalResult.current = "";
      haveRetryTimes.current = 0;
      currentPage.current = 1;
      const {
        data: { task_id, is_batch_task },
      } = await API.post(APIName, path + "/task_start", {
        queryStringParameters,
        body,
        response: true,
        responseType,
        ...config,
      });

      taskInfo.current = {
        isBatchTask: is_batch_task,
        taskId: task_id,
        timeLastFetch: new Date(),
        taskStarted: false,
      };
      return await continuePollRequest({
        path,
        taskId: task_id,
        isBlocking,
        withTaskInfo,
        body,
      });
    } catch (err: any) {
      const errorStructure = {
        code: err.response?.data?.code ?? err.response?.status,
        detail: err.response?.data?.detail,
        message: err.response?.data?.message,
        severity: err.response?.data?.severity,
      };
      setExtraErrException(errorStructure);
      parseErrorThrown({
        error: errorStructure,
        setApiError,
        setValidationError: setErrorValidation,
        apiError,
      });
      throw new CustomError(errorStructure);
    } finally {
      setLoadingState?.(false);
      if (isBlocking) {
        setLoadingBlocker?.({
          loadingBlockerText: undefined,
          isBlocking: false,
        });
      }
    }
  };

  // validate every 3 seconds
  useInterval(async () => {
    if (!taskInfo.current) return;
    const end = DateTime.now();
    const start = DateTime.fromJSDate(taskInfo.current.timeLastFetch);
    const diff = end.diff(start).toMillis();
    if (
      diff >= defaultTimeLimit &&
      taskInfo.current.taskStarted &&
      taskInfo.current.isBatchTask &&
      !canCancelPollRef.current &&
      !canCancelPoll &&
      !taskInfo.current.isTaskDone
    ) {
      canCancelPollRef.current = true;
      setCanCancelPoll(true);
      // remove loading blocker to enable user to click cancel poll button
      setLoadingBlocker?.({
        loadingBlockerText: undefined,
        isBlocking: false,
      });
    }
  }, 5000);

  const onCancelPoll = async () => {
    if (!taskInfo.current) return;

    try {
      const res = await fetchApi<any>({
        path: cancelTask(taskInfo.current.taskId),
        type: "del",
      });
      setApiError({
        code: 0,
        message: res?.data?.message ?? dictionary.errorMessage.cancelTask,
        severity: "success",
      });
      setTimeout(() => {
        setApiError();
      }, 5000);

      return res;
    } catch (err: any) {
      const errorStructure = {
        code: err.response?.data?.code ?? err.response?.status,
        detail: err.response?.data?.detail,
        message: err.response?.data?.message,
        severity: err.response?.data?.severity,
      };
      setExtraErrException(errorStructure);
      parseErrorThrown({
        error: errorStructure,
        setApiError,
        setValidationError: setErrorValidation,
        apiError,
      });
      throw new CustomError(errorStructure);
    } finally {
      // reset everything
      setLoadingState?.(false);
      setLoadingBlocker?.({
        loadingBlockerText: undefined,
        isBlocking: false,
      });
      taskInfo.current = null;
      setCanCancelPoll(false);
    }
  };
  return { createPoll, continuePollRequest, canCancelPoll, onCancelPoll, forceStopPoll };
};
