import React, { useCallback, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Autocomplete,
  TextField,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AFA_WELL_TYPE } from "@/components/TreeView/constants";
import { HeaderMapping, NormalizedHeaders, formatUnit } from "@/models/wells";
import { DIALOG_CONSTANTS, MANUAL_WELL_TYPE_OPTIONS, getDateUnitFromFiles, pythonDateToDisplayFormat } from "./helper";
import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

type ParsedFile = {
  fileName: string;
  headers: string[];
  rows: string[][];
};

type HeaderMappingDialogProps = {
  open: boolean;
  parsedFiles: ParsedFile[];
  currentFileIndex: number;
  setCurrentFileIndex: (index: number) => void;
  manualWellType: string | null;
  setManualWellType: (val: string | null) => void;
  headerMapping: Record<string, HeaderMapping>;
  onMappingChange: (newMapping: Record<string, HeaderMapping>) => void;
  dateFormatsOptions: string[];
  setDateFormatOptions: (dateFormat: string[]) => void;
  onConfirm: () => void;
  onCancel: () => void;
  normalizedFields: NormalizedHeaders | null;
};

const HeaderMappingDialog: React.FC<HeaderMappingDialogProps> = ({
  open,
  parsedFiles,
  currentFileIndex,
  setCurrentFileIndex,
  manualWellType,
  setManualWellType,
  headerMapping,
  onMappingChange,
  dateFormatsOptions,
  setDateFormatOptions,
  onConfirm,
  onCancel,
  normalizedFields,
}) => {
  const safeIndex = Math.min(currentFileIndex, parsedFiles.length - 1);
  const safeParsedFile = parsedFiles[safeIndex] || { fileName: "", rows: [], headers: [] };
  const { fileName, rows, headers } = safeParsedFile;

  // If any CSV column is mapped to "AFA-welltype," the user is doing column-based well type
  // => user should NOT pick a manual well type
  const isAutoWellTypeSelected = useMemo(
    () => Object.values(headerMapping).some((mapping) => mapping.normalizedField === AFA_WELL_TYPE),
    [headerMapping]
  );

  const templateFieldOptions = normalizedFields ? Object.keys(normalizedFields) : [];
  /**
   * Check for errors:
   * 1) Any date field with an unrecognized date format
   * 2) Any normal field missing a unit
   */
  const hasAnyUnrecognizedDates = useMemo(
    () =>
      Object.values(headerMapping).some(
        (mapping) => mapping.normalizedField === DIALOG_CONSTANTS.DATE_FIELD && mapping.normalizedUnit === DIALOG_CONSTANTS.UNRECOGNIZED_DATE
      ),
    [headerMapping]
  );

  // It's a "normal" field => it must have a unit
  const hasAnyMissingUnits = useMemo(
    () =>
      Object.values(headerMapping).some((mapping) => {
        if (!mapping.normalizedField || mapping.normalizedField === DIALOG_CONSTANTS.TAG_FIELD || mapping.normalizedField === AFA_WELL_TYPE) {
          return false;
        }
        return !mapping.normalizedUnit;
      }),
    [headerMapping]
  );

  // check AFA welltype is selected or not
  const missingWellType = !isAutoWellTypeSelected && !manualWellType;
  const disableConfirm = hasAnyUnrecognizedDates || hasAnyMissingUnits || missingWellType;

  // Prevent double-mapping the same Field across multiple headers
  const selectedNormalizedFields = useMemo(() => {
    return Object.entries(headerMapping)
      .filter(([, val]) => val.normalizedField && val.normalizedField !== DIALOG_CONSTANTS.TAG_FIELD && val.normalizedField !== AFA_WELL_TYPE)
      .map(([, val]) => val.normalizedField);
  }, [headerMapping]);

  // Field selection
  const handleNormalizedFieldChange = useCallback(
    (header: string) => (event: SelectChangeEvent<string>) => {
      const newFieldVal = event.target.value === DIALOG_CONSTANTS.NOT_USED ? null : event.target.value;
      const newMapping = { ...headerMapping };
      const currentMapping = newMapping[header] ?? {
        normalizedField: null,
        normalizedUnit: null,
      };

      let newUnit: string | null = null;
      if (newFieldVal === DIALOG_CONSTANTS.DATE_FIELD) {
        const guessed = getDateUnitFromFiles(parsedFiles, header);
        if (guessed === DIALOG_CONSTANTS.UNRECOGNIZED_DATE) {
          newUnit = DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
          setDateFormatOptions([DIALOG_CONSTANTS.UNRECOGNIZED_DATE]);
        } else if (Array.isArray(guessed)) {
          setDateFormatOptions(guessed);
          if (guessed.length === 1) newUnit = guessed[0];
        }
      }

      newMapping[header] = {
        ...currentMapping,
        normalizedField: newFieldVal,
        normalizedUnit: newUnit,
      };

      // If user picks AFA-welltype => reset manualWellType
      if (newFieldVal === AFA_WELL_TYPE) setManualWellType(null);

      onMappingChange(newMapping);
    },
    [headerMapping, parsedFiles, setDateFormatOptions, setManualWellType, onMappingChange]
  );

  // Unit selection
  const handleNormalizedUnitChange = useCallback(
    (header: string) => (event: SelectChangeEvent<string>) => {
      const rawVal = event.target.value;
      const chosenUnit = [DIALOG_CONSTANTS.CHOOSE_UNIT, DIALOG_CONSTANTS.CHOOSE_WELL_TYPE, DIALOG_CONSTANTS.UNRECOGNIZED_DATE].includes(rawVal)
        ? null
        : rawVal;

      const newMapping = { ...headerMapping };
      const currentMapping = newMapping[header] || {
        normalizedField: null,
        normalizedUnit: null,
      };

      newMapping[header] = {
        ...currentMapping,
        normalizedUnit: chosenUnit,
      };

      onMappingChange(newMapping);
    },
    [headerMapping, onMappingChange]
  );

  const handleDialogClose = useCallback(
    (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      onCancel();
    },
    [onCancel]
  );

  // For previewing multiple files
  const handleFileSelectChange = useCallback(
    (_event: any, newValue: string | null) => {
      const idx = parsedFiles.findIndex((file) => file.fileName === newValue);
      if (idx !== -1) setCurrentFileIndex(idx);
    },
    [parsedFiles, setCurrentFileIndex]
  );

  // If user picks a manual well type => remove any existing "AFA-welltype" columns
  const handleManualWellTypeSelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const chosen = event.target.value === DIALOG_CONSTANTS.CHOOSE_WELL_TYPE ? null : event.target.value;

      // If user picked a valid well type, remove existing AFA-welltype columns
      if (chosen) {
        const newMapping = { ...headerMapping };
        Object.keys(newMapping).forEach((col) => {
          if (newMapping[col].normalizedField === AFA_WELL_TYPE) {
            newMapping[col] = {
              normalizedField: null,
              normalizedUnit: null,
            };
          }
        });
        onMappingChange(newMapping);
      }
      setManualWellType(chosen);
    },
    [headerMapping, onMappingChange, setManualWellType]
  );

  const StyledLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  }));

  return (
    <Dialog open={open} onClose={handleDialogClose} TransitionProps={{ timeout: 0 }} maxWidth="lg" fullWidth data-testid="header-mapping-dialog">
      <DialogTitle style={{ fontWeight: "bolder" }} data-testid="dialog-title">
        <StyledLink target="_blank" rel="noreferrer" href={helpLinkUrl.wellImport}>
          {dictionary.nav.importWells} <InfoOutlinedIcon fontSize="small" />
        </StyledLink>
      </DialogTitle>

      <DialogContent sx={{ padding: 3 }}>
        {/* If there's NO column mapped to "AFA-welltype," we let the user pick a manual well type */}
        {!isAutoWellTypeSelected && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <h4 style={{ margin: "0 10px 0 0" }}>Select Well Type:</h4>
            <FormControl size="small" style={{ width: "200px" }}>
              <InputLabel>AFA Well Type</InputLabel>
              <Select
                label="AFA Well Type"
                data-testid="manual-well-type-select"
                value={manualWellType ?? DIALOG_CONSTANTS.CHOOSE_WELL_TYPE}
                onChange={handleManualWellTypeSelect}
                renderValue={(selected) => (
                  <span
                    style={{
                      color: selected === DIALOG_CONSTANTS.CHOOSE_WELL_TYPE ? "red" : "inherit",
                    }}
                  >
                    {selected}
                  </span>
                )}
              >
                <MenuItem value={DIALOG_CONSTANTS.CHOOSE_WELL_TYPE} style={{ color: "red" }}>
                  <em>{DIALOG_CONSTANTS.CHOOSE_WELL_TYPE}</em>
                </MenuItem>
                {MANUAL_WELL_TYPE_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item} data-testid={`manual-well-type-option-${item}`}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {/* **Show error if neither column-based nor manual-based well type is selected** */}
        {missingWellType && (
          <FormHelperText error style={{ marginLeft: 0 }}>
            Configuration must include a column mapped to &quot;AFA-welltype&quot; or manually select an &quot;AFA-welltype&quot;.
          </FormHelperText>
        )}

        {/* The table for CSV columns => Field + Unit */}
        <div style={{ marginTop: "10px" }} data-testid="header-matching-section">
          <TableContainer style={{ maxHeight: 400 }} data-testid="header-matching-table-container">
            <Table size="small" stickyHeader data-testid="header-matching-table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      width: "30%",
                    }}
                  >
                    Column in File
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      width: "35%",
                    }}
                  >
                    Field
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      width: "35%",
                    }}
                  >
                    Unit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {headers.map((header) => {
                  const currentMapping = headerMapping[header] || {
                    normalizedField: null,
                    normalizedUnit: null,
                  };
                  const selectedField = currentMapping.normalizedField;
                  const selectedUnit = currentMapping.normalizedUnit;

                  const isDateField = selectedField === DIALOG_CONSTANTS.DATE_FIELD;
                  const isUnrecognizedDate = isDateField && selectedUnit === DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
                  const isAfaWellType = selectedField === AFA_WELL_TYPE;
                  const isTagField = selectedField === DIALOG_CONSTANTS.TAG_FIELD;

                  // Gather possible units
                  let availableUnits: string[] = [];
                  if (isDateField) {
                    availableUnits = dateFormatsOptions;
                  } else if (selectedField && normalizedFields) {
                    availableUnits = normalizedFields[selectedField as keyof NormalizedHeaders] || [];
                  }

                  const availableNormalizedFields = templateFieldOptions.filter(
                    (field) => !selectedNormalizedFields.includes(field) || field === DIALOG_CONSTANTS.TAG_FIELD || field === selectedField
                  );

                  // disable Unit if "Not Used," "Tag," or "AFA-welltype"
                  const isUnitDisabled = !selectedField || isTagField || isAfaWellType;

                  // Normal field that requires a unit?
                  const isNormalFieldRequiringUnit = selectedField && selectedField !== DIALOG_CONSTANTS.TAG_FIELD && selectedField !== AFA_WELL_TYPE;

                  // Missing a unit if it's a normal field but has no selectedUnit
                  const isMissingUnit = isNormalFieldRequiringUnit && !selectedUnit;

                  // fallback for unit
                  const fallbackMenuValue = DIALOG_CONSTANTS.CHOOSE_UNIT;

                  return (
                    <TableRow key={header} data-testid={`header-row-${header}`}>
                      <TableCell style={{ width: "30%" }} data-testid={`header-cell-${header}`}>
                        {header}
                      </TableCell>

                      {/* Field Column */}
                      <TableCell style={{ width: "35%" }}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={selectedField ?? DIALOG_CONSTANTS.NOT_USED}
                            onChange={handleNormalizedFieldChange(header)}
                            renderValue={(sel) => (
                              <span
                                style={{
                                  color: sel === DIALOG_CONSTANTS.NOT_USED ? "red" : "inherit",
                                }}
                              >
                                {sel}
                              </span>
                            )}
                            style={{ fontSize: "0.9rem" }}
                            MenuProps={{
                              PaperProps: {
                                style: { maxHeight: 300 },
                              },
                            }}
                            data-testid={`template-field-select-${header}`}
                          >
                            {/* 1) “Not used” SELECTION */}
                            <MenuItem value={DIALOG_CONSTANTS.NOT_USED} style={{ color: "red" }}>
                              <em>{DIALOG_CONSTANTS.NOT_USED}</em>
                            </MenuItem>

                            {/* 2) Normal fields */}
                            <MenuItem
                              disabled
                              style={{
                                borderTop: "1px solid #999",
                                borderBottom: "1px solid #999",
                                fontWeight: "bold",
                                marginTop: 4,
                                marginBottom: 4,
                              }}
                            >
                              Fields
                            </MenuItem>
                            {availableNormalizedFields
                              .filter((f) => f !== "Tag" && f !== AFA_WELL_TYPE)
                              .map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}

                            {/* 3) "System Fields" */}
                            <MenuItem
                              disabled
                              style={{
                                borderTop: "1px solid #999",
                                borderBottom: "1px solid #999",
                                fontWeight: "bold",
                                marginTop: 4,
                                marginBottom: 4,
                              }}
                            >
                              System Fields
                            </MenuItem>

                            {["Tag", "AFA-welltype"].map((systemField) => {
                              if (!availableNormalizedFields.includes(systemField)) return null;
                              return (
                                <MenuItem key={systemField} value={systemField}>
                                  {systemField}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>

                      {/* Unit Column */}
                      <TableCell style={{ width: "35%" }}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={Boolean((isDateField && selectedUnit === DIALOG_CONSTANTS.UNRECOGNIZED_DATE) || isMissingUnit)}
                        >
                          <Select
                            value={selectedUnit ?? fallbackMenuValue}
                            onChange={handleNormalizedUnitChange(header)}
                            renderValue={(sel) => {
                              if (!sel || sel === fallbackMenuValue) return <span style={{ color: "red" }}>{fallbackMenuValue}</span>;
                              if (sel === DIALOG_CONSTANTS.UNRECOGNIZED_DATE)
                                return <span style={{ color: "red" }}>{DIALOG_CONSTANTS.UNRECOGNIZED_DATE}</span>;
                              if (isDateField) return <span>{pythonDateToDisplayFormat(sel)}</span>;
                              return <span>{formatUnit(sel)}</span>;
                            }}
                            disabled={isUnitDisabled || isUnrecognizedDate}
                            style={{ fontSize: "0.9rem" }}
                            data-testid={`template-unit-select-${header}`}
                          >
                            <MenuItem value={fallbackMenuValue} style={{ color: "red" }}>
                              <em>{fallbackMenuValue}</em>
                            </MenuItem>
                            {availableUnits.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {isDateField ? pythonDateToDisplayFormat(unit) : formatUnit(unit)}
                              </MenuItem>
                            ))}
                          </Select>

                          {/* Error message if date is unrecognized */}
                          {isDateField && selectedUnit === DIALOG_CONSTANTS.UNRECOGNIZED_DATE && (
                            <FormHelperText error>{DIALOG_CONSTANTS.UNRECOGNIZED_DATE_MESSAGE}</FormHelperText>
                          )}

                          {/* Error message if normal field is missing a unit */}
                          {isMissingUnit && <FormHelperText error>{DIALOG_CONSTANTS.UNIT_NOT_SELECTED}</FormHelperText>}
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Preview Section */}
        <div style={{ marginTop: "20px" }} data-testid="preview-section">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ marginRight: "10px" }}>Preview File:</h4>
            <Autocomplete
              options={parsedFiles.map((f) => f.fileName)}
              value={fileName}
              onChange={handleFileSelectChange}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select File" variant="outlined" size="small" data-testid="select-file-input" />}
            />
          </div>
          <TableContainer style={{ maxHeight: 200, overflowX: "auto" }} data-testid="preview-table-container">
            <Table size="small" stickyHeader data-testid="preview-table" style={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      key={header}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                      }}
                      data-testid={`preview-header-${header}`}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="preview-table-body">
                {rows.map((row, rowIndex) => {
                  const rowKey = `${rowIndex}-${row.join("||")}`;
                  return (
                    <TableRow key={rowKey} data-testid={`preview-row-${rowKey}`}>
                      {row.map((cell, idx) => (
                        <TableCell key={headers[idx]} data-testid={`preview-cell-${headers[idx]}-${cell}`}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>

      <DialogActions style={{ marginTop: "5px" }} data-testid="dialog-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        {/* Disable if there's any unrecognized date or missing units */}
        <Button onClick={onConfirm} variant="contained" style={{ color: "white" }} disabled={disableConfirm} data-testid="confirm-button">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HeaderMappingDialog;
